/** @jsxImportSource @emotion/react */
import { jsx, css } from "@emotion/react";

import React from "react";

type Props = {
  color: string;
  css: ReturnType<typeof css>;
};

const ScholarHatIcon: React.FC<Props> = ({ color = "#3D3D3D", css }) => {
  return (
    <svg
      width="24"
      height="20"
      viewBox="0 0 24 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      css={css}
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M12 0L24 5.78125L19.0714 8.90625L12 13.3333L3.42857 8.02083V18.3333L1.71429 17.5V6.92708L0 5.83333L12 0ZM19.1785 9.99993L19.7142 14.2708C19.6071 14.3402 19.1696 14.644 18.4017 15.1822C17.6339 15.7204 16.8839 16.2499 16.1517 16.7708C15.4196 17.2916 14.6428 17.8645 13.8214 18.4895C12.9999 19.1145 12.3928 19.618 11.9999 19.9999C11.6071 19.618 10.9999 19.1145 10.1785 18.4895C9.35707 17.8645 8.58029 17.2916 7.84814 16.7708C7.116 16.2499 6.36601 15.7204 5.59814 15.1822C4.83028 14.644 4.39279 14.3402 4.28564 14.2708L4.76779 9.99993L11.9999 14.5833L19.1785 9.99993Z"
        fill={color}
      />
    </svg>
  );
};

export default ScholarHatIcon;
