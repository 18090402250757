/** @jsxImportSource @emotion/react */
import { jsx } from "@emotion/react";
import { useTheme } from "@emotion/react";
import React, { useState, useCallback, useEffect } from "react";
import {
  useScreenSizeStore,
  useSubmitInquiryForm,
  useFacilityStore,
} from "stores";
import shallow from "zustand/shallow";
import { addFlex, addPadding, addSize } from "utils";

import Paragraph from "components/Paragraph";
import { getValidationToken } from "services/validationToken";
import FormButtons from "components/SendableForm/FormButtons";
import SendableFormInputWrapper from "components/inputs/SendableFormInputWrapper";
import { FeedbackType } from "stores/utils/storeMakers/makeModalStore/types";
import submitEnquiry from "services/api/submitEnquiry";
import getTenantConfig, { TenantConfig } from "services/api/tenantConfig";
import { FormInputsStates } from "stores/utils/storeMakers/makeFormStore/types";
import CaptchaNotice from "components/CaptchaNotice";
import { NextFormAction } from "../types";
import { useShowThankyouAfterSubmittingEffect } from "components/SendableForm/utils/FormHelpers";
import localTenantConfig from "tenants";
import LoadingSpinner from "components/LoadingSpinner";
import { useSearchResultsStore } from "stores";

type Props = {
  feedBackTypeText?: string;
  feedBackType?: FeedbackType;
  nextAction?: NextFormAction;
};

const USER_TYPES = [
  {
    value: "COMMERCIAL",
    label: localTenantConfig.userTypes["COMMERCIAL"],
  },
  {
    value: "ACADEMIC",
    label: localTenantConfig.userTypes["ACADEMIC"],
  },
  {
    value: "PRIVATE",
    label: localTenantConfig.userTypes["PRIVATE"],
  },
  {
    value: "PUBLIC",
    label: localTenantConfig.userTypes["PUBLIC"],
  },
];

async function submitEnquiryFormAction(
  {
    firstName,
    lastName,
    organisation,
    email,
    phone,
    comment,
    userType,
  }: FormInputsStates,
  validationToken: string,
  institutionId: string,
  facilityId: string,
  searchTerm: string,
  success: (outcome: boolean) => void
): Promise<void> {
  await submitEnquiry({
    firstName: firstName.value.toString(),
    lastName: lastName.value.toString(),
    userType: userType.value.toString(),
    organisation: organisation.value.toString(),
    email: email.value.toString(),
    phone: phone.value.toString(),
    comment: comment.value.toString(),
    captcha: validationToken,
    institutionId,
    facilityId,
    searchTerm,
  }).then(() => success(true));
}

const SubmitEnquiryForm: React.FC<Props> = () => {
  const [
    updateInputValue,
    inputsStates,
    toggleFocus,
    refreshForm,
    isValid,
    setImmutableForForm,
  ] = useSubmitInquiryForm(
    (state) => [
      state.updateInputValue,
      state.inputsStates,
      state.toggleFocus,
      state.refreshForm,
      state.isValid,
      state._setImmutable,
    ],
    shallow
  );

  const { screenIs, isMobile } = useScreenSizeStore();
  const theme = useTheme();
  const [facility] = useFacilityStore(({ facility }) => [facility]);
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [isFormSubmittedSuccessfully, setIsFormSubmittedSuccessfully] =
    useState(false);
  const [tenantConfig, setTenantConfig] = useState(null as null | TenantConfig);

  useEffect(() => {
    async function fetchTenantConfig() {
      const res = await getTenantConfig();
      setTenantConfig(res.data.getTenantConfig);
    }
    fetchTenantConfig();

    refreshForm({ initialValuesByInputId: {} });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    // Remove validation on hidden fields
    if (tenantConfig) {
      Object.entries(tenantConfig.enquiry).forEach(([field, value]) => {
        if (!value) {
          setImmutableForForm((draftState) => {
            draftState.inputsStates[field].validatorTypes = [];
          });
          // so validators run again
          updateInputValue({ inputId: field, newValue: "" });
        }
      });
    }
  }, [setImmutableForForm, tenantConfig, updateInputValue]);

  const addInputIdProps = useCallback(
    (inputId: string) => {
      const inputState = inputsStates[inputId];
      return {
        id: inputId,
        value: inputState.value,
        onChange: (newValue: string | number) => {
          updateInputValue({ inputId, newValue });
        },
        errors: inputState.hasBeenUnfocused
          ? inputState.validationErrorTexts
          : [],
        onFocus: () => toggleFocus({ inputId, isFocused: true }),
        onBlur: () => toggleFocus({ inputId, isFocused: false }),
      };
    },
    [inputsStates, toggleFocus, updateInputValue]
  );

  // This changes things based on what option was chosen for "userType"
  // The placeholder and the validators change
  // TODO could clean this up,
  // maybe add isVisible/enabled to form elements so validators can see
  let organisationPlaceholder = "";

  if (inputsStates["userType"]) {
    switch (inputsStates["userType"].value) {
      case "COMMERCIAL":
        organisationPlaceholder = "Company name";
        break;
      case "ACADEMIC":
        organisationPlaceholder = "Organisation name";
        break;
      case "PRIVATE":
      case "PUBLIC":
        {
          organisationPlaceholder = "Organisation name";
          if (
            inputsStates["organisation"].validatorTypes.includes("required")
          ) {
            setImmutableForForm((draftState) => {
              draftState.inputsStates["organisation"].validatorTypes = [];
            });
            // so validators run again
            updateInputValue({ inputId: "organisation", newValue: "" });
          }
        }
        break;
    }

    switch (inputsStates["userType"].value) {
      case "COMMERCIAL":
      case "ACADEMIC": {
        if (!inputsStates["organisation"].validatorTypes.includes("required")) {
          setImmutableForForm((draftState) => {
            draftState.inputsStates["organisation"].validatorTypes = [
              "required",
            ];
          });
          // so validators run again
          updateInputValue({
            inputId: "organisation",
            newValue: inputsStates["organisation"].value,
          });
        }
      }
    }
  }

  useShowThankyouAfterSubmittingEffect({
    isFormSubmittedSuccessfully,
    title: "Thanks for your enquiry",
    text: "We’ll be in touch with you shortly",
  });

  const [searchTerm] = useSearchResultsStore(
    (state) => [state.searchTerm],
    shallow
  );

  return (
    <div
      css={{
        ...addFlex({ x: "left", y: "top" }),
        overflow: "auto",
        width: "100%",
        maxHeight: `${window.innerHeight - 30}px`,
      }}
    >
      <div
        css={{
          width: isMobile ? "100%" : "600px",
          height: "auto",
          ...addPadding(
            screenIs({
              default: {
                top: "36px",
                bottom: "0",
                horizontal: "50px",
              },
              tablet: {
                top: "36px",
                bottom: "0",
                horizontal: "40px",
              },
              mobile: { top: "109px", horizontal: "20px", bottom: "0" },
            })
          ),
        }}
      >
        <h2 css={{ color: theme.searchBarBorder }}>Submit enquiry</h2>
        <Paragraph type="small">All fields are required.</Paragraph>
        <form
          onSubmit={async (e) => {
            e.preventDefault();
            setIsSubmitting(true);
            if (isValid) {
              const validationToken =
                (await getValidationToken(`submitContactForm`)) || "";
              await submitEnquiryFormAction(
                inputsStates,
                validationToken,
                facility?.institutionId || "",
                facility?.id || "",
                searchTerm || "",
                setIsFormSubmittedSuccessfully
              );
            }
            setIsSubmitting(false);
          }}
        >
          {!tenantConfig && (
            <div css={{ ...addSize({ size: "120px" }) }}>
              <LoadingSpinner height="calc(100vh - 390px)" isLoading={true} />
            </div>
          )}

          {tenantConfig && tenantConfig.enquiry.firstName && (
            <SendableFormInputWrapper
              {...addInputIdProps(`firstName`)}
              label={`First name`}
              required
              placeholder={`First name`}
            />
          )}
          {tenantConfig && tenantConfig.enquiry.lastName && (
            <SendableFormInputWrapper
              {...addInputIdProps(`lastName`)}
              label={`Last name`}
              required
              placeholder={`Last name`}
            />
          )}
          {tenantConfig && tenantConfig.enquiry.userType && (
            <SendableFormInputWrapper
              {...addInputIdProps(`userType`)}
              label={`Type Of Customer`}
              required
              inputElementType={`radio`}
              placeholder={`userType`}
              radioOptions={USER_TYPES}
            />
          )}
          {tenantConfig &&
            tenantConfig.enquiry.organisation &&
            inputsStates["userType"].value !== "PRIVATE" && (
              <SendableFormInputWrapper
                {...addInputIdProps(`organisation`)}
                label={``}
                required
                inputElementType={"text"}
                placeholder={organisationPlaceholder}
              />
            )}

          {tenantConfig && tenantConfig.enquiry.email && (
            <SendableFormInputWrapper
              {...addInputIdProps(`email`)}
              label={`Email`}
              required
              inputElementType={"email"}
              placeholder={`Email`}
            />
          )}
          {tenantConfig && tenantConfig.enquiry.phone && (
            <SendableFormInputWrapper
              {...addInputIdProps(`phone`)}
              label={`Phone`}
              required
              inputElementType={"phone"}
              placeholder={`Phone`}
            />
          )}
          {tenantConfig && tenantConfig.enquiry.comment && (
            <SendableFormInputWrapper
              {...addInputIdProps(`comment`)}
              label={`Comment`}
              inputElementType={`textArea`}
              required
              placeholder={`What equipment are you interested in?`}
            />
          )}
          <CaptchaNotice />
          <FormButtons
            submitText={"Send"}
            submitDisabled={!isValid}
            isSubmitting={isSubmitting}
          />
        </form>
      </div>
    </div>
  );
};

export default SubmitEnquiryForm;
