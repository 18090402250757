/** @jsxImportSource @emotion/react */
import { jsx } from "@emotion/react";
import { ThemeProvider } from "@emotion/react";

import tenantConfig from "tenants";

import { addFlex, addPadding, addSize } from "utils";
import { useScreenSizeStore } from "stores";
import BasicIcon from "components/BasicIcon";

const ExternalTenantNavigation = () => {
  const { screenIs } = useScreenSizeStore();

  const { externalNavigation } = tenantConfig;

  return (
    <div
      css={{
        height: 32,
        width: "100%",
        backgroundColor: "#2F2F2F",
        color: "#FFF",
        ...addFlex({ y: "center" }),
        ...addPadding({
          horizontal: screenIs({
            default: "32px",
            tablet: "25px",
            mobile: "0px",
          }),
        }),
      }}
    >
      <BasicIcon
        size={screenIs({
          default: 20,
          mobile: 18,
        })}
        name={`Home`}
        color={`#FFF`}
      />
      <a
        href={externalNavigation?.link}
        css={{
          color: "white",
          fontSize: 14,
          marginLeft: 10,
          fontWeight: 700,
        }}
      >
        {externalNavigation?.label}
      </a>
    </div>
  );
};

export default ExternalTenantNavigation;
