import { create } from "zustand";
import getGeoLocation from "services/geoLocation";
import { GeoLocation } from "utils/types";

type LocationStoreState = {
  geoLocation: GeoLocation | null;
  isLoadingLocation: boolean;
};

type LocationStore = LocationStoreState & {
  setIsLoadingLocation: (isLoadingLocation: boolean) => void;
  resetGeoLocation: () => void;
  setLocation: (geoLocation: GeoLocation) => void;
  getUserGeoLocation: () => Promise<GeoLocation | null>;
};

const useLocationStore = create<LocationStore>((set, get) => ({
  geoLocation: null,
  isLoadingLocation: false,
  resetGeoLocation: () => set(() => ({ geoLocation: null })),
  setLocation: (geoLocation: GeoLocation) => set(() => ({ geoLocation })),
  setIsLoadingLocation: (isLoadingLocation: boolean) =>
    set(() => ({ isLoadingLocation })),
  getLocation: () => get().geoLocation,
  getUserGeoLocation: async () => {
    get().setIsLoadingLocation(true);
    const position = await getGeoLocation();
    get().setIsLoadingLocation(false);
    if (position && position.coords) {
      const { latitude, longitude } = position.coords;
      get().setLocation({ latitude, longitude });
      return { latitude, longitude };
    }
    return null;
  },
}));

export { useLocationStore };
