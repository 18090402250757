import { gql } from "apollo-boost";

import Api from "services/api";

export type TenantConfig = {
  feedback: {
    comment: boolean;
  };
  enquiry: {
    comment: boolean;
    email: boolean;
    firstName: boolean;
    lastName: boolean;
    organisation: boolean;
    phone: boolean;
    userType: boolean;
  };
  programs: {
    enabled: boolean;
    ncrisInTopNav: boolean;
    ncrisInHomePage: boolean;
    inSearchResults: boolean;
    inFacilityPage: boolean;
  };
};

type getTenantConfigResponse = { getTenantConfig: TenantConfig };

const getTenantConfig = () =>
  Api.query<getTenantConfigResponse>({
    query: gql`
      {
        getTenantConfig {
          feedback {
            comment
          }
          enquiry {
            comment
            email
            firstName
            lastName
            organisation
            phone
            userType
          }
          programs {
            enabled
            ncrisInTopNav
            ncrisInHomePage
            inSearchResults
            inFacilityPage
          }
        }
      }
    `,
  }).then((result) => result);

export default getTenantConfig;
