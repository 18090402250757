/** @jsxImportSource @emotion/react */
import { jsx } from "@emotion/react";
import React from "react";
import { addFlex, addSize } from "utils";
import Paragraph from "components/Paragraph";
import { useScreenSizeStore } from "stores";

type Props = { count: string };

const NumberCount: React.FC<Props> = ({ count }) => {
  const { screenIs } = useScreenSizeStore();

  return (
    <div
      css={{
        ...addFlex({ x: "center", y: "center" }),
        ...addSize({
          width: screenIs({
            default: { default: "45px", min: "45px" },
            tablet: { default: "40px", min: "40px" },
            mobile: { default: "30px", min: "30px" },
          }),
          height: screenIs({
            default: "32px",
            tablet: "25px",
            mobile: "20px",
          }),
        }),
        position: "relative",
        background: "#2F5673",
        borderRadius: "100px",
        color: "#fff",
        fontWeight: "bold",
        marginRight: "10px",
      }}
    >
      <Paragraph bold type="small">
        {count.toString()}
      </Paragraph>
    </div>
  );
};

export default NumberCount;
