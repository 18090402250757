/** @jsxImportSource @emotion/react */
import { jsx, css } from "@emotion/react";
import React, { useState, Fragment } from "react";
import { useTheme } from "@emotion/react";

import BasicIcon from "components/BasicIcon";
import { useModalStore, useScreenSizeStore, useFacilityStore } from "stores";
import { addSize, addFlex, addMargin, addPadding } from "utils";
import { OrganisationTypes } from "utils/types";
import Paragraph from "components/Paragraph";
import { AllIconNames } from "icons";
import RadioInputOption from "../RadioInputOption";

type Props = {
  inputId: string;
  options: { value: string; label: string; icon: AllIconNames }[];
  onChange: (newValue: string) => void;
  value: string;
};

const BoxIconRadioInput: React.FC<Props> = ({
  inputId,
  options,
  onChange,
  value,
}) => {
  const { isMobile, screenIs } = useScreenSizeStore();
  const theme = useTheme();

  return (
    <div
      css={{
        display: "flex",
        flexDirection: "row",
        justifyContent: "space-between",
      }}
    >
      {options.map((loopedOption, index) => {
        const mainColor = "#228192";
        const isLastOption = index === options.length - 1;

        return (
          <Fragment key={loopedOption.value}>
            <label
              css={{
                position: "relative",
                ...addFlex({ x: "center", y: "edges", direction: "down" }),
                border: screenIs({
                  default: `2px solid ${theme.mainColor}`,
                  mobile: `1px solid ${theme.mainColor}`,
                }),
                boxSizing: "border-box",
                borderus: "4px",
                padding: "24px",
                textAlign: "center",
                color: theme.mainColor,
                cursor: "pointer",
                //
                width: "100%",
                background: "#fff",
                fontWeight: "bold",
                borderRadius: "4px",

                ...addPadding(
                  screenIs({
                    default: { vertical: "22px", horizontal: "34px" },
                    mobile: { default: "12px" },
                  })
                ),
              }}
              htmlFor={inputId}
              onClick={() => {
                onChange(loopedOption.value);
              }}
            >
              <div
                css={{
                  ...addFlex({ x: "center", y: "top", direction: "down" }),
                }}
              >
                <BasicIcon
                  color={theme.mainColor}
                  name={loopedOption.icon}
                  size={screenIs({ default: 24, mobile: 20 })}
                />
                <Paragraph
                  bold
                  css={{
                    textAlign: "center",
                    fontSize: isMobile ? "10px" : "14px",
                    lineHeight: isMobile ? "14px" : "20px",
                    ...addMargin(
                      screenIs({
                        default: {
                          top: "7px",
                          bottom: "18px",
                        },
                      })
                    ),
                  }}
                >
                  {loopedOption.label}
                </Paragraph>
              </div>
              <div
                css={{
                  position: "relative",
                  ...addFlex({ x: "center", y: "center" }),
                }}
              >
                <RadioInputOption
                  color={theme.mainColor}
                  name={inputId}
                  id={loopedOption.value}
                  activeOption={value}
                  value={loopedOption.label}
                />
              </div>
            </label>
            {!isLastOption && (
              <div
                css={{
                  ...addSize({
                    size: screenIs({
                      desktop: { default: "20px", min: "20px" },
                      default: { default: "10px", min: "10px" },
                    }),
                  }),
                }}
              />
            )}
          </Fragment>
        );
      })}
    </div>
  );
};

export default BoxIconRadioInput;
