/** @jsxImportSource @emotion/react */
import { jsx } from "@emotion/react";
import { useTheme } from "@emotion/react";
import React from "react";

type Props = {
  currentStep: number;
  maxSteps: number;
};

const MultiStepFormCount: React.FC<Props> = ({ currentStep, maxSteps }) => {
  const theme = useTheme();
  return (
    <div
      css={{
        fontFamily: theme.fonts.main,
        fontWeight: 800,
        fontSize: "12px",
        lineHeight: "15px",
        letterSpacing: "0.5px",
        textTransform: "uppercase",
        color: "#228192",
      }}
    >
      {currentStep} / {maxSteps} SUGGESTIONS
    </div>
  );
};

export default MultiStepFormCount;
