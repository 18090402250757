import { TenantThemeVars } from "../tenant";

const monashUniTheme: TenantThemeVars = {
  mainColor: "#006DAE",
  topFocusBoxHeading: "#FFF",
  topFocusBoxBackground:
    "linear-gradient(127.75deg, #006DAE 33.47%, #1B436A 137.4%)",
  softBackground: "#006DAE10",
  locationPinColor: "#1B436A",
  searchBarBorder: "#1B436A",
  searchBarBorderFocused: "#3c6d91",
  searchBarHover: "#20425A",
  searchOverlayBackground: "#2F5673",
  searchOverlayBorder: "#EEF4F6",
  inputCheckboxColor: "#006DAE",
  feedbackButtonActive: "#1B436A",
  feedbackButtonInactive: "#006DAE",
  staticSearchBorder: "#2F5673",
  resultsOverlayTextColor: "#50c3e0",
  searchMatchesSummaryCount: "#1B436A",
  footerAccent: "#228192",
  fonts: {
    googleFontsLink:
      "https://fonts.googleapis.com/css2?family=Muli:ital,wght@0,400;0,700;0,800;1,700&family=Roboto+Mono&display=swap",
    globalFontFamily: "'Muli', sans-serif;",
    heading: "Muli",
    main: "Muli",
  },
};

export default monashUniTheme;
