/** @jsxImportSource @emotion/react */
import { jsx } from "@emotion/react";
import { useTheme } from "@emotion/react";
import { useLocation } from "react-router-dom";
import React, { useRef, Fragment } from "react";
import BasicIcon from "components/BasicIcon";
import { useFacilityStore, useScreenSizeStore } from "stores";
import { addFlex, addSize, addPadding } from "utils";
import Paragraph from "components/Paragraph";
import FadeDiv from "components/FadeDiv";
import LoadingSpinner from "components/LoadingSpinner";
import { getRouteInfo } from "utils/routes";

import tenantConfig from "tenants";

type Props = {};

const ResultContent: React.FC<Props> = () => {
  const location = useLocation();
  const { screenIs } = useScreenSizeStore();
  const { current: localContext } = useRef({
    latestPathname: location.pathname,
  });
  localContext.latestPathname = location.pathname;
  const { facility } = useFacilityStore();
  const routeName = getRouteInfo(location.pathname).name;

  const theme = useTheme();

  return (
    <Fragment>
      <FadeDiv isVisible={routeName === "facility" && !facility}>
        <LoadingSpinner isLoading={!facility} height="100%" size={50} />
      </FadeDiv>
      <FadeDiv
        isVisible={!!facility}
        css={{
          ...addFlex({ x: "left", y: "center", direction: "down" }),
          ...addSize({
            size: "100%",
            height: { min: "200px" },
          }),
          ...addPadding(
            screenIs({
              default: { horizontal: "75px", top: "55px", bottom: "45px" },
              tablet: { horizontal: "25px", top: "43px", bottom: "29px" },
              mobile: { horizontal: "15px", top: "25px", bottom: "64px" },
            })
          ),
          label: "facility-result-focusbox",
        }}
      >
        <div
          css={{
            fontFamily: theme.fonts.heading,
            fontStyle: "normal",
            fontWeight: "bold",
            fontSize: screenIs({ default: "12px", mobile: "10px" }),
            lineHeight: "13px",
            letterSpacing: "0.5px",
            textTransform: "uppercase",
            marginBottom: "10px",
            color: theme.topFocusBoxHeading,
          }}
        >
          {tenantConfig.text.usePlatfrom ? "Platform" : "Facility"}
        </div>
        <h1
          data-automation={`facilityHeadline`}
          css={{
            color: theme.topFocusBoxHeading,
            fontFamily: theme.fonts.heading,
          }}
        >
          {facility?.title}
        </h1>

        <div
          css={{
            ...addFlex({ x: "left", y: "center" }),
            ...addSize({ width: "100%" }),
            ...addPadding({
              top: screenIs({
                default: "27px",
                tablet: "15px",
                mobile: "20px",
              }),
              right: screenIs({
                default: "420px",
                tablet: "0",
                mobile: "0",
              }),
            }),
            opacity: facility?.location ? 1 : 0,
            color: theme.topFocusBoxHeading,
          }}
        >
          <BasicIcon
            name="LocationPin"
            color={theme.topFocusBoxHeading}
            size={24}
            css={{
              transform: "translateY(2px)",
              marginRight: "8px",
            }}
          />
          <Paragraph type="small">{facility?.location}</Paragraph>
        </div>
      </FadeDiv>
    </Fragment>
  );
};

export default ResultContent;
