/** @jsxImportSource @emotion/react */
import { jsx, css, Theme, Interpolation } from "@emotion/react";

import React from "react";

type Props = {
  color: string;
  css: ReturnType<typeof css>;
};

const CheckIcon: React.FC<Props> = ({ color, css }) => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" css={css}>
      <defs>
        <path style={{ fill: color }} id="a" d="M0 0h24v24H0z" />
      </defs>
      <path
        style={{ fill: color }}
        d="M9 11.24V7.5a2.5 2.5 0 015 0v3.74A4.49 4.49 0 0011.5 3 4.5 4.5 0 009 11.24zm5.5 2.47a1.97 1.97 0 00-.89-.21H13v-6a1.5 1.5 0 10-3 0v10.74l-3.44-.72c-.37-.08-.76.04-1.03.31a1.13 1.13 0 000 1.58l4.01 4.01c.38.37.89.58 1.42.58h6.1a2 2 0 001.98-1.72l.63-4.47a2 2 0 00-1.09-2.07l-4.08-2.03z"
      />
    </svg>
  );
};

export default CheckIcon;
