// Edited from https://github.com/ReactTraining/react-router/blob/master/packages/react-router-dom/modules/Link.js

import React, { forwardRef } from "react";
import { __RouterContext as RouterContext } from "react-router";
import invariant from "tiny-invariant";
import { createLocation } from "history";

export const resolveToLocation = (to: any, currentLocation: any) =>
  typeof to === "function" ? to(currentLocation) : to;

export const normalizeToLocation = (to: any, currentLocation: any) => {
  return typeof to === "string"
    ? createLocation(to, null, undefined, currentLocation)
    : to;
};

function isModifiedEvent(event: any) {
  return !!(event.metaKey || event.altKey || event.ctrlKey || event.shiftKey);
}

const LinkAnchor: React.FC<any> = forwardRef(
  (
    {
      innerRef, // TODO: deprecate
      navigate,
      onClick,
      onClickCapture,
      ...rest
    },
    forwardedRef
  ) => {
    const { target } = rest;

    let props = {
      ...rest,
      onClick: (event: any) => {
        try {
          if (onClick) onClick(event);
        } catch (ex) {
          event.preventDefault();
          throw ex;
        }

        if (
          !event.defaultPrevented && // onClick prevented default
          event.button === 0 && // ignore everything but left clicks
          (!target || target === "_self") && // let browser handle "target=_blank" etc.
          !isModifiedEvent(event) // ignore clicks with modifier keys
        ) {
          event.preventDefault();
          navigate();
        }
      },
      onClickCapture: onClickCapture,
      onPointerUp: (event: PointerEvent) => {
        if (event.button === 1) {
          try {
            if (onClickCapture) onClickCapture(event);
          } catch (ex) {
            event.preventDefault();
            throw ex;
          }
        }
      },
    };
    props.ref = forwardedRef || innerRef;

    /* eslint-disable-next-line jsx-a11y/anchor-has-content */
    return <a {...props} />;
  }
);

/**
 * The public API for rendering a history-aware <a>.
 */
const CustomRouteLink: React.FC<any> = forwardRef(
  (
    {
      component = LinkAnchor,
      replace,
      to,
      innerRef, // TODO: deprecate
      ...rest
    },
    forwardedRef
  ) => {
    return (
      <RouterContext.Consumer>
        {(context) => {
          invariant(context, "You should not use <Link> outside a <Router>");

          const { history } = context;

          const location = normalizeToLocation(
            resolveToLocation(to, context.location),
            context.location
          );

          const href = location ? history.createHref(location) : "";
          const props: any = {
            ...rest,
            href,
            navigate() {
              const location = resolveToLocation(to, context.location);
              const method = replace ? history.replace : history.push;

              method(location);
            },
          };

          props.ref = forwardedRef || innerRef;

          return React.createElement(component, props);
        }}
      </RouterContext.Consumer>
    );
  }
);

export default CustomRouteLink;
