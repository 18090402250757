/** @jsxImportSource @emotion/react */
import { jsx, css } from "@emotion/react";

import React from "react";

type Props = {
  color: string;
  css: ReturnType<typeof css>;
};

const PortfolioIcon: React.FC<Props> = ({ color = "#3D3D3D", css }) => {
  return (
    <svg
      viewBox="0 0 24 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      css={css}
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M22.5 4.28571H18V1.42857c0-.3869-.1484-.721724-.4453-1.004461C17.2578.141369 16.9063 0 16.5 0h-9c-.40625 0-.75781.141369-1.05469.424109C6.14844.706846 6 1.04167 6 1.42857v2.85714H1.5c-.40625 0-.757811.14137-1.054688.42411C.148436 4.99256 0 5.32738 0 5.71429V18.5714c0 .3869.148436.7218.445312 1.0045C.742189 19.8586 1.09375 20 1.5 20h21c.4063 0 .7578-.1414 1.0547-.4241.2969-.2827.4453-.6176.4453-1.0045V5.71429c0-.38691-.1484-.72173-.4453-1.00447-.2969-.28274-.6484-.42411-1.0547-.42411zM7.41602 1.42857H16.416v2.85714H7.41602V1.42857z"
        fill={color}
      />
    </svg>
  );
};

export default PortfolioIcon;
