import { TenantThemeVars } from "../tenant";

const singascopeTheme: TenantThemeVars = {
  mainColor: "#B62326",
  topFocusBoxHeading: "#2F2F2F",
  topFocusBoxBackground: "#E4E4E8",
  softBackground: "#F6EEEE",
  locationPinColor: "#B62326",
  searchBarBorder: "#B52025",
  searchBarBorderFocused: "#B52025",
  searchBarHover: "#B62326",
  searchOverlayBackground: "#B52025",
  searchOverlayBorder: "#F6EEEE",
  inputCheckboxColor: "#000000",
  feedbackButtonActive: "#B52025",
  feedbackButtonInactive: "#B52025",
  searchMatchesSummaryCount: "#000",
  staticSearchBorder: "#B62326",
  resultsOverlayTextColor: "#FFECE4",
  footerAccent: "#2F2F2F",
  fonts: {
    googleFontsLink:
      "https://fonts.googleapis.com/css2?family=Source+Sans+Pro:ital,wght@0,400;0,700;0,800;1,700&family=Montserrat&display=swap",
    globalFontFamily: "'Source Sans Pro', sans-serif",
    heading: "Montserrat",
    main: "'Source Sans Pro', monospace",
  },
};

export default singascopeTheme;
