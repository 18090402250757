import { Alternative } from "utils/types";
import { imagesAreConsideredSufficient } from "./validateQuality/images";
import { descriptionIsConsideredSufficient } from "./validateQuality/description";
import { titleIsConsideredSufficient } from "./validateQuality/title";
import {
  SuggestionCollection,
  Capability,
} from "stores/capabilitySuggestion/types";
import { getRandomArrayItem } from "utils";
import { filterViableDescriptions } from "./utils";

type AlternativeSuggestion = Pick<Alternative, "value" | "id">;

export type CapabilitySuggestionFields = {
  images: AlternativeSuggestion[] | undefined;
  title: AlternativeSuggestion | undefined;
  description: AlternativeSuggestion | undefined;
};

type CapabilityNeedSuggestion = {
  images: boolean;
  title: boolean;
  description: boolean;
};

function getCapabilitySuggestion(
  suggestions: SuggestionCollection,
  capabilitySuggestionsStatus: CapabilityNeedSuggestion
): CapabilitySuggestionFields {
  const images = suggestions.images?.map(({ value, id }) => ({
    value,
    id,
  }));

  const titles = suggestions.title?.map(({ value, id }) => ({
    value,
    id,
  }));
  const descriptions = suggestions.descriptions?.map(({ value, id }) => ({
    value,
    id,
  }));

  const viableDescriptions = descriptions
    ? filterViableDescriptions(descriptions)
    : [];

  return {
    images:
      images?.length && capabilitySuggestionsStatus.images ? images : undefined,
    title: titles && capabilitySuggestionsStatus.title ? titles[0] : undefined,
    description:
      viableDescriptions.length > 0 && capabilitySuggestionsStatus.description
        ? getRandomArrayItem(viableDescriptions)
        : undefined,
  };
}
function whatCapabilityPropertiesNeedsSuggestion({
  images,
  title,
  description,
}: Capability): CapabilityNeedSuggestion {
  return {
    images: !imagesAreConsideredSufficient(images),
    title: !titleIsConsideredSufficient(title),
    description: !descriptionIsConsideredSufficient(description),
  };
}

function capabilityNeedsSuggestions(
  capabilitySuggestionsStatus: CapabilityNeedSuggestion
): boolean {
  return !!Object.values(capabilitySuggestionsStatus).filter(
    (value) => value === true
  ).length;
}
export function getCapabilitySuggestions(
  suggestions: SuggestionCollection,
  capability: Capability
): CapabilitySuggestionFields {
  const capabilitySuggestionsStatus = whatCapabilityPropertiesNeedsSuggestion(
    capability
  );
  return capabilityNeedsSuggestions(capabilitySuggestionsStatus)
    ? getCapabilitySuggestion(suggestions, capabilitySuggestionsStatus)
    : {
        images: undefined,
        title: undefined,
        description: undefined,
      };
}
