/** @jsxImportSource @emotion/react */
import { jsx } from "@emotion/react";
import React, { useEffect } from "react";
import scrollIntoView from "scroll-into-view-if-needed";
import { addFlex, addPadding, addSize, addMargin } from "utils";
import { useScreenSizeStore } from "stores";
import Paragraph from "components/Paragraph";

type Props = {};

const CookiesContent: React.FC<Props> = () => {
  useEffect(() => {
    // scroll to the top when opening the cookies page
    scrollIntoView(document.body, {
      scrollMode: "if-needed",
      block: "start",
      behavior: "smooth",
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const { screenIs } = useScreenSizeStore();

  const paragraphTitleCss = {
    ...addMargin(
      screenIs({ default: { bottom: "14px" }, mobile: { bottom: "10px" } })
    ),
  };
  const paragraphCss = {
    ...addMargin(
      screenIs({ default: { bottom: "50px" }, mobile: { bottom: "25px" } })
    ),
  };
  const lineBreakCss = {
    ...addSize({ width: "100%", height: "1px" }),
    ...addMargin(
      screenIs({ default: { bottom: "50px" }, mobile: { bottom: "25px" } })
    ),
    backgroundColor: "#D5D5D5",
  };

  return (
    <div
      css={{
        ...addFlex({ x: "center", y: "center", direction: "down" }),
        position: "relative",
        top: 0,
        left: 0,
        ...addPadding(
          screenIs({
            default: { default: "50px", top: "100px" },
            tablet: { default: "50px", top: "55px" },
            mobile: { horizontal: "0px", vertical: "30px", top: "40px" },
          })
        ),
        width: "100%",
      }}
    >
      <div
        css={{
          ...addSize({ width: { default: "100%", max: "800px" } }),
        }}
      >
        <Paragraph css={paragraphCss}>
          At ARIN, we believe in being clear and open about how we collect and
          use data related to you. In the spirit of transparency, this policy
          provides detailed information about how and when we use cookies. This
          cookie policy applies to any ARIN product or service that links to
          this policy or incorporates it by reference.reference. The ARIN Portal
          is managed by Monash University (ABN 12 377 614 012) and subject to
          the Monash Websites Terms and Conditions available at{" "}
          <a href="https://www.monash.edu/terms-and-conditions">
            https://www.monash.edu/terms-and-conditions
          </a>
          .
        </Paragraph>
        <div css={lineBreakCss} />
        <h3 css={paragraphTitleCss}>Does Monash use cookies?</h3>
        <Paragraph css={paragraphCss}>
          Yes. We use cookies and other technologies to ensure everyone who uses
          ARIN portal has the best possible experience. Cookies also help us
          keep your account safe. By continuing to visit or use our services,
          you are agreeing to the use of cookies and similar technologies for
          the purposes we describe in this policy.
        </Paragraph>
        <div css={lineBreakCss} />
        <h3 css={paragraphTitleCss}>What is a cookie?</h3>
        <Paragraph css={paragraphCss}>
          A cookie is a small file placed onto your device that enables ARIN
          features and functionality. For example, cookies enable us to identify
          your device, secure your access to ARIN and may help us serve relevant
          ads to you.
        </Paragraph>
        <div css={lineBreakCss} />
        <h3 css={paragraphTitleCss}>When does Monash place cookies?</h3>
        <Paragraph css={paragraphCss}>
          We use cookies on our sites and mobile applications. Any browser
          visiting these sites will receive cookies from us. We also may place
          cookies in your browser when you visit non-ARIN sites that host our
          plugins or tags.
        </Paragraph>
        <div css={lineBreakCss} />
        <h3 css={paragraphTitleCss}>What types of cookies does Monash use?</h3>
        <Paragraph css={paragraphCss}>
          We may use two types: persistent cookies and session cookies. A
          persistent cookie helps us recognize you as an existing (returning)
          user, so it’s easier to return to ARIN Portal or interact with our
          services without signing in. After you sign in, a persistent cookie
          may stay in your browser and will be read by ARIN when you return to
          one of our sites or a partner site that uses our services. Session
          cookies only last for as long as the session (usually the current
          visit to a website or a browser session).
        </Paragraph>
        <div css={lineBreakCss} />
        <h3 css={paragraphTitleCss}>What are cookies used for?</h3>
        <Paragraph css={paragraphCss}>
          Cookies can be used to recognize you when you visit ARIN portal,
          remember your preferences, and give you a personalized experience
          that’s in line with your settings. Cookies also make your interactions
          with ARIN faster and more secure. Additionally, cookies may allow us
          to bring you advertising both on and off the ARIN sites, and bring
          customized features to you through ARIN plugins.
        </Paragraph>
      </div>
    </div>
  );
};

export default CookiesContent;
