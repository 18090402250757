/** @jsxImportSource @emotion/react */
import { jsx, css } from "@emotion/react";

import React from "react";

type Props = {
  color: string;
  css: ReturnType<typeof css>;
};

const SettingsIcon: React.FC<Props> = ({ color = "#3D3D3D", css }) => {
  return (
    <svg
      viewBox="0 0 24 22"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      css={css}
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M20.4 7.24224c2 0 3.6-1.63976 3.6-3.68945C24 1.50311 22.4 0 20.4 0c-1.6 0-2.9333.956522-3.3333 2.45963H0v2.45962h17.0667c.4 1.36646 1.7333 2.32299 3.3333 2.32299zm0-4.78261c.6667 0 1.2.54658 1.2 1.22981 0 .68323-.5333 1.22981-1.2 1.22981s-1.2-.54658-1.2-1.22981c0-.68323.5333-1.22981 1.2-1.22981zm-12 4.78261c-1.6 0-2.93333.95652-3.33333 2.45962H0v2.45964h5.06667C5.6 13.5279 6.93333 14.6211 8.4 14.6211c1.46667 0 2.9333-.9565 3.3333-2.4596H24V9.70186H11.7333C11.3333 8.3354 10 7.24224 8.4 7.24224zm0 4.91926c-.66667 0-1.2-.5466-1.2-1.2298 0-.6833.53333-1.22984 1.2-1.22984s1.2.54654 1.2 1.22984c0 .6832-.53333 1.2298-1.2 1.2298zm12 2.4596c-1.6 0-2.9333.9565-3.3333 2.4596H0v2.4597h17.0667C17.6 20.9068 18.9333 22 20.4 22c2 0 3.6-1.6398 3.6-3.6894 0-2.0497-1.6-3.6895-3.6-3.6895zm0 4.7826c-.6667 0-1.2-.5466-1.2-1.2298s.5333-1.2298 1.2-1.2298 1.2.5466 1.2 1.2298-.5333 1.2298-1.2 1.2298z"
        fill={color}
      />
    </svg>
  );
};

export default SettingsIcon;
