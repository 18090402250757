/** @jsxImportSource @emotion/react */
import { jsx } from "@emotion/react";

import React from "react";

type Props = {
  text: string;
  noWrap: boolean;
};

const TooltipItem: React.FC<Props> = ({ text, noWrap }) => {
  return (
    <div
      css={{
        position: "relative",
        padding: "8px",
        color: "#3D3D3D",
        fontSize: "14px",
        lineHeight: "20px",
        whiteSpace: noWrap ? "nowrap" : "pre-line",
        fontWeight: "normal",
        width: "100%",
        overflow: "hidden",
        textOverflow: "ellipsis",
      }}
    >
      {text}
    </div>
  );
};

export default TooltipItem;
