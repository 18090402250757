/** @jsxImportSource @emotion/react */
import { jsx } from "@emotion/react";
import { useTheme } from "@emotion/react";
import React from "react";
import Paragraph from "components/Paragraph";
import { useScreenSizeStore } from "stores";
import tenantConfig from "tenants";

type Props = {};

const AllResultsFilteredOut: React.FC<Props> = () => {
  const { screenIs } = useScreenSizeStore();
  const theme = useTheme();

  return (
    <div
      css={{
        textAlign: "center",
        padding: screenIs({
          default: "100px 0",
          tablet: "32px 0",
          mobile: "16px 0",
        }),
      }}
    >
      <Paragraph type={`intro`} bold css={{ color: theme.mainColor }}>
        You don’t have any organisations selected.
      </Paragraph>
      <Paragraph css={{ padding: "16px 0" }}>
        Please select at least one organisation to show{" "}
        {tenantConfig.text.usePlatfrom ? "platform" : "facility"} results.
      </Paragraph>
    </div>
  );
};

export default AllResultsFilteredOut;
