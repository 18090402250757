/** @jsxImportSource @emotion/react */
import { jsx, css } from "@emotion/react";

import React from "react";

type Props = {
  color: string;
  css: ReturnType<typeof css>;
};

const BlocksIcon: React.FC<Props> = ({ color = "#3D3D3D", css }) => {
  return (
    <svg
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      css={css}
    >
      <path
        d="M8.75456 5.3199V12.9975L15.0832 10.1159V2.53904L8.75456 5.3199ZM15.5294 16.3224V24L22 21.0378V13.4811L15.5294 16.3224ZM15.002 16.262V23.9395L8.67343 21.0579V13.4811L15.002 16.262ZM8.3286 5.3602V13.0378L2 10.136V2.57934L8.3286 5.3602ZM2.3854 2.33753L8.57201 4.97733L14.7586 2.21662L8.61258 0L2.3854 2.33753ZM9.13996 13.2393L15.2657 15.8791L21.716 13.0982L15.3874 10.4584L9.13996 13.2393Z"
        fill={color}
      />
    </svg>
  );
};

export default BlocksIcon;
