/** @jsxImportSource @emotion/react */
import { jsx } from "@emotion/react";
import { useTheme } from "@emotion/react";
import React, { useState } from "react";

import { getAnalyticsPublisher } from "services/analytics";
import arinCircle from "assets/images/arinCircle.png";

import BasicIcon from "components/BasicIcon";
import BasicButton from "components/BasicButton";
import Link from "components/LinkUnderline";
import {
  useScreenSizeStore,
  userOrganisationStore,
  useFacilityStore,
} from "stores";
import { addSize, addFlex, addMargin, addPadding } from "utils";
import { ORGANISATION_TYPES } from "utils/constants";
import { Facility, OrganisationTypes } from "utils/types";
import Paragraph from "components/Paragraph";
import BoxIconRadioInput from "components/inputs/BoxIconRadioInput";
import { showFacilityThankyouMessage } from "components/SendableForm/utils/FormHelpers";
import useStackedModals from "stores/modals";
import { QuestionsProps } from "stores/utils/storeMakers/makeModalStore/types";
import tenantConfig from "tenants";
import { logEvent } from "firebase/analytics";

type Props = QuestionsProps;

function trackProvidedOrganisationType(
  facility: Facility | undefined | null,
  providedOrganisationType: string | undefined
) {
  logEvent(getAnalyticsPublisher(), `closeQuickQuestionModal`, {
    facilityId: facility?.id || `noId`,
    providedOrganisationType: providedOrganisationType || `notGiven`,
  });
}

const QuickQuestion: React.FC<Props> = ({ shouldShowThankyou = false }) => {
  const { closeTopModal } = useStackedModals();
  const { isMobile, screenIs } = useScreenSizeStore();
  const theme = useTheme();

  const setUserOrganisationType = userOrganisationStore(
    (state) => state.setUserOrganisationType
  );
  const facility = useFacilityStore((state) => state.facility);
  const [activeOrganisationType, setActiveOrganisationType] = useState(
    undefined as OrganisationTypes | undefined
  );

  return (
    <div
      role="alert"
      css={{
        ...addSize({
          size: "100%",
          width: { max: screenIs({ default: "600px", tablet: "530px" }) },
        }),
        ...addFlex({ x: "left", y: "top", direction: "down" }),
        borderRadius: screenIs({ default: "15px", mobile: "10" }),
        overflow: "hidden",

        backgroundColor: "#ffffff",
      }}
    >
      <div
        css={{
          ...addFlex({ x: "left", y: "center" }),
          width: "100%",
          ...addPadding(
            screenIs({
              default: {
                top: "36px",
                bottom: "0px",
                horizontal: "50px",
              },
              tablet: {
                top: "36px",
                bottom: "0px",
                horizontal: "40px",
              },
              mobile: { top: "39px", horizontal: "20px", bottom: "0px" },
            })
          ),
        }}
      >
        {!isMobile && (
          <div
            role="img"
            aria-label=""
            title="Periscope character"
            css={{
              label: "arin-image",
              position: "relative",
              ...addSize(
                screenIs({
                  default: {
                    width: { default: "82px", min: "82px" },
                    height: { default: "76px", min: "76px" },
                  },
                  mobile: { width: `82px`, height: "76px" },
                })
              ),
              backgroundImage: `url("${tenantConfig.images.quickQuestions}")`,
              backgroundSize: "cover",
              backgroundPosition: "center top",
              overflow: "hidden",
            }}
          ></div>
        )}
        <h3
          css={{
            color: theme.searchBarBorder,
            ...addMargin({ left: screenIs({ default: "21px", mobile: "0" }) }),
            textAlign: screenIs({ default: "left", mobile: "center" }),
          }}
        >
          {" "}
          Are you from industry or a research organisation?
        </h3>
      </div>

      <BasicButton
        label="close description"
        onClick={closeTopModal}
        css={{
          ...addSize({ size: "50px" }),
          position: "absolute",
          top: "0",
          right: "0",
          transform: screenIs({
            default: `translate(-5px, 5px)`,
            mobile: `translate(-0px, -0px)`,
          }),
          borderRadius: "50px",
        }}
        innerCss={{
          ...addFlex({ x: "center", y: "center" }),
          ...addSize({ size: "50px" }),
          borderRadius: "50px",
        }}
      >
        <BasicIcon
          name="Close"
          color="#949494"
          size={screenIs({ default: 26, mobile: 22 })}
        />
      </BasicButton>

      <div
        css={{
          ...addSize({ width: "100%", height: "100%" }), // fixes content on IE11
          ...addPadding(
            screenIs({
              default: { horizontal: "50px", bottom: "40px", top: "17px" },
              tablet: { default: "40px", bottom: "30px", top: "12px" },
              mobile: { default: "20px", top: "10px", bottom: "16px" },
            })
          ),
          overflow: "auto",
        }}
      >
        <div
          css={{
            ...addFlex({ x: "center", y: "top" }),
            backgroundColor: "#ffffff",
          }}
        >
          <Paragraph
            type="small"
            css={{
              ...addMargin({
                bottom: screenIs({
                  default: "24px",
                  tablet: "20px",
                  mobile: "15px",
                }),
              }),
              textAlign: screenIs({ default: "left", mobile: "center" }),
            }}
          >
            Asking who’s using {tenantConfig.name}{" "}
            <span css={{ fontWeight: "bold" }}>
              helps us to better understand the connections{" "}
            </span>
            between industry and research organisations - allowing us to{" "}
            <span css={{ fontWeight: "bold" }}>
              continuously design a better platform
            </span>
            .
          </Paragraph>
        </div>
        <BoxIconRadioInput
          inputId="organisationType"
          options={[
            {
              icon: "Building",
              label: "I’m from industry",
              value: ORGANISATION_TYPES.industry,
            },
            {
              icon: "ScholarHat",
              label: "I’m from a research organisation",
              value: ORGANISATION_TYPES.research,
            },
          ]}
          value={activeOrganisationType as string}
          onChange={(newValue) =>
            setActiveOrganisationType(newValue as OrganisationTypes)
          }
        />
        <div
          css={{
            ...addFlex(
              screenIs({
                default: { x: "edges", y: "center" },
                mobile: { x: "center", y: "center", direction: "down" },
              })
            ),
            ...addMargin({
              top: screenIs({ default: "20px", mobile: "15px" }),
              horizontal: "0",
            }),
          }}
        >
          {/* This is an invisible 'skip' button, so the 'Continue' button stays centered */}
          {!isMobile && (
            <div css={{ opacity: 0, pointerEvents: "none" }}>
              <Paragraph
                bold
                css={{
                  marginTop: "11px",
                  ...addFlex({ x: "left", y: "center" }),
                }}
              >
                <Link disabled={true} label="Close modal" onClick={() => {}}>
                  Skip
                </Link>
              </Paragraph>
            </div>
          )}
          <BasicButton
            label="Continue"
            onClick={() => {
              if (activeOrganisationType) {
                setUserOrganisationType(activeOrganisationType);
              }
              trackProvidedOrganisationType(facility, activeOrganisationType);

              if (shouldShowThankyou) {
                showFacilityThankyouMessage();
              } else {
                closeTopModal();
              }
            }}
            disabled={activeOrganisationType === undefined}
            css={{
              opacity: activeOrganisationType === undefined ? 0.2 : 1,
              border: `1px solid ${theme.mainColor}`,
              backgroundColor: theme.mainColor,
              borderRadius: "8px",
            }}
            innerCss={{
              ...addFlex({ x: "center", y: "center" }),
              ...addSize({ width: "160px", height: "50px" }),
              whiteSpace: "nowrap",
              color: "#fff",
            }}
          >
            <Paragraph bold>Continue</Paragraph>
          </BasicButton>
          <Paragraph
            bold
            css={{
              marginTop: "11px",
              ...addFlex({ x: "left", y: "center" }),
            }}
          >
            <Link
              label="Skip question"
              onClick={() => {
                setUserOrganisationType(ORGANISATION_TYPES.none);
                trackProvidedOrganisationType(facility, activeOrganisationType);
                closeTopModal();
              }}
            >
              Skip
            </Link>
          </Paragraph>
        </div>
      </div>
    </div>
  );
};

export default QuickQuestion;
