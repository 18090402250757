/** @jsxImportSource @emotion/react */
import { jsx } from "@emotion/react";
import { useTheme } from "@emotion/react";
import React, { useState, useCallback, useEffect } from "react";
import {
  useScreenSizeStore,
  useSendFeedbackForm,
  useSearchResultsStore,
  useClickedCapabilitiesStore,
  useLocationStore,
} from "stores";
import shallow from "zustand/shallow";
import { addFlex, addPadding, addSize } from "utils";

import { getValidationToken } from "services/validationToken";
import FormButtons from "components/SendableForm/FormButtons";
import SendableFormInputWrapper from "components/inputs/SendableFormInputWrapper";
import { FeedbackType } from "stores/utils/storeMakers/makeModalStore/types";
import submitFeedback from "services/api/submitFeedback";
import getTenantConfig, { TenantConfig } from "services/api/tenantConfig";
import { FormInputsStates } from "stores/utils/storeMakers/makeFormStore/types";
import { useLocation } from "react-router-dom";
import CaptchaNotice from "components/CaptchaNotice";
import { NextFormAction } from "../types";
import { useShowThankyouAfterSubmittingEffect } from "components/SendableForm/utils/FormHelpers";
import LoadingSpinner from "components/LoadingSpinner";

type Props = {
  feedBackTypeText?: string;
  feedBackType?: FeedbackType;
  nextAction?: NextFormAction;
};
function getPageType(locationPathName: string): string {
  const locationBase = locationPathName.split(`/`)[1];
  console.log(locationBase);
  switch (locationBase) {
    case "facility":
      return "Facility";
    case "search":
      return "SearchResult";
    default:
      return "";
  }
}

async function submitSendFeedbackFormAction(
  { comment }: FormInputsStates,
  page: string,
  validationToken: string,
  feedBackType: string,
  searchTerm: string,
  capabilities: string,
  location: string,
  success: (outcome: boolean) => void
): Promise<void> {
  await submitFeedback({
    searchTerm,
    page,
    capabilities,
    location,
    reason: feedBackType,
    comment: comment.value.toString(),
    captcha: validationToken,
  }).then(() => success(true));
}

const SendFeedbackForm: React.FC<Props> = ({
  feedBackTypeText,
  feedBackType,
}) => {
  const [
    updateInputValue,
    inputsStates,
    toggleFocus,
    refreshForm,
    isValid,
    setImmutableForForm,
  ] = useSendFeedbackForm(
    (state) => [
      state.updateInputValue,
      state.inputsStates,
      state.toggleFocus,
      state.refreshForm,
      state.isValid,
      state._setImmutable,
    ],
    shallow
  );
  const { screenIs, isMobile } = useScreenSizeStore();
  const searchTerm = useSearchResultsStore((state) => state.searchTerm);
  const theme = useTheme();

  const getAllClickedCapabilities = useClickedCapabilitiesStore(
    (state) => state.getAllClickedCapabilities
  );
  const { geoLocation } = useLocationStore();

  const location = useLocation();

  const [isSubmitting, setIsSubmitting] = useState(false);
  const [isFormSubmittedSuccessfully, setIsFormSubmittedSuccessfully] =
    useState(false);
  const [tenantConfig, setTenantConfig] = useState(null as null | TenantConfig);

  useEffect(() => {
    async function fetchTenantConfig() {
      const res = await getTenantConfig();
      setTenantConfig(res.data.getTenantConfig);
      if (!res.data.getTenantConfig.feedback.comment) {
        setImmutableForForm((draftState) => {
          draftState.inputsStates["comment"].validatorTypes = [];
        });
        // so validators run again
        updateInputValue({ inputId: "comment", newValue: "" });
      }
    }
    fetchTenantConfig();

    refreshForm({ initialValuesByInputId: {} });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const addInputIdProps = useCallback(
    (inputId: string) => {
      const inputState = inputsStates[inputId];
      return {
        id: inputId,
        value: inputState.value,
        onChange: (newValue: string | number) => {
          updateInputValue({ inputId, newValue });
        },
        errors: inputState.hasBeenUnfocused
          ? inputState.validationErrorTexts
          : [],
        onFocus: () => toggleFocus({ inputId, isFocused: true }),
        onBlur: () => toggleFocus({ inputId, isFocused: false }),
      };
    },
    [inputsStates, toggleFocus, updateInputValue]
  );

  useShowThankyouAfterSubmittingEffect({
    isFormSubmittedSuccessfully,
    title: "Thanks for your feedback",
  });

  return (
    <div
      css={{
        ...addFlex({ x: "left", y: "top" }),
        width: "100%",
      }}
    >
      <div
        css={{
          width: isMobile ? "100%" : "600px",
          height: "auto",
          ...addPadding(
            screenIs({
              default: {
                top: "36px",
                bottom: "0",
                horizontal: "50px",
              },
              tablet: {
                top: "36px",
                bottom: "0",
                horizontal: "40px",
              },
              mobile: { top: "39px", horizontal: "20px", bottom: "0" },
            })
          ),
        }}
      >
        <h2 css={{ color: theme.searchBarBorder }}>Send feedback</h2>
        {feedBackTypeText && (
          <p
            css={{
              fontWeight: 300,
              fontSize: "20px",
              lineHeight: "25px",
              color: theme.searchBarBorder,
              margin: 0,
            }}
          >
            {feedBackTypeText}
          </p>
        )}
        <form
          onSubmit={async (e) => {
            e.preventDefault();
            setIsSubmitting(true);

            const validationToken =
              (await getValidationToken(`submitFeedBackForm`)) || "";

            const capabilities = getAllClickedCapabilities()
              .map((capability) => capability.title)
              .join(", ");

            const formattedUserLocation = geoLocation
              ? `{longitude: ${geoLocation.longitude} latitude: ${geoLocation.latitude}}`
              : "";

            await submitSendFeedbackFormAction(
              inputsStates,
              getPageType(location.pathname),
              validationToken,
              feedBackType || ``,
              searchTerm || ``,
              capabilities,
              formattedUserLocation,
              setIsFormSubmittedSuccessfully
            );
            setIsSubmitting(false);
          }}
        >
          {feedBackType && (
            <input
              type={`hidden`}
              name={`feedBackTypeText`}
              value={feedBackType}
            />
          )}

          {!tenantConfig && (
            <div css={{ ...addSize({ size: "150px" }) }}>
              <LoadingSpinner height="calc(100vh - 390px)" isLoading={true} />
            </div>
          )}

          {tenantConfig && tenantConfig.feedback.comment && (
            <SendableFormInputWrapper
              {...addInputIdProps(`comment`)}
              label={`Comment*`}
              inputElementType={`textArea`}
              required
              placeholder={`Describe your issue or share your ideas`}
            />
          )}
          <CaptchaNotice />
          <FormButtons
            submitText={"Send"}
            submitDisabled={!isValid}
            isSubmitting={isSubmitting}
          />
        </form>
      </div>
    </div>
  );
};

export default SendFeedbackForm;
