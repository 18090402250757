const inputTypes = {
  text: {
    blankValue: "",
    defaultValidatorTypes: [],
    defaultValidatorsOptions: {},
  },
  number: {
    blankValue: "",
    defaultValidatorTypes: [],
    defaultValidatorsOptions: {},
  },
  textArea: {
    blankValue: "",
    defaultValidatorTypes: [],
    defaultValidatorsOptions: {},
  },
  radio: {
    blankValue: "",
    defaultValidatorTypes: [],
    defaultValidatorsOptions: {},
  },
};

export default inputTypes;
