/** @jsxImportSource @emotion/react */
import { jsx } from "@emotion/react";
import { useTheme } from "@emotion/react";
import React from "react";
import { useScreenSizeStore, useModalStore, useModalSecondStore } from "stores";
import shallow from "zustand/shallow";
import Paragraph from "components/Paragraph";
import BasicButton from "components/BasicButton";
import { addFlex, addSize } from "utils";

import arinCircle from "assets/images/arinCircle.png";
import { FormSentMessageProps } from "stores/utils/storeMakers/makeModalStore/types";
import tenantConfig from "tenants";

type Props = FormSentMessageProps;

const FormSentMessage: React.FC<Props> = ({ title, text }) => {
  const [closeModal] = useModalStore((state) => [state.closeModal], shallow);
  const [closeModalSecond, modalSecondAnimationState] = useModalSecondStore(
    (state) => [state.closeModal, state.animationState],
    shallow
  );
  const { isDesktop, screenIs } = useScreenSizeStore();
  const theme = useTheme();

  return (
    <div
      css={{
        ...addFlex({ x: "center", y: "center" }),
        flexDirection: "column",
        width: isDesktop ? "500px" : "100%",
        padding: screenIs({ default: "68px", tablet: "32px", mobile: "32px" }),
      }}
    >
      <div
        role="img"
        aria-label=""
        title=""
        css={{
          label: "arin-image",
          position: "absolute",
          top: screenIs({
            default: "-58.5px",
            tablet: "-58.5px",
            mobile: "-40px",
          }),
          ...addSize(
            screenIs({
              default: {
                width: "128px",
                height: "117px",
              },
              tablet: {
                width: "128px",
                height: "117px",
              },
              mobile: { width: "85px", height: "78px" },
            })
          ),
          backgroundImage: `url("${tenantConfig.images.quickQuestions}")`,
          backgroundSize: "cover",
          backgroundPosition: "center top",
          overflow: "hidden",
        }}
      ></div>
      <h3 css={{ color: theme.mainColor, marginBottom: "20px" }}>{title}</h3>
      {!!text && (
        <Paragraph
          css={{
            textAlign: "center",
          }}
        >
          {text}
        </Paragraph>
      )}
      <BasicButton
        isSubmitButton
        label={`close feedback form`}
        css={{
          border: `2px solid ${theme.mainColor}`,
          backgroundColor: theme.mainColor,
          height: "50px",
          width: "160px",
          borderRadius: "4px",
          marginTop: screenIs({
            default: "30px",
            tablet: "20px",
            mobile: "10px",
          }),
        }}
        onClick={() => {
          if (modalSecondAnimationState !== "closed") {
            closeModalSecond();
          } else {
            closeModal();
          }
        }}
        innerCss={{
          whiteSpace: "nowrap",
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
          fontWeight: "bold",
          color: "white",
        }}
      >
        Close
      </BasicButton>
    </div>
  );
};

export default FormSentMessage;
