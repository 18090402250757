/** @jsxImportSource @emotion/react */
import { jsx } from "@emotion/react";
import { useTheme } from "@emotion/react";
import React, { useState } from "react";

import { createStyleSheet } from "utils/styles";
import { addFlex, addPadding } from "utils";
import BasicButton from "components/BasicButton";
import BasicIcon from "components/BasicIcon";
import HyperLink from "components/HyperLink";
import { useSpring, animated } from "react-spring";
import AutoHeightAnimator from "components/AutoHeightAnimator";

type Props = {};

const LearnAboutVerifyingAccordion: React.FC<Props> = ({}) => {
  const [isOpen, setIsOpen] = useState(false);
  const [measuredContentHeight, setMeasuredContentHeight] = useState(0);
  const theme = useTheme();

  const motionProps = useSpring({
    opacity: isOpen ? 1 : 0,
    arrowRotation: isOpen ? 180 : 0,
  });

  const styles = createStyleSheet({
    container: {
      ...addFlex({ x: "left", y: "center", direction: "down" }),
      backgroundColor: "#F8F8F8",
      borderRadius: "8px",
    },
    headerButton: {
      width: "100%",
      ...addFlex({ x: "left", y: "center", direction: "right" }),
    },
    headerButtonInner: {
      width: "100%",
      ...addFlex({ x: "left", y: "center", direction: "right" }),
      justifyContent: "space-between",
      alignItems: "center",
      ...addPadding({ top: "10px", bottom: "12px", horizontal: "18px" }),
    },
    title: {
      fontFamily: theme.fonts.main,
      fontStyle: "normal",
      fontWeight: "bold",
      fontSize: "18px",
      lineHeight: "30px",
      color: "#2F2F2F",
    },
    headerArrow: {
      ...addFlex({ x: "center", y: "center" }),
      transformOrigin: "center center",
    },
    arrowIcon: {
      transform: "translateY(3px)",
    },

    content: {
      ...addFlex({ x: "left", y: "center", direction: "right" }),
      overflow: "hidden",
      ...addPadding({ horizontal: "18px" }),
    },
    description: {
      fontFamily: theme.fonts.main,
      fontStyle: "normal",
      fontWeight: "normal",
      fontSize: "18px",
      lineHeight: "30px",
      color: "#2F2F2F",
      overflow: "visible",
    },
    link: {
      fontFamily: theme.fonts.main,
      fontStyle: "normal",
      fontWeight: "normal",
      fontSize: "18px",
      lineHeight: "30px",
      color: "#238192",
    },
  });

  return (
    <div css={styles.container} aria-expanded={isOpen}>
      <BasicButton
        label={`${isOpen ? "close" : "show"} description`}
        onClick={() => setIsOpen(!isOpen)}
        css={styles.headerButton}
        innerCss={styles.headerButtonInner}
      >
        <div css={styles.title}>Unhappy with your suggestion? </div>
        <animated.div
          css={styles.headerArrow}
          style={{
            transform: motionProps.arrowRotation.to(
              (value) => `rotate(${value}deg)`
            ),
          }}
        >
          <BasicIcon
            name="Arrow"
            color="#2F2F2F"
            size={14}
            css={styles.arrowIcon}
          />
        </animated.div>
      </BasicButton>
      <div css={styles.content}>
        <AutoHeightAnimator initialHeight={0}>
          <animated.div
            css={styles.description}
            style={{
              height: isOpen ? undefined : 0,
              paddingBottom: isOpen ? "20px" : "0px",
              opacity: motionProps.opacity,
            }}
          >
            Don't worry - no suggestions are automatically applied to the data.
            If you have concerns about how your suggestions may be used, please
            email {` `}
            <HyperLink
              label={"send email"}
              mailTo={"info@arin.org"}
              css={styles.link}
              fontSize={"18px"}
            >
              info@arin.org
            </HyperLink>
          </animated.div>
        </AutoHeightAnimator>
      </div>
    </div>
  );
};

export default LearnAboutVerifyingAccordion;
