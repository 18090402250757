/** @jsxImportSource @emotion/react */
import { jsx } from "@emotion/react";
import React, { useRef, useEffect } from "react";

import BasicIcon from "components/BasicIcon";
import BasicButton from "components/BasicButton";
import { useNotificationsStore } from "stores";
import {
  NotificationType,
  NOTIFICATION_HEIGHT,
  NotificationItem,
} from "stores/notifications";
import { AllIconNames } from "icons";

import { useThrottledResizeObserver } from "hooks";
import { addFlex, addSize } from "utils";
import shallow from "zustand/shallow";

const colorsByType = {
  success: "#69A34E",
  error: "#DD1717",
  warning: "#E39326",
  info: "#228192",
};

const iconsByType: { [K in NotificationType]: AllIconNames } = {
  success: "CheckInCircleOutline",
  error: "CloseInCircleOutline",
  warning: "Warning",
  info: "Info",
};

type Props = {
  notificationItem: NotificationItem;
};

const Notification: React.FC<Props> = React.memo(({ notificationItem }) => {
  const [closeNotification, updateNotificationHeight] = useNotificationsStore(
    (state) => [state.closeNotification, state.updateNotificationHeight],
    shallow
  );
  const contentHolderRef = useRef<HTMLDivElement>(null);

  const { height } = useThrottledResizeObserver({
    ref: contentHolderRef,
    wait: 100,
  });

  useEffect(() => {
    if (height && contentHolderRef.current) {
      const measuredHeight =
        contentHolderRef.current.getBoundingClientRect().height;
      updateNotificationHeight(notificationItem.id, measuredHeight);
    }
  }, [height, notificationItem.id, updateNotificationHeight]);

  const itemColor = colorsByType[notificationItem.type];
  const itemIcon = iconsByType[notificationItem.type];
  return (
    <div
      ref={contentHolderRef}
      role="alert"
      css={{
        position: "relative",
        ...addSize({
          width: "100%",
          height: { min: `${NOTIFICATION_HEIGHT}px` },
        }),
        ...addFlex({ x: "left", y: "top", direction: "down" }),
        overflow: "hidden",
        padding: "15px",
        paddingRight: "18px",
        background: "white",
        borderRadius: "4px",
        boxShadow: "0px 6px 20px rgba(0, 0, 0, 0.25)",
        pointerEvents: "all",
      }}
      style={{ border: `2px solid ${itemColor}` }}
    >
      <div
        css={{
          label: "icon-and-title-holder",
          position: "relative",
          width: "100%",
          ...addFlex({ x: "left", y: "center" }),
        }}
      >
        <div
          css={{
            position: "relative",
            ...addSize({ size: "27px" }),
            ...addFlex({ x: "center", y: "center" }),
          }}
        >
          <BasicIcon name={itemIcon} color={itemColor} size={27} />
        </div>
        <div
          css={{
            marginLeft: "15px",
            color: itemColor,
            fontSize: "18px",
            lineHeight: "30px",
            fontWeight: "bold",
          }}
        >
          {notificationItem.title}
        </div>
      </div>
      <div
        css={{
          position: "relative",
          width: "100%",
          ...addFlex({ x: "left", y: "top", direction: "down" }),
          paddingLeft: "44px",
        }}
      >
        <div
          css={{
            fontSize: "14px",
            lineHeight: "20px",
          }}
        >
          {notificationItem.text}
        </div>
      </div>
      <BasicButton
        label={`dismiss ${notificationItem.type} message`}
        css={{
          position: "absolute",
          top: "0px",
          right: "0px",
          ...addSize({ size: "38px" }),
          pointerEvents: "all",
          borderRadius: "50px",
        }}
        innerCss={{
          ...addSize({ size: "100%" }),
          ...addFlex({ x: "center", y: "center" }),
          pointerEvents: "all",
        }}
        onClick={() => {
          closeNotification(notificationItem.id);
        }}
      >
        <BasicIcon name="Close" color="#949494" size={18} />
      </BasicButton>
    </div>
  );
});

export default Notification;
