import { create } from "zustand";
import { SearchResultResponse, GeoLocation, ProgramMeta } from "utils/types";
import getSearchResult from "services/api/search";
import keyBy from "lodash.keyby";
import { sortByDistance, sortByScore } from "utils/sorters";

type SearchResultMeta = { measuredHeight: number };
type SearchResultsMeta = { [facilityId: string]: SearchResultMeta };

type SearchResultsStoreState = {
  searchResultIds: string[];
  searchResults: { [facilityId: string]: SearchResultResponse };
  searchResultsMeta: SearchResultsMeta;
  searchTerm: string | undefined;
  isShowingLoadingOverlay: boolean;
  isLoadingResults: boolean;
  lastSearchFailed: boolean;
  selectedProgram: ProgramMeta | null;
};

type SearchResultsStore = SearchResultsStoreState & {
  setSearchResults: (searchResults: SearchResultResponse[]) => void;
  updateSearchResultMeta: (options: {
    resultId: string;
    newMeta: SearchResultMeta;
  }) => void;
  setSearchTerm: (searchTerm: string) => void;
  resetSearchResults: () => void;
  setIsLoadingResults: (newValue: boolean) => void;
  setLastSearchFailed: (newValue: boolean) => void;
  setSelectedProgram: (newValue: ProgramMeta | null) => void;
  setIsShowingLoadingOverlay: (newValue: boolean) => void;
  sortSearchResultsByDistance: (location: GeoLocation) => void;
  sortSearchResultsByScore: () => void;
  fetchSearchResults: (searchText: string) => void;
};

const useSearchResultsStore = create<SearchResultsStore>((set, get) => {
  let queuedSearchResultMeta = null as null | SearchResultsMeta;

  function batchUpdateSearchResultMetaAfterTimeout() {
    set((state) => ({
      searchResultsMeta: {
        ...state.searchResultsMeta,
        ...queuedSearchResultMeta,
      },
    }));
    queuedSearchResultMeta = null;
  }

  return {
    searchResultIds: [],
    searchResults: {},
    searchResultsMeta: {},
    searchTerm: undefined,
    isLoadingResults: false,
    lastSearchFailed: false,
    selectedProgram: null,
    isShowingLoadingOverlay: false,
    setSearchResults: (searchResults) =>
      set((state) => {
        const editedResultsMeta: typeof state.searchResultsMeta = {
          ...state.searchResultsMeta,
        };

        searchResults.forEach((loopedResult) => {
          editedResultsMeta[loopedResult.facility.id] = {
            measuredHeight:
              editedResultsMeta?.[loopedResult.facility.id]?.measuredHeight ??
              0,
          };
        });

        return {
          searchResults: keyBy(searchResults, (result) => result.facility.id),
          searchResultIds: searchResults.map((result) => result.facility.id),
          searchResultsMeta: editedResultsMeta,
        };
      }),
    updateSearchResultMeta: ({ resultId, newMeta }) => {
      if (queuedSearchResultMeta === null) {
        queuedSearchResultMeta = {};
        setTimeout(batchUpdateSearchResultMetaAfterTimeout, 250);
      }
      queuedSearchResultMeta[resultId] = newMeta;
    },
    setSearchTerm: (searchTerm) => set(() => ({ searchTerm })),
    setLastSearchFailed: (newValue) => {
      set((state) => ({
        ...state,
        lastSearchFailed: newValue,
      }));
    },
    setSelectedProgram: (newValue) => {
      set((state) => ({
        ...state,
        selectedProgram: newValue,
      }));
    },
    resetSearchResults: () =>
      set({
        searchTerm: undefined,
        searchResults: {},
        searchResultIds: [],
        searchResultsMeta: {},
      }),
    setIsLoadingResults: (newValue) => {
      set((state) => ({
        ...state,
        isLoadingResults: newValue,
      }));
    },
    setIsShowingLoadingOverlay: (newValue) => {
      set((state) => ({
        ...state,
        isShowingLoadingOverlay: newValue,
      }));
    },
    sortSearchResultsByDistance: (location: GeoLocation) =>
      get().setSearchResults(sortByDistance(get().searchResults, location)),

    sortSearchResultsByScore: () =>
      get().setSearchResults(sortByScore(get().searchResults)),

    fetchSearchResults: async (searchText) => {
      if (searchText) {
        if (get().isLoadingResults) return;
        get().setLastSearchFailed(false);
        get().setIsLoadingResults(true);
        get().setIsShowingLoadingOverlay(true);
        get().setSearchTerm(searchText);

        try {
          const searchResponse = await getSearchResult(searchText);

          get().setSearchResults(
            searchResponse.data.facilities.results.sort(
              (a, b) => b.score - a.score
            )
          );
          const hasResults = searchResponse?.data?.facilities?.results.length;
          if (!hasResults) {
            window.setTimeout(() => {
              get().setIsShowingLoadingOverlay(false);
            }, 750);
          }
          get().setSelectedProgram(
            searchResponse?.data?.facilities?.programMeta
          );
          get().setIsLoadingResults(false);
        } catch (error) {
          console.error(error);
          get().setLastSearchFailed(true);
          get().setIsLoadingResults(false);
        }
      }
    },
  };
});

export { useSearchResultsStore };
