/** @jsxImportSource @emotion/react */
import { jsx } from "@emotion/react";
import React, { useState, Fragment, useRef, useEffect } from "react";

import { useScreenSizeStore } from "stores";
import RadioInputOption from "../RadioInputOption";
import Paragraph from "components/Paragraph";

type Props = {
  inputId: string;
  options: { value: string; label: string }[];
  onChange: (newValue: string) => void;
  value: string;
  onFocus: () => void;
  onBlur: () => void;
};

const PlainRadioInput: React.FC<Props> = ({
  inputId,
  options,
  onChange,
  value,
  onFocus,
  onBlur,
}) => {
  const { isTablet } = useScreenSizeStore();
  const [isFocused, setIsFocused] = useState(false);
  const { current: localContext } = useRef({
    setFocusTimeout: null as null | number,
    prevIsFocused: false,
  });
  useEffect(() => {
    // (prevents running on first render)
    if (localContext.prevIsFocused !== isFocused) {
      if (isFocused) {
        onFocus();
      } else {
        onBlur();
      }
    }
    localContext.prevIsFocused = isFocused;
  }, [isFocused, localContext.prevIsFocused, onBlur, onFocus]);

  return (
    <div css={{ display: "flex", flexDirection: "row" }}>
      {options.map((loopedOption, index) => {
        return (
          <Fragment key={loopedOption.value}>
            <label
              onClick={() => {
                onChange(loopedOption.value);
              }}
              onFocus={() => {
                if (localContext.setFocusTimeout) {
                  clearTimeout(localContext.setFocusTimeout);
                }

                if (!isFocused) {
                  setIsFocused(true);
                }
              }}
              onBlur={() => {
                localContext.setFocusTimeout = window.setTimeout(() => {
                  setIsFocused(false);
                  localContext.setFocusTimeout = null;
                }, 150);
              }}
              css={{
                display: "flex",
                background: "#fff",
                borderRadius: "8px",
                padding: "10px",
                margin: isTablet ? "5px" : "5px 0",
                width: "100%",
                cursor: "pointer",
              }}
            >
              <RadioInputOption
                name={inputId}
                id={loopedOption.value}
                activeOption={value}
                value={loopedOption.label}
                css={{ marginRight: "10px" }}
                size="small"
              />
              <span css={{ lineHeight: 1.4 }}>
                <Paragraph type="small">{loopedOption.label}</Paragraph>
              </span>
            </label>
          </Fragment>
        );
      })}
    </div>
  );
};

export default PlainRadioInput;
