/** @jsxImportSource @emotion/react */
import { jsx } from "@emotion/react";
import React from "react";
import { addMargin, addSize } from "utils";

type Props = {};

const ColumnDivider: React.FC<Props> = () => {
  return (
    <div
      css={{
        ...addMargin({
          horizontal: "25px",
        }),
        ...addSize({
          width: { default: "1px", min: "1px" },
        }),
        flexShrink: 0,
        flexBasis: 1,
        position: "relative",
        background: "#D5D5D5",
      }}
    ></div>
  );
};

export default ColumnDivider;
