import { create } from "zustand";
import {
  CapabilitySuggestionFields,
  getCapabilitySuggestions,
} from "services/getCapabilitySuggestions";
import {
  CapabilitySuggestionStore,
  SuggestionTypes,
  FacilitySuggestionsConsideredHistoryItem,
} from "./types";

export const INITIAL_STATE = {
  numberOfSuggestions: 0,
  suggestionsConsidered: {
    images: false,
    title: false,
    description: false,
  },
  suggestionsConfirmed: {
    images: false,
    title: false,
    description: false,
  },
  suggestions: {
    images: undefined,
    description: undefined,
    title: undefined,
  },
  hasConsideredSuggestions: false,
  facilitySuggestionsConsideredHistory:
    [] as FacilitySuggestionsConsideredHistoryItem[],
};

const useCapabilitySuggestionStore = create<CapabilitySuggestionStore>(
  (set, get) => ({
    suggestions: INITIAL_STATE.suggestions,
    numberOfSuggestions: INITIAL_STATE.numberOfSuggestions,
    suggestionsConsidered: INITIAL_STATE.suggestionsConsidered,
    suggestionsConfirmed: INITIAL_STATE.suggestionsConfirmed,
    hasConsideredSuggestions: INITIAL_STATE.hasConsideredSuggestions,
    facilitySuggestionsConsideredHistory:
      INITIAL_STATE.facilitySuggestionsConsideredHistory,
    setCapabilitySuggestion: (suggestions: CapabilitySuggestionFields) =>
      set({ suggestions }),

    setNumberOfSuggestions: (numberOfSuggestions: number) =>
      set({ numberOfSuggestions }),

    setSuggestionsConsidered: (
      suggestion: SuggestionTypes,
      capabilityId: string | undefined
    ) => {
      set({
        hasConsideredSuggestions: true,
        suggestionsConsidered: {
          ...get().suggestionsConsidered,
          ...{ [suggestion]: true },
        },
      });
      if (capabilityId) {
        const suggestions = get().facilitySuggestionsConsideredHistory;

        const facilitySuggested = suggestions.find(
          (currentSuggestion) => currentSuggestion.capabilityId === capabilityId
        );

        if (!facilitySuggested) {
          suggestions.push({
            suggestions: {
              ...INITIAL_STATE.suggestionsConsidered,
              ...{ [suggestion]: true },
            },
            capabilityId: capabilityId,
          });
        }

        set({
          facilitySuggestionsConsideredHistory: suggestions.map(
            (currentSuggestion) => {
              if (currentSuggestion.capabilityId === capabilityId) {
                currentSuggestion.suggestions[suggestion] = true;
              }
              return currentSuggestion;
            }
          ),
        });
      }
    },
    setSuggestionsConfirmed: (suggestion: SuggestionTypes) => {
      const suggestionsConfirmed = {
        ...get().suggestionsConfirmed,
        ...{ [suggestion]: true },
      };
      set({
        suggestionsConfirmed,
      });
    },
    getSuggestionsConfirmed: () => get().suggestionsConfirmed,
    numberOfSuggestionsCurrentPage: () => {
      return (
        Object.values(get().suggestionsConsidered).filter(
          (suggestion) => suggestion
        ).length + 1
      );
    },
    getNextAction: () => {
      const availableActions = Object.entries(get().suggestionsConsidered)
        .map(([suggestion, hasBeenConsidered]) => {
          return hasBeenConsidered === false &&
            get().suggestions[suggestion as SuggestionTypes]
            ? suggestion
            : false;
        })
        .filter(Boolean) as Array<SuggestionTypes>;
      return !!availableActions.length ? availableActions[0] : null;
    },
    resetCapabilitySuggestion: () => {
      const {
        numberOfSuggestions,
        suggestionsConsidered,
        suggestionsConfirmed,
        suggestions,
        hasConsideredSuggestions,
      } = INITIAL_STATE;
      set({
        suggestions,
        numberOfSuggestions,
        suggestionsConsidered,
        suggestionsConfirmed,
        hasConsideredSuggestions,
      });
    },
    getCapabilitySuggestion: () => get().suggestions,
    initialiseCapabilitySuggestion: (
      suggestions,
      { title, images, description }
    ) => {
      const capabilitySuggestion = getCapabilitySuggestions(suggestions, {
        title,
        images,
        description,
      });
      get().setNumberOfSuggestions(
        Object.values(capabilitySuggestion).filter((suggestion) => !!suggestion)
          .length
      );
      get().setCapabilitySuggestion(capabilitySuggestion);
    },
  })
);

export { useCapabilitySuggestionStore };
