import { Configuration } from "../";
import tenantConfig from "tenants";

const configuration: Configuration = {
  api: {
    uri:
      process.env.REACT_APP_API_URL ||
      "https://mlz2mtt2trakbjf2czmp4vihiq.appsync-api.ap-southeast-2.amazonaws.com/graphql",
    apiKey: process.env.REACT_APP_API_KEY || "da2-xcu5vq52nbecnb5kya3o67xxwu",
    tenantId: tenantConfig.tenantId,
  },
  fireBaseConfig: {
    apiKey: "AIzaSyBCJpDnT0xbpvhQxjQeh3CZ48Xmt0z56C8",
    authDomain: "arin-portal-development.firebaseapp.com",
    projectId: "arin-portal-development",
    appId: "1:1046258555640:web:ea0bd0775a505fc0d5790d",
    measurementId: "G-0RFGFMMB73",
  },
  shouldCollectAnalyticsData: false,
  reCaptchaKey: "6LfKluIUAAAAAFJEzqPawgX71jCIrSgzGw5J-p_X",
} as const;

export default configuration;
