import { useNotificationsStore } from "stores";

function showGeolocationFailedNotification(): void {
  useNotificationsStore.getState().addNotification({
    type: "warning",
    title: "Failed to get location",
    text: "Something went while trying access you location",
  });
}

export default function getGeoLocation(): Promise<GeolocationPosition | null> {
  if (!navigator.geolocation) {
    showGeolocationFailedNotification();
    return new Promise((resolve) => {
      resolve(null);
    });
  } else {
    return new Promise((resolve, reject) => {
      navigator.geolocation.getCurrentPosition(
        (position) => {
          resolve(position);
        },
        () => {
          showGeolocationFailedNotification();
          resolve(null);
        }
      );
    });
  }
}
