/** @jsxImportSource @emotion/react */
import { jsx } from "@emotion/react";
import { useTheme } from "@emotion/react";
import React, { useRef } from "react";

import BasicIcon from "components/BasicIcon";
import { addFlex, addSize } from "utils";
import { useScreenSizeStore } from "stores";

const CHECK_BOX_SIZE = 20;
const TRANSITION_DURATION = ".2s";

type Props = {
  label: string | React.ReactNode;
  id: string;
  name: string;
  value: boolean;
  onChange: (newValue: boolean) => void;
  disabled?: boolean;
  className?: string;
  whiteSpace?: "nowrap" | "normal";
  tickBoxAlignment?: "top" | "center";
};

const CheckBox: React.FC<Props> = ({
  label,
  name,
  id,
  value = false,
  disabled,
  onChange,
  className,
  whiteSpace = "nowrap",
  tickBoxAlignment = "center",
}) => {
  const { screenIs } = useScreenSizeStore();
  const theme = useTheme();
  const checkInputRef = useRef<HTMLInputElement>(null);

  const isChecked = !!value;
  return (
    <div
      className={className}
      css={{
        position: "relative",
        ...addFlex({ x: "left", y: tickBoxAlignment }),
      }}
    >
      <div
        css={{
          position: "relative",
          border: `2px solid ${
            disabled ? "#d5d5d5" : theme.inputCheckboxColor
          }`,
          borderRadius: "2px",
          marginRight: "10px",
          marginTop: tickBoxAlignment === "top" ? "2px" : 0,
          ...addSize({
            size: {
              default: `${CHECK_BOX_SIZE}px`,
              min: `${CHECK_BOX_SIZE}px`,
            },
          }),
        }}
      >
        <input
          ref={checkInputRef}
          css={{
            ...addFlex({ x: "center", y: "center" }),
            appearance: "none",
            ...addSize({ size: { default: "100%", min: "100%" } }),
            outline: 0,
            ":checked": {
              background: `${disabled ? "#d5d5d5" : theme.inputCheckboxColor}`,
            },

            ":focus": {
              background: `${isChecked ? "#a8d9e2" : "#e3f7fb"}`,
            },
            transition: `background ${TRANSITION_DURATION}`,
            cursor: disabled ? "not-allowed" : "pointer",
            borderRadius: "0",
          }}
          type="checkbox"
          id={id}
          data-automation={id}
          name={name}
          checked={isChecked}
          disabled={disabled}
          onChange={() => {
            onChange(!isChecked);
          }}
          onMouseUp={() => {
            window.setTimeout(() => {
              if (checkInputRef.current) {
                checkInputRef.current.blur();
              }
            }, 0);
          }}
        />
        <div
          css={{
            ...addSize({ size: { default: "100%", min: "100%" } }),
            ...addFlex({ x: "center", y: "center" }),
            position: "absolute",
            top: "0",
            left: "0",
            opacity: isChecked ? 1 : 0,
            pointerEvents: "none",
            transition: `opacity ${TRANSITION_DURATION}`,
          }}
        >
          <BasicIcon name={`CheckThick`} color={"#fff"} size={16} height={12} />
        </div>
      </div>
      <label
        css={{
          verticalAlign: "text-top",
          color: disabled ? "#d5d5d5" : "#2F2F2F",
          fontWeight: "bold",
          whiteSpace: whiteSpace,
          fontSize: screenIs({
            default: "20px",
            tablet: "18px",
            mobile: "12px",
          }),
          cursor: disabled ? "not-allowed" : "pointer",
        }}
        htmlFor={id}
        onMouseUp={() => {
          window.setTimeout(() => {
            if (checkInputRef.current) {
              checkInputRef.current.blur();
            }
          }, 0);
        }}
      >
        {label}
      </label>
    </div>
  );
};

export default CheckBox;
