import { create } from "zustand";
import { produce } from "immer";
import {
  FormInputState,
  MakeInputsOptions,
  FormInputsStates,
  ValidatorType,
  FormStoreActions,
  FormStoreState,
} from "./types";
import { defaultInitialInputItemState } from "./consts";
import validatorFunctions from "./validatorTypes";
import inputTypes from "./inputTypes";

export default function makeFormStore<
  T_MakeInputsOptions extends MakeInputsOptions
>(makeInputsOptions: T_MakeInputsOptions) {
  type DefinedInputId = keyof T_MakeInputsOptions;

  type FormStore = FormStoreState & FormStoreActions<DefinedInputId>;

  const initialInputsState: FormInputsStates = {};

  const allInputIds = Object.keys(makeInputsOptions);

  allInputIds.forEach((loopedInputId) => {
    const {
      defaultInitialValue: initialValue,
      inputType,
      validatorTypes,
      validatorsOptions,
    } = makeInputsOptions[loopedInputId];

    initialInputsState[loopedInputId] = {
      ...defaultInitialInputItemState,
      inputType,
      initialValue: initialValue || inputTypes[inputType].blankValue,
      validatorTypes: validatorTypes || [],
      validatorsOptions: validatorsOptions || {},
      inputId: loopedInputId,
      value: initialValue,
    } as FormInputState;
  });

  return create<FormStore>((set, get) => ({
    //
    // state                           ▼
    //
    inputsStates: initialInputsState,
    // input ids
    allInputIds,
    focusedInputId: "",
    invalidInputIds: [],
    // times
    timeFocused: 0,
    timeUnfocused: 0,
    timeOpened: 0,
    timeUpdated: 0,
    // interpreted
    isEdited: false,
    isValid: false,
    isFocused: false,
    hasBeenUnfocused: false,
    //
    //  actions                      ▼
    //
    // helpers
    _setImmutable: (editDraftStateFunction) =>
      // produce() can also return a function that accepts state
      // set(produce(editDraftStateFunction)),
      // but using long way to be clear
      set((baseState) => {
        const nextState = produce(baseState, (draftState) =>
          editDraftStateFunction(draftState)
        );
        return nextState;
      }),
    _checkIfFormIsEdited: ({
      inputId: latestEditedInputId,
      newInputIsEdited,
    }) => {
      const { allInputIds, inputsStates } = get();
      /*
      check if any inputs are edited, then the form is edited
      */
      if (newInputIsEdited) return true;

      for (const loopedInputId of allInputIds) {
        const loopedInput = inputsStates[loopedInputId];
        if (loopedInputId !== latestEditedInputId && loopedInput.isEdited) {
          return true;
        }
      }
      return false;
    },
    _checkIfFormIsValid: ({
      inputId: latestChangedInputId,
      newInputIsValid,
    }) => {
      const { allInputIds, inputsStates } = get();
      /*
      check if any inputs are edited, then the form is edited
      */

      if (!newInputIsValid) return false;

      for (const loopedInputId of allInputIds) {
        const loopedInput = inputsStates[loopedInputId];
        if (loopedInputId !== latestChangedInputId && !loopedInput.isValid) {
          return false;
        }
      }

      return true;
    },
    _validateInputValue: ({ inputId, newValue }) => {
      const baseState = get();
      const inputState = baseState.inputsStates[inputId];
      const { validatorTypes, validatorsOptions } =
        baseState.inputsStates[inputId];

      const errorTypes: ValidatorType[] = [];
      const errorTexts: string[] = [];

      validatorTypes.forEach((validatorType) => {
        const validatorMessage = validatorFunctions[validatorType]({
          value: newValue,
          validatorOptions: validatorsOptions[validatorType],
          formState: baseState,
          inputState,
        });
        if (validatorMessage) {
          errorTypes.push(validatorType);
          errorTexts.push(validatorMessage);
        }
      });

      return { errorTypes, errorTexts };
    },
    // updating state
    updateInputValue: ({ inputId, newValue }) => {
      const baseState = get();
      const { _setImmutable, _checkIfFormIsEdited, _checkIfFormIsValid } =
        baseState;
      const inputState = baseState.inputsStates[inputId];
      const { initialValue, isEdited, isValid } = inputState;

      const { errorTexts, errorTypes } = get()._validateInputValue({
        inputId,
        newValue,
      });

      _setImmutable((draftState) => {
        const currentTime = Date.now();
        const newInputIsEdited = newValue !== initialValue;
        const newInputIsValid = errorTypes.length === 0;
        const isEditedDidChange = isEdited !== newInputIsEdited;
        const isValidDidChange = isValid !== newInputIsValid;
        const draftInputState = draftState.inputsStates[inputId];
        draftInputState.value = newValue;
        draftInputState.timeUpdated = currentTime;
        draftInputState.isEdited = newInputIsEdited;
        draftInputState.validationErrorTypes = errorTypes;
        draftInputState.validationErrorTexts = errorTexts;
        draftInputState.isValid = newInputIsValid;
        draftState.timeUpdated = currentTime;
        if (isEditedDidChange) {
          draftState.isEdited = _checkIfFormIsEdited({
            inputId,
            newInputIsEdited,
          });
        }
        if (isValidDidChange) {
          draftState.isValid = _checkIfFormIsValid({
            inputId,
            newInputIsValid,
          });
        }
      });
    },
    toggleFocus: ({ inputId, isFocused: newIsFocused }) => {
      const baseState = get();
      const { _setImmutable, inputsStates } = baseState;
      const baseInputState = inputsStates[inputId];
      _setImmutable((draftState) => {
        const draftInputState = draftState.inputsStates[inputId];
        const currentTime = Date.now();

        draftInputState.isFocused = newIsFocused;

        if (newIsFocused) {
          draftInputState.timeFocused = currentTime;
          draftState.focusedInputId = inputId;
        } else {
          //unfocused
          if (!baseInputState.hasBeenUnfocused) {
            // check for errors on first unfocus
            const { errorTexts, errorTypes } = get()._validateInputValue({
              inputId,
              newValue: baseInputState.value,
            });
            draftInputState.validationErrorTypes = errorTypes;
            draftInputState.validationErrorTexts = errorTexts;
          }
          draftInputState.timeUnfocused = currentTime;
          draftInputState.hasBeenUnfocused = true;
        }
      });

      // if this input is still set as the focused input,
      //  for the form 300ms after unfocusing,
      // then set the focused input for the form to none
      // NOTE adding a clearTimeout could prevent (potential)
      //  issues with swapping focus back and fourth quickly
      if (!newIsFocused) {
        window.setTimeout(() => {
          if (get().focusedInputId === inputId) {
            _setImmutable((draftState) => {
              draftState.focusedInputId = "";
              draftState.isFocused = false;
              draftState.hasBeenUnfocused = true;
            });
          }
        }, 300);
      }
    },
    refreshForm: ({ initialValuesByInputId }) => {
      const inputIdsWithNewInitialValues = Object.keys(initialValuesByInputId);

      const baseState = get();
      const { _setImmutable, allInputIds } = baseState;

      _setImmutable((draftState) => {
        const currentTime = Date.now();
        let newFormIsValid = true;

        allInputIds.forEach((loopedInputId) => {
          const draftInputState = draftState.inputsStates[loopedInputId];
          const { blankValue } = inputTypes[draftInputState.inputType];
          const newInitialValue = initialValuesByInputId[loopedInputId];
          const { defaultInitialValue } = makeInputsOptions[loopedInputId];

          const newValue = newInitialValue || defaultInitialValue || blankValue;

          const { errorTexts, errorTypes } = get()._validateInputValue({
            inputId: loopedInputId,
            newValue,
          });
          const newInputIsValid = errorTypes.length === 0;
          if (!newInputIsValid) {
            newFormIsValid = false;
          }

          draftInputState.initialValue = newValue;
          draftInputState.value = newValue;
          draftInputState.validationErrorTexts = errorTexts;
          draftInputState.validationErrorTypes = errorTypes;
          draftInputState.timeOpened = currentTime;
          draftInputState.timeUpdated = 0;
          draftInputState.timeFocused = 0;
          draftInputState.timeUnfocused = 0;
          draftInputState.isFocused = false;
          draftInputState.hasBeenUnfocused = false;
          draftInputState.isEdited = false;
          draftInputState.isValid = newInputIsValid;
        });

        // ids
        draftState.focusedInputId = "";
        draftState.invalidInputIds = [];
        // times
        draftState.timeOpened = currentTime;
        draftState.timeUpdated = 0;
        draftState.timeFocused = 0;
        draftState.timeUnfocused = 0;
        // bools
        draftState.isEdited = false;
        draftState.isFocused = false;
        draftState.hasBeenUnfocused = false;
        draftState.isValid = newFormIsValid;
      });
    },
  }));
}
