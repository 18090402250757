/** @jsxImportSource @emotion/react */
import { jsx } from "@emotion/react";
import React from "react";

import { Contact } from "utils/types";
import { addMargin, addPadding, addSize } from "utils";
import Paragraph from "components/Paragraph";
import HyperLink from "components/HyperLink";
import { useScreenSizeStore } from "stores";
import ContactCardRow from "./ContactCardRow";
import { userOrganisationStore } from "stores/userOrganisationType";

type Props = {
  contact: Contact;
  isOnFirstColumn: boolean;
  shouldFormatPhoneNumbers: boolean;
};

function makeEmailTextSecret(theEmail: string) {
  const [beforeAtSymbol, afterAtSymbol] = theEmail.split("@");
  return [...[...beforeAtSymbol].map(() => "*"), "@", afterAtSymbol].join("");
}

function makePhoneTextSecret(phoneNumber: string) {
  if (phoneNumber === null) return "";
  const firstSevenDigits = phoneNumber.substring(0, 7);
  const otherDigits = phoneNumber.substring(7, phoneNumber.length);
  return [...[...firstSevenDigits].map(() => "*"), otherDigits].join("");
}

// This temporary until we cleaned it on import
function tacticalPhoneNumberFormatting(
  phoneNumber: string,
  shouldFormatPhoneNumbers: boolean
): string {
  if (shouldFormatPhoneNumbers === false) return phoneNumber;

  const phoneNumbers = phoneNumber
    .split(" ")
    .map((potentialValue) => potentialValue.replace(/\D/g, ""))
    .filter(Boolean);

  return phoneNumbers[0];
}
const ContactCard: React.FC<Props> = React.memo(
  ({ contact, shouldFormatPhoneNumbers, isOnFirstColumn = true }) => {
    const { screenIs } = useScreenSizeStore();

    const [setShowContactInfo, showContactInfo] = userOrganisationStore(
      (state) => [state.setShowContactInfo, state.showContactInfo]
    );

    const formattedPhoneNumber = tacticalPhoneNumberFormatting(
      contact.phone,
      shouldFormatPhoneNumbers
    );
    return (
      <div
        css={{
          label: "contact-card",
          borderBottom: "1px solid #D5D5D5",
          ...addMargin({
            top: screenIs({ default: "28px", tablet: "20px", mobile: "15px" }),
            left: !isOnFirstColumn ? "34px" : "0",
          }),
          ...addPadding({
            bottom: screenIs({
              default: "28px",
              tablet: "20px",
              mobile: "15px",
            }),
          }),
          ...addSize({
            width: screenIs({ default: "100%", tablet: "calc(50% - 34px)" }),
          }),
        }}
      >
        <Paragraph
          bold
          css={{
            marginBottom: "7px",
          }}
        >{`${contact.firstName} ${contact.lastName || ""}`}</Paragraph>
        {contact.role && (
          <ContactCardRow
            icon="Portfolio"
            showContacts={setShowContactInfo}
            contactId={contact.id}
          >
            {contact.role}
          </ContactCardRow>
        )}
        <ContactCardRow
          icon="Mail"
          contactId={contact.id}
          isHidden={!showContactInfo}
          showContacts={setShowContactInfo}
        >
          {showContactInfo ? (
            <HyperLink label={"send email"} mailTo={contact.email}>
              {contact.email}
            </HyperLink>
          ) : (
            makeEmailTextSecret(contact.email)
          )}
        </ContactCardRow>
        {formattedPhoneNumber ? (
          <ContactCardRow
            icon="Phone"
            contactId={contact.id}
            isHidden={!showContactInfo}
            showContacts={setShowContactInfo}
          >
            {showContactInfo ? (
              <HyperLink
                label={"make phone call"}
                phoneTo={formattedPhoneNumber}
              >
                {formattedPhoneNumber}
              </HyperLink>
            ) : (
              makePhoneTextSecret(formattedPhoneNumber)
            )}
          </ContactCardRow>
        ) : (
          <div css={{ height: "32px" }}> </div>
        )}
        {!!contact.location && (
          <ContactCardRow
            showContacts={setShowContactInfo}
            icon="LocationPin"
            contactId={contact.id}
          >
            {contact.location}
          </ContactCardRow>
        )}
      </div>
    );
  }
);

export default ContactCard;
