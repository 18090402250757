import makeFormStore from "stores/utils/storeMakers/makeFormStore";

const useSubmitInquiryForm = makeFormStore({
  firstName: {
    inputType: "text",
    validatorTypes: ["required"],
  },
  lastName: {
    inputType: "text",
    validatorTypes: ["required"],
  },
  userType: {
    inputType: "radio",
    validatorTypes: ["required"],
    defaultInitialValue: "commercial",
  },
  organisation: {
    inputType: "text",
    validatorTypes: ["required"],
  },
  email: {
    inputType: "text",
    validatorTypes: ["required"],
  },
  phone: {
    inputType: "text",
    validatorTypes: ["required"],
  },
  comment: {
    inputType: "textArea",
    validatorTypes: ["required"],
  },
});

export { useSubmitInquiryForm };
