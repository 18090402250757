/** @jsxImportSource @emotion/react */
import { jsx } from "@emotion/react";
import { useTheme } from "@emotion/react";
import React, { useState, useEffect, useCallback, useMemo } from "react";
import { useArrowKeyChoosableList } from "hooks";
import Paragraph from "components/Paragraph";
import BasicIcon from "components/BasicIcon";
import BasicButton from "components/BasicButton";
import LoadingSpinner from "components/LoadingSpinner";
import { addFlex } from "utils";
import { useLocationStore } from "stores";
import { SortSearchResultsType } from "screens/SearchContent";
import autoLocality from "services/api/autoLocality";
import { AutoLocality } from "utils/types";

type Props = {
  setSortBy: (location: SortSearchResultsType) => void;
  sortBy: SortSearchResultsType;
  isLoadingLocation: boolean;
};

const ProvideLocation: React.FC<Props> = ({
  setSortBy,
  isLoadingLocation,
  sortBy,
}) => {
  const theme = useTheme();
  const [addressSearchTerm, setAddressSearchTerm] = useState<
    string | undefined
  >(undefined);
  const [isPostCodeFinderOpen, setPostCodeFinderOpen] =
    useState<boolean>(false);

  const { setLocation, getUserGeoLocation, geoLocation, resetGeoLocation } =
    useLocationStore();
  const [autoCompletedLocalities, setAutoCompletedLocalities] = useState<
    AutoLocality[] | undefined
  >(undefined);

  useEffect(() => {
    if (sortBy === "Relevance") {
      setPostCodeFinderOpen(false);
    }
  }, [sortBy]);

  useEffect(() => {
    async function fetchAutoLocality() {
      if (addressSearchTerm && addressSearchTerm?.length >= 3) {
        const autoCompletedResponses = await autoLocality(addressSearchTerm);
        const formattedAutoCompletedResponses = autoCompletedResponses
          .sort((a, b) => b.score - a.score)
          .slice(0, 5);
        setAutoCompletedLocalities(formattedAutoCompletedResponses);
      }
    }
    fetchAutoLocality();
  }, [addressSearchTerm]);

  useEffect(() => {
    if (addressSearchTerm?.length === 0) {
      setAutoCompletedLocalities(undefined);
    }
  }, [addressSearchTerm]);

  const [activeAutoCompletedLocalities] = useArrowKeyChoosableList({
    items: autoCompletedLocalities
      ? autoCompletedLocalities.map(({ name }) => name)
      : [],
    isActive: true,
  });

  const handleUserKeyPress = useCallback(
    (event: KeyboardEvent) => {
      if (event.keyCode !== 13 || !autoCompletedLocalities) return;

      const matchedLocality = autoCompletedLocalities.find(
        (autoCompletedLocality) =>
          autoCompletedLocality.name === activeAutoCompletedLocalities
      );

      if (matchedLocality) {
        const { lat, long } = matchedLocality.coordinates;

        setAddressSearchTerm(matchedLocality.name);
        setLocation({ latitude: lat, longitude: long });
        setSortBy(`Postcode`);
        setAutoCompletedLocalities([]);
      }
    },
    [
      activeAutoCompletedLocalities,
      autoCompletedLocalities,
      setLocation,
      setSortBy,
    ]
  );

  useEffect(() => {
    window.addEventListener("keyup", handleUserKeyPress);

    return () => {
      window.removeEventListener("keyup", handleUserKeyPress);
    };
  });

  const saveButtonDisabled = useMemo(() => geoLocation === null, [geoLocation]);

  return (
    <div
      css={{
        position: "relative",
        background: sortBy === "Postcode" ? "#EEF4F6" : "#fff",
        padding: "12px 20px",
      }}
    >
      {isLoadingLocation && (
        <div
          css={{
            position: "absolute",
            top: 0,
            left: 0,
            width: "100%",
            height: "100%",
            background: "rgba(255,255,255,0.7)",
            zIndex: 999,
          }}
        >
          <LoadingSpinner height="auto" verticalPadding={`44px`} size={50} />
        </div>
      )}

      <BasicButton
        label={`open find postcode location`}
        onClick={() => setPostCodeFinderOpen(!isPostCodeFinderOpen)}
        innerCss={{
          width: "100%",
        }}
      >
        <div
          css={{
            display: "flex",
            justifyContent: "space-between",
            width: "100%",
          }}
        >
          <Paragraph
            bold
            css={{
              color: theme.mainColor,
            }}
          >
            Location
          </Paragraph>
          {sortBy === "Postcode" && (
            <BasicIcon name={`Check`} color={theme.mainColor} size={24} />
          )}
        </div>
      </BasicButton>
      <div
        css={{
          position: "relative",
          display: isPostCodeFinderOpen ? "flex" : "none",
        }}
      >
        <input
          value={addressSearchTerm ?? ""}
          onChange={(e) => {
            setAddressSearchTerm(e.target.value);
            if (!!e.target.value.length) {
              resetGeoLocation();
            }
          }}
          css={{
            padding: !autoCompletedLocalities ? "20px 15px 10px 15px" : "15px",
            border: "1px solid #D5D5D5",
            width: "100%",
            boxSizing: "border-box",
            borderRadius: "4px",
            fontSize: "14px",
          }}
          aria-label={`Search for a location`}
          aria-autocomplete="both"
          aria-controls="autoCompleteLocalities"
          type="search"
          aria-activedescendant={`autoCompleteLocalities-item`}
        />
        <BasicButton
          label="save location"
          onClick={async () => {
            setSortBy(`Postcode`);
            setPostCodeFinderOpen(false);
            setAutoCompletedLocalities([]);
          }}
          css={{
            border: `1px solid ${theme.mainColor}`,
            backgroundColor: theme.mainColor,
            position: "absolute",
            right: 0,
            top: 0,
            borderRadius: "4px",
            fontSize: "14px",
            padding: "16px",
            opacity: saveButtonDisabled ? 0.6 : 1,
          }}
          innerCss={{
            fontWeight: "bold",
            color: "white",
          }}
          disabled={saveButtonDisabled}
        >
          Save
        </BasicButton>
        {!autoCompletedLocalities && (
          <label
            css={{
              position: "absolute",
              fontSize: "10px",
              lineHeight: "18px",
              color: "#2F2F2F",
              top: "5px",
              left: "15px",
            }}
            htmlFor={`autoCompleteLocalities`}
          >
            Enter postcode or suburb
          </label>
        )}
        {autoCompletedLocalities && autoCompletedLocalities.length && (
          <ul
            css={{
              position: "absolute",
              listStyle: "none",
              background: "#fff",
              top: "50px",
              height: "200px",
              width: "100%",
              overflowY: "auto",
              margin: 0,
              padding: 0,
              zIndex: 2,
              boxShadow: "0px 8px 24px rgba(0, 0, 0, 0.2)",
              borderRadius: "4px",
            }}
            role={`status`}
            id={`autoCompleteLocalities`}
          >
            {autoCompletedLocalities?.map((autoCompletedLocality) => (
              <li
                role="option"
                aria-selected={
                  autoCompletedLocality.name === activeAutoCompletedLocalities
                    ? "true"
                    : "false"
                }
                id={`autoCompleteLocalities-item`}
                css={{
                  background:
                    autoCompletedLocality.name === activeAutoCompletedLocalities
                      ? "rgba(235, 255, 251, 1)"
                      : "rgba(255, 255, 255, 0)",
                }}
              >
                <BasicButton
                  label={`Sort by search results closest to ${autoCompletedLocality.name}`}
                  onClick={() => {
                    const { lat, long } = autoCompletedLocality.coordinates;
                    setLocation({ latitude: lat, longitude: long });
                    setAddressSearchTerm(autoCompletedLocality.name);
                    setAutoCompletedLocalities([]);
                  }}
                >
                  <Paragraph
                    type={`small`}
                    css={{
                      margin: "10px 0",
                      padding: "0 15px",
                    }}
                  >
                    {autoCompletedLocality.name}
                  </Paragraph>
                </BasicButton>
              </li>
            ))}
          </ul>
        )}
      </div>

      <div
        css={{
          marginTop: "14px",
          display: isPostCodeFinderOpen ? "flex" : "none",
        }}
      >
        <BasicButton
          label="Detect current location"
          onClick={async () => {
            const position = await getUserGeoLocation();
            if (position) {
              setSortBy(`Postcode`);
            }
          }}
          css={{
            whiteSpace: "nowrap",
            display: "flex",
          }}
          innerCss={{
            ...addFlex({ x: "left", y: "center" }),
          }}
        >
          <BasicIcon
            name={`Aim`}
            color={`#000000`}
            size={12}
            css={{
              marginRight: "5px",
            }}
          />
          <Paragraph type={`small`}>Detect current location</Paragraph>
        </BasicButton>
      </div>
    </div>
  );
};

export default ProvideLocation;
