import ApolloClient from "apollo-boost";
import getConfiguration from "configuration";
import { useNotificationsStore } from "stores";

const { uri, apiKey, tenantId } = getConfiguration().api;

const client = new ApolloClient({
  uri,
  onError(parameters) {
    const { graphQLErrors, networkError } = parameters;
    // NOTE: Errors used to be removed/stopped here, but now they're not.
    // This means the api calls can be try-catched with custom error handling (like stopping a loader)

    if (graphQLErrors) {
      graphQLErrors.map(({ message, locations, path }, index) => {
        console.error(`[GraphQL error]:
    ${message}`);
        console.error({ locations, path });
        window.setTimeout(() => {
          useNotificationsStore.getState().addNotification({
            type: "error",
            title: "Server error",
            text: "An error occured from the server",
          });
        }, index * 75);
      });
      parameters.graphQLErrors = [];
    }

    if (networkError) {
      console.error(`[Network error]:`);
      console.error(networkError);

      useNotificationsStore.getState().addNotification({
        type: "error",
        title: networkError.name.includes("ServerError")
          ? "Server error"
          : "Network error",
        text: "Something went wrong while connecting",
      });
      parameters.networkError = undefined;
    }
  },
  headers: {
    "x-api-key": apiKey,
    "tenant-id": tenantId,
  },
});

export default client;
