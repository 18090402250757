import { gql } from "apollo-boost";
import Api from "services/api";

export type SubmissionCapabilityType = "EQUIPMENT" | "SERVICE";

type SubmitSuggestCapabilityTagResponse = {
  suggestCapabilityTag: null;
};

export type InputCapability = {
  type: SubmissionCapabilityType;
  id: string;
};

const suggestCapabilityTag = (
  capabilities: InputCapability[],
  values: string[],
  captcha: string
) =>
  Api.mutate<SubmitSuggestCapabilityTagResponse>({
    variables: {
      capabilities: capabilities,
      values,
      captcha,
    },
    mutation: gql`
      mutation suggestCapabilityTag(
        $capabilities: [InputCapabilityId]!
        $values: [String]!
        $captcha: String!
      ) {
        suggestCapabilityTag(
          capabilities: $capabilities
          values: $values
          captcha: $captcha
        )
      }
    `,
  }).then((result) => result.data);

export default suggestCapabilityTag;
