/** @jsxImportSource @emotion/react */
import { jsx } from "@emotion/react";
import React from "react";
import { useTheme } from "@emotion/react";
import { addFlex, addSize } from "utils";
import { useScreenSizeStore } from "stores";
type Props = {
  value: string;
  name: string;
  id: string;
  activeOption: string | undefined;
  color?: string;
  size?: "small" | "medium";
  className?: string;
};

const RADIO_CIRCLE_SIZER_OUTER = {
  default: {
    small: 20,
    medium: 24,
  },
  mobile: {
    small: 20,
    medium: 24,
  },
};

const RADIO_CIRCLE_SIZES_INNER = {
  default: {
    small: 10,
    medium: 14,
  },
  mobile: {
    small: 8,
    medium: 12,
  },
};

const RadioInputOption: React.FC<Props> = ({
  value,
  name,
  id,
  activeOption,
  className,
  color = "#228192",
  size = "medium",
}) => {
  const { isMobile, screenIs } = useScreenSizeStore();
  const theme = useTheme();

  const deviceType = isMobile ? "mobile" : "default";

  const circleSize = `${RADIO_CIRCLE_SIZER_OUTER[deviceType][size]}px`;
  const circleSizeInner = `${RADIO_CIRCLE_SIZES_INNER[deviceType][size]}px`;

  return (
    <div
      css={{ position: "relative", display: "inline-block" }}
      className={className}
    >
      <input
        css={{
          ...addSize({
            size: screenIs({
              default: { default: circleSize, max: circleSize },
              mobile: { default: circleSize, max: circleSize },
            }),
          }),
          position: "relative",
          border: screenIs({
            default: `2px solid ${theme.mainColor}`,
            mobile: `1px solid ${theme.mainColor}`,
          }),
          borderRadius: "20px",
          appearance: "none",
          backgroundColor: "transparent",
          ":focus": {
            outline: "#d8d8d8 solid 1px",
          },
        }}
        type="radio"
        name={name}
        id={id}
        data-automation={id}
        value={value}
      />
      <span
        css={{
          ...addFlex({ x: "center", y: "center" }),
          opacity: activeOption === id ? 1 : 0,
          ...addSize({
            size: screenIs({
              default: { default: circleSize, max: circleSize },
              mobile: { default: circleSize, max: circleSize },
            }),
          }),
          position: "absolute",
          top: "0px",
          left: "0px",
          transition: "opacity .25s",
          pointerEvents: "none",
        }}
      >
        <span
          css={{
            opacity: activeOption === id ? 1 : 0,
            ...addSize({
              size: circleSizeInner,
            }),
            backgroundColor: `${theme.mainColor}`,
            borderRadius: "14px",
            transition: "opacity .25s",
            pointerEvents: "none",
          }}
        ></span>
      </span>
    </div>
  );
};

export default RadioInputOption;
