/** @jsxImportSource @emotion/react */
import { jsx, css } from "@emotion/react";

import React from "react";

type Props = {
  color?: string;
  width?: string;
};

const AddressBook: React.FC<Props> = ({
  color = "#D5D5D5",
  width = "80px",
}) => {
  return (
    <svg
      viewBox="0 0 70 80"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      width={width}
    >
      <path
        d="M0 20.8485v-5.2121h3.99654V3.87879C3.99654 1.81818 5.81315 0 7.99308 0H66.1246C68.1834 0 70 1.81818 70 3.87879V76c0 2.1818-1.8166 4-3.8754 4H7.99308c-2.17993 0-3.87543-1.8182-3.87543-3.8788v-9.2121H.121109V61.697H4.11765V51.6364H.121109v-5.2122H4.11765V36.3636H.121109v-5.2121H4.11765V21.0909H0v-.2424zm37.0588-4.4849c-6.1764 0-11.1418 4.9697-11.1418 11.1516 0 6.1818 4.8442 11.2727 11.1418 11.2727s11.1419-4.9697 11.1419-11.1515c0-6.1819-4.9654-11.2728-11.1419-11.2728zm17.9239 44.6061c0-9.9394-7.9931-17.9394-17.9239-17.9394s-17.9239 8-17.9239 17.9394h35.8478z"
        fill={color}
      />
    </svg>
  );
};

export default AddressBook;
