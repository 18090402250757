/** @jsxImportSource @emotion/react */
import { jsx } from "@emotion/react";
import { useTheme } from "@emotion/react";
import React, { useState, useEffect, useCallback, useRef } from "react";
import Paragraph from "components/Paragraph";
import StackedItems from "./StackedItems";
import { addMargin, addFlex, addPadding } from "utils";
import { useScreenSizeStore } from "stores";
import { FacilityCapabilitiesType } from "utils/types";
import BasicButton from "components/BasicButton";

export type FacilityLinkListItem = {
  text: string;
  link: string;
  label: string;
  id: string;
};
type Props = {
  title: string;
  linkItems: FacilityLinkListItem[];
  automationId: string;
  type: FacilityCapabilitiesType;
  moreText: string;
  listOpenTitle?: string;
  backgroundColour?: string;
  showMoreBarBackgroundColour?: string;
  className?: string;
  initialNumberOfItemsToShow?: number;
};

const FacilityLinksList: React.FC<Props> = React.memo(
  ({
    linkItems,
    title,
    automationId,
    type,
    listOpenTitle,
    backgroundColour = "#f8f8f8",
    showMoreBarBackgroundColour = "#eeeeee",
    className,
    initialNumberOfItemsToShow = 10,
  }) => {
    const { screenIs } = useScreenSizeStore();
    const theme = useTheme();

    const facilityLinksListWrapperRef = useRef<HTMLDivElement>(null);
    const floatingTitleRef = useRef<HTMLDivElement>(null);

    const numberOfItems = linkItems.length;
    const shouldHaveShowMoreLink = numberOfItems > initialNumberOfItemsToShow;

    const [isOpen, setIsOpen] = useState(false);

    const firstTenLinkItems = linkItems.slice(0, initialNumberOfItemsToShow);

    const updateFloatingTitleStyle = useCallback((navBarPosition: number) => {
      if (floatingTitleRef?.current?.style) {
        Object.assign(floatingTitleRef.current.style, {
          transform: `translate3d(0px, ${navBarPosition}px, 0px)`,
          boxShadow:
            navBarPosition !== 0 ? "0 5px 6px rgba(0, 0, 0, 0.15)" : "none",
        });
      }
    }, []);

    const handleScroll = useCallback(() => {
      requestAnimationFrame(() => {
        if (!facilityLinksListWrapperRef.current) return;
        if (isOpen) {
          const bounds =
            facilityLinksListWrapperRef.current.getBoundingClientRect();
          const facilityLinksListNavBarPosition =
            bounds.top >= 0 ? 0 : Math.abs(bounds.top);
          updateFloatingTitleStyle(facilityLinksListNavBarPosition);
        }
      });
    }, [isOpen, updateFloatingTitleStyle]);
    useEffect(() => {
      if (!facilityLinksListWrapperRef.current) return;
      if (!isOpen) {
        // set the floating title style when isOpen becomes false
        // (to avoid setting it onScroll )
        updateFloatingTitleStyle(0);
      }
    }, [isOpen, updateFloatingTitleStyle]);

    useEffect(() => {
      window.addEventListener("scroll", handleScroll);
      return () => {
        window.removeEventListener("scroll", handleScroll);
      };
    }, [handleScroll]);

    return (
      <div
        css={{
          background: backgroundColour,
          borderRadius: "8px",
          height: "auto",
          overflow: "hidden",
          position: "relative",
          padding: screenIs({
            default: shouldHaveShowMoreLink
              ? `${
                  initialNumberOfItemsToShow == 0 ? "24px" : "80px"
                } 20px 25px 20px`
              : "25px 20px",
            mobile: shouldHaveShowMoreLink
              ? `${
                  initialNumberOfItemsToShow == 0 ? "24px" : "80px"
                } 15px 20px 15px`
              : "15px 20px",
          }),
        }}
        className={className}
        data-automation={automationId}
        ref={facilityLinksListWrapperRef}
      >
        {shouldHaveShowMoreLink && (
          <div
            ref={floatingTitleRef}
            css={{
              position: "absolute",
              borderTopLeftRadius: "8px",
              borderTopRightRadius: "8px",
              overflow: "hidden",
              top: 0,
              left: 0,
              width: "100%",
              zIndex: 2,
              transition: "box-shadow 0.2s",
            }}
          >
            <BasicButton
              css={{
                width: "100%",
                zIndex: 2,
                minHeight: "48px",
                background: showMoreBarBackgroundColour,
                ...addFlex({ x: "center", y: "center" }),
              }}
              label={isOpen ? "Show less" : "Show all"}
              onClick={() => {
                setIsOpen(!isOpen);
              }}
              innerCss={{
                width: "100%",
                padding: "0 20px",
                flex: 1,
                fontSize: "14px",
                fontWeight: "bold",
                minHeight: "48px",
                ...addPadding({ vertical: "12px" }),
                ...addFlex({ x: "left", y: "center", direction: "right" }),
                justifyContent: "space-between",
              }}
            >
              <Paragraph
                bold
                css={{
                  lineHeight: "normal",
                  color: "#2F2F2F",
                  fontFamily: theme.fonts.main,
                  fontStyle: "normal",
                  fontWeight: "bold",
                }}
              >
                {!isOpen ? title : listOpenTitle}
              </Paragraph>
              <div
                css={{
                  display: "flex",
                  alignItems: "center",
                  ...addMargin({ left: "10px" }),
                  color: "#2F2F2F",
                  fontFamily: theme.fonts.main,
                  fontStyle: "normal",
                  fontWeight: "bold",
                  textDecoration: "underline",
                }}
              >
                {isOpen ? "Show less" : `Show all (${numberOfItems})`}
              </div>
            </BasicButton>
          </div>
        )}

        {!shouldHaveShowMoreLink && (
          <Paragraph
            bold
            css={{
              ...addMargin({
                top: "0",
                bottom: screenIs({ default: "15px", mobile: "10px" }),
              }),
              color: "#2F2F2F",
            }}
          >
            {title}
          </Paragraph>
        )}
        <StackedItems
          isOpen={isOpen}
          linkItems={isOpen ? linkItems : firstTenLinkItems}
          type={type}
        ></StackedItems>
      </div>
    );
  }
);

export default FacilityLinksList;
