/** @jsxImportSource @emotion/react */
import { jsx } from "@emotion/react";
import { useTheme } from "@emotion/react";
import { useLocation } from "react-router-dom";
import React, { useRef } from "react";
import PATH from "utils/routes";
import { addFlex, addPadding } from "utils";
import { useScreenSizeStore } from "stores";

import tenantConfig from "tenants";

type Props = {};

function getHeadline(latestPathname: string): string {
  const { TENANT } = process.env;

  switch (latestPathname) {
    case PATH.privacy:
      if (TENANT === "arin") {
        return "ARIN Portal Terms of Use";
      }
      return "Privacy Policy";
    case PATH.cookies:
      return "Cookies";
    case PATH.termsOfService:
      return "Terms Of Service";
    case PATH.facilities:
      return `${
        tenantConfig.text.usePlatfrom ? "Platform" : "Facility"
      } directory`;
    default:
      return "";
  }
}

function getTextAlignment(latestPathname: string): "left" | "center" {
  return latestPathname === PATH.facilities ? "left" : "center";
}

const DisclaimerContent: React.FC<Props> = () => {
  const { screenIs } = useScreenSizeStore();

  const location = useLocation();
  const { current: localContext } = useRef({
    latestPathname: location.pathname,
  });
  localContext.latestPathname = location.pathname;

  const titleText = getHeadline(localContext.latestPathname);
  const x = getTextAlignment(localContext.latestPathname);

  const theme = useTheme();

  return (
    <div
      css={{
        label: "home-title",
        ...addFlex({ x, y: "center" }),
        ...addPadding(
          screenIs({
            default: { horizontal: "75px", vertical: "45px" },
            mobile: { horizontal: "15px", vertical: "20px" },
          })
        ),
        color: theme.topFocusBoxHeading,

        width: "100%",
      }}
      data-automation={`staticContentHeadline`}
    >
      <h1>{titleText}</h1>
    </div>
  );
};

export default DisclaimerContent;
