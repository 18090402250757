/** @jsxImportSource @emotion/react */
import { jsx, css } from "@emotion/react";

import React from "react";

type Props = {
  color: string;
  css: ReturnType<typeof css>;
};

const BackArrow: React.FC<Props> = ({ color = "#3D3D3D", css }) => {
  return (
    <svg
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      css={css}
      viewBox="0 0 24 24"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M12.7071 5.7071c.3905-.3905.3905-1.0237 0-1.4142-.3905-.3905-1.0237-.3905-1.4142 0l-7 7c-.3905.3905-.3905 1.0237 0 1.4142l7 7c.3905.3905 1.0237.3905 1.4142 0 .3905-.3905.3905-1.0237 0-1.4142L7.4142 13H19c.5523 0 1-.4477 1-1s-.4477-1-1-1H7.4142l5.2929-5.2929z"
        fill={color}
      />
    </svg>
  );
};

export default BackArrow;
