/** @jsxImportSource @emotion/react */
import { jsx } from "@emotion/react";
import { useTheme } from "@emotion/react";
import React from "react";
import Paragraph from "components/Paragraph";
import { addMargin } from "utils";

type Props = { className?: string };

const CaptchaNotice: React.FC<Props> = ({ className }) => {
  const theme = useTheme();

  return (
    <Paragraph
      type={`small`}
      css={{
        ...addMargin({ bottom: "20px", top: "10px" }),
        color: "#b6b6b6",
      }}
      className={className}
    >
      This form is protected by reCAPTCHA and the Google{" "}
      <a
        css={{ color: theme.mainColor }}
        href="https://policies.google.com/privacy"
      >
        Privacy Policy
      </a>{" "}
      and{" "}
      <a
        css={{ color: theme.mainColor }}
        href="https://policies.google.com/terms"
      >
        Terms of Service
      </a>{" "}
      apply.
    </Paragraph>
  );
};

export default CaptchaNotice;
