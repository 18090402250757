/** @jsxImportSource @emotion/react */
import { jsx } from "@emotion/react";
import React from "react";
import { addMargin, addSize } from "utils";

type Props = {};

const NavigatorDivider: React.FC<Props> = () => (
  <span
    css={{
      ...addMargin({ vertical: "0", horizontal: "15px" }),
      ...addSize({ width: "1px", height: "32px" }),
      background: "#EEF4F6",

      verticalAlign: "middle",
      display: "inline-block",
    }}
  ></span>
);

export default NavigatorDivider;
