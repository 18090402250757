/** @jsxImportSource @emotion/react */
import { jsx } from "@emotion/react";
import React, { useEffect } from "react";
import scrollIntoView from "scroll-into-view-if-needed";
import { addFlex, addMargin, addPadding, addSize } from "utils";
import Paragraph from "components/Paragraph";
import { useScreenSizeStore } from "stores";
import tenantConfig from "tenants";

type Props = {};

const paragraphCss = { ...addMargin({ bottom: "20px" }) };

const PrivacyContent: React.FC<Props> = () => {
  useEffect(() => {
    // scroll to the top when opening the privacy page
    scrollIntoView(document.body, {
      scrollMode: "if-needed",
      block: "start",
      behavior: "smooth",
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const { screenIs } = useScreenSizeStore();

  return (
    <div
      css={{
        ...addFlex({ x: "center", y: "center", direction: "down" }),
        position: "relative",
        top: 0,
        left: 0,
        ...addPadding(
          screenIs({
            default: { default: "50px" },
            mobile: { horizontal: "0px", vertical: "30px" },
          })
        ),
        width: "100%",
      }}
    >
      <div
        css={{
          ...addSize({ width: { default: "100%", max: "800px" } }),
        }}
      >
        <Paragraph css={paragraphCss}>
          The {tenantConfig.name} is managed by Monash University (ABN 12 377
          614 012) and subject to the Monash Websites Terms and Conditions
          available at{" "}
          <div>
            <a
              css={{
                color: "#228192",
                fontWeight: "bold",
                ":visited": {
                  color: "#228192",
                },
              }}
              href="https://www.monash.edu/terms-and-conditions"
              target={`_blank`}
            >
              https://www.monash.edu/terms-and-conditions
            </a>
            .
          </div>
        </Paragraph>
        <Paragraph css={paragraphCss}>
          While the University attempts to ensure that the information on this
          website is accurate at the time of publication, it provides no express
          or implied warranties or makes any representations in relation to this
          website or any content. The information on this website is provided
          'as is' and without any guarantees as to its accuracy, currency,
          completeness or reliability.
        </Paragraph>
        <Paragraph css={paragraphCss}>
          This website relies on information provided by independent research
          institutions within the Australian Research Infrastructure Network and
          the University does not guarantee the accuracy of any results. It is
          expected that you will contact the relevant research institution to
          verify that any infrastructure/equipment is suitable for your intended
          purposes.
        </Paragraph>
        <Paragraph css={paragraphCss}>
          The University accepts no responsibility for any loss or damage
          occasioned by use of this website or information contained on this
          website. All access to and use of this website and its information is
          at the risk of the user.
        </Paragraph>
        <Paragraph css={paragraphCss}>
          This website may contain links to third party websites which are
          provided for convenience only and should not be construed as an
          endorsement or approval of the website or its content by Monash
          University.
        </Paragraph>
      </div>
    </div>
  );
};

export default PrivacyContent;
