import { FormInputState } from "./types";

export const defaultInitialInputItemState: FormInputState = {
  // setup
  inputType: "text",
  inputId: "deafultInputId",
  initialValue: "",
  validatorTypes: [],
  validatorsOptions: {},
  // state
  value: "",
  validationErrorTypes: [],
  validationErrorTexts: [],
  // times
  timeOpened: 0,
  timeUpdated: 0,
  timeFocused: 0,
  timeUnfocused: 0,
  // interpreted from above
  isEdited: false,
  isValid: false,
  isFocused: false,
  hasBeenUnfocused: false,
};
