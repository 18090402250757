/** @jsxImportSource @emotion/react */
import { jsx } from "@emotion/react";
import { useTheme } from "@emotion/react";
import React from "react";
import { useScreenSizeStore } from "stores";
import BasicButton from "components/BasicButton";
import { PATHS } from "utils/routes";
import Paragraph from "components/Paragraph";
import { addFlex } from "utils";
import tenantConfig from "tenants";

type Props = { description?: String };

const PageNotFound: React.FC<Props> = ({
  description = "The page you are looking for appears to have been moved, deleted or doesn’t exist.",
}) => {
  const { screenIs } = useScreenSizeStore();
  const theme = useTheme();

  return (
    <div
      data-automation="errorWrapper"
      css={{
        textAlign: "center",
        ...addFlex({ x: "center", y: "center", direction: "down" }),
        height: "80vh",
        width: "100%",
      }}
    >
      {tenantConfig.images.notFound && (
        <img
          src={tenantConfig.images.notFound}
          css={{
            width: screenIs({
              default: "278px",
              tablet: "225px",
              mobile: "150px",
            }),
            height: screenIs({
              default: "315px",
              tablet: "255px",
              mobile: "170px",
            }),
            marginBottom: screenIs({ default: "50px", mobile: "30px" }),
          }}
          alt={``}
        />
      )}

      <div
        css={{
          width: screenIs({
            desktop: "600px",
            default: "342px",
          }),
          maxWidth: "calc(100% - 30px)",
          ...addFlex({ x: "center", y: "center", direction: "down" }),
        }}
      >
        <h1
          css={{
            color: theme.mainColor,
            marginBottom: "20px",
          }}
        >
          I can't find what you're looking for! Error 404.
        </h1>

        {description && <Paragraph type="intro">{description}</Paragraph>}
        <BasicButton
          automationId={`homeLink`}
          label="open homepage"
          css={{
            fontWeight: "bold",
            color: "#3D3D3D",
            textDecoration: "underline",
          }}
          linkTo={PATHS.index}
        >
          <Paragraph type="intro">
            Take me to {tenantConfig.name} homepage.
          </Paragraph>
        </BasicButton>
      </div>
    </div>
  );
};

export default PageNotFound;
