/** @jsxImportSource @emotion/react */
import { jsx, css } from "@emotion/react";

import React from "react";

type Props = {
  color: string;
  css: ReturnType<typeof css>;
};

const BuildingIcon: React.FC<Props> = ({ color = "#3D3D3D", css }) => {
  return (
    <svg
      viewBox="0 0 22 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      css={css}
    >
      <path
        d="M11 4.36548V0H0v20h22V4.36548H11zM4.33945 17.665H2.11927v-2.132h2.22018v2.132zm0-4.3655H2.11927V11.066h2.22018v2.2335zm0-4.36549H2.11927v-2.2335h2.22018v2.2335zm0-4.56853H2.11927V2.2335h2.22018v2.13198zM8.77982 17.665H6.66055v-2.132h2.11927v2.132zm0-4.3655H6.66055V11.066h2.11927v2.2335zm0-4.36549H6.66055v-2.2335h2.11927v2.2335zm0-4.56853H6.66055V2.2335h2.11927v2.13198zM19.8807 17.665H11v-2.132h2.2202v-2.2335H11V11.066h2.2202V8.93401H11v-2.2335h8.8807V17.665zm-2.3211-2.132h-2.2202v-2.2335h2.2202v2.2335zm0-4.467h-2.2202V8.93401h2.2202V11.066z"
        fill={color}
      />
    </svg>
  );
};

export default BuildingIcon;
