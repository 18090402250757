/** @jsxImportSource @emotion/react */
import { jsx, css } from "@emotion/react";

import React from "react";

type Props = {
  color: string;
  css: ReturnType<typeof css>;
};

const HomeIcon: React.FC<Props> = ({ color = "#3D3D3D", css }) => {
  return (
    <svg
      width="21"
      height="21"
      viewBox="0 0 21 21"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M8.75 17.5V12.25H12.25V17.5H16.625V10.5H19.25L10.5 2.625L1.75 10.5H4.375V17.5H8.75Z"
        fill="white"
      />
    </svg>
  );
};

export default HomeIcon;
