/** @jsxImportSource @emotion/react */
import { jsx, css } from "@emotion/react";
import React from "react";
import { useScreenSizeStore } from "stores";
import arinImage from "assets/images/arinImage.png";
import { addFlex, addPadding, addSize } from "utils";
import Paragraph from "components/Paragraph";

type Props = {};

const AboutArin: React.FC<Props> = () => {
  const { screenIs, isLandscapeTablet, isMobile } = useScreenSizeStore();
  const imageWidth = screenIs({ default: 360, tablet: 202, mobile: 118 });
  const imageHeight = screenIs({ default: 460, tablet: 258, mobile: 123 });
  return (
    <div
      css={{
        label: "home-title",
        ...addFlex({ x: "center", y: "center" }),
        ...addPadding(
          screenIs({
            default: {
              horizontal: isLandscapeTablet ? "75px" : "150px",
              vertical: "100px",
            },
            tablet: { horizontal: "80px", vertical: "75px" },
            mobile: { horizontal: "15px", vertical: "80px" },
          })
        ),
        color: "#2F5673",

        width: "100%",
      }}
      data-automation={`staticContentHeadline`}
    >
      <div
        css={{
          width: screenIs({ default: "50%", tablet: "65%", mobile: "100%" }),
        }}
      >
        <h1>What does ARIN do?</h1>
        <Paragraph type={`intro`} css={{ marginTop: "20px" }}>
          Participating research organisations allow Arin to read their
          equipment booking engines, and Arin presents the results to you. You
          then directly contact the facility to arrange booking.
        </Paragraph>
      </div>
      <div
        css={{
          width: screenIs({ default: "50%", tablet: "35%", mobile: "0%" }),
          ...addFlex({ x: "right", y: "center" }),
          display: isMobile ? "none" : "flex",
        }}
      >
        <div
          role="img"
          aria-label="Periscope character"
          title=""
          css={{
            label: "arin-image",
            position: "relative",
            ...addSize({
              width: `${imageWidth}px`,
              height: `${imageHeight}px`,
            }),
            backgroundImage: `url("${arinImage}")`,
            backgroundSize: "cover",
            backgroundPosition: "center top",
            transform: `translateX(${screenIs({
              default: "0",
              tablet: "45px",
              mobile: "-38px",
            })})`,
          }}
        ></div>
      </div>
    </div>
  );
};

export default AboutArin;
