/** @jsxImportSource @emotion/react */
import { jsx } from "@emotion/react";
import React, { useState } from "react";
import parse from "html-react-parser";
import { useLocation } from "react-router-dom";
import {
  useScreenSizeStore,
  useFacilityStore,
  useCapabilitySuggestionStore,
} from "stores";

import { addFlex, addPadding, addMargin } from "utils";
import { getValidationToken } from "services/validationToken";
import FormButtons from "components/SendableForm/FormButtons";
import { FeedbackType } from "stores/utils/storeMakers/makeModalStore/types";
import CaptchaNotice from "components/CaptchaNotice";
import { parseAndValidateHash } from "utils/routes";
import { parseHTMLOptions } from "utils/parseHtml";
import DataWrapper from "components/DataWrapper";
import FormHeader from "components/SendableForm/FormHeader";
import MultiStepFormCount from "components/SendableForm/MultiStepFormCount";
import {
  getCapability,
  submitValidateAlternative,
  useShowNextModalAfterSubmittingEffect,
} from "components/SendableForm/utils/FormHelpers";
import { Equipment } from "utils/types";
import { NextFormAction } from "../types";

type Props = {
  feedBackTypeText?: string;
  feedBackType?: FeedbackType;
  nextAction?: NextFormAction;
};
const FORM_SUGGESTION_TYPE = "description" as const;

const SubmitDescriptionForm: React.FC<Props> = () => {
  const { hash } = useLocation();
  const parsedHash = parseAndValidateHash(hash);
  const { screenIs, isDesktop } = useScreenSizeStore();

  const [facility] = useFacilityStore(({ facility }) => [facility]);
  const currentId = parsedHash && parsedHash.id ? parsedHash.id : undefined;

  const { title, description } = currentId
    ? getCapability(currentId, facility)
    : ({} as Equipment);

  const {
    suggestions,
    numberOfSuggestions,
    setSuggestionsConsidered,
    numberOfSuggestionsCurrentPage,
    setSuggestionsConfirmed,
  } = useCapabilitySuggestionStore();

  const currentStep = numberOfSuggestionsCurrentPage();

  const [isSubmitting, setIsSubmitting] = useState(false);
  const [isFormSubmittedSuccessfully, setIsFormSubmittedSuccessfully] =
    useState(false);

  useShowNextModalAfterSubmittingEffect(isFormSubmittedSuccessfully);

  return (
    <div
      css={{
        ...addFlex({ x: "left", y: "top" }),
        overflow: "auto",
        width: "100%",
        maxHeight: `${window.innerHeight - 30}px`,
      }}
    >
      <div
        css={{
          width: isDesktop ? "840px" : "100%",
          height: "auto",
        }}
      >
        <FormHeader capabilityName={title}>
          <h2 css={{ color: "#2F5673" }}>Is this a better description?</h2>
          {numberOfSuggestions > 1 && (
            <MultiStepFormCount
              currentStep={currentStep}
              maxSteps={numberOfSuggestions}
            />
          )}
        </FormHeader>
        <div
          css={{
            ...addPadding({
              top: "0",
              bottom: "0",
              horizontal: screenIs({
                default: "50px",
                tablet: "40px",
                mobile: "20px",
              }),
            }),
          }}
        >
          <form
            onSubmit={async (e) => {
              e.preventDefault();
              setIsSubmitting(true);
              const validationToken =
                (await getValidationToken(`submitImageValidation`)) || "";
              await submitValidateAlternative(
                {
                  id: suggestions.description?.id || "",
                  captcha: validationToken,
                },
                setIsFormSubmittedSuccessfully
              );

              setSuggestionsConsidered(FORM_SUGGESTION_TYPE, currentId);
              setSuggestionsConfirmed(FORM_SUGGESTION_TYPE);
              setIsSubmitting(false);
            }}
          >
            <div
              css={{
                display: "flex",
                flexDirection: isDesktop ? "row" : "column",
              }}
            >
              <div
                css={{
                  width: isDesktop ? "50%" : "100%",
                  marginRight: isDesktop ? "15px" : 0,
                }}
              >
                <h6
                  css={{
                    textAlign: "center",
                    ...addMargin({ vertical: "20px" }),
                  }}
                >
                  Current
                </h6>
                <DataWrapper type={`description`}>
                  {parse(description || "", parseHTMLOptions)}
                </DataWrapper>
              </div>
              <div
                css={{
                  width: isDesktop ? "50%" : "100%",
                  marginLeft: isDesktop ? "15px" : 0,
                }}
              >
                <h6
                  css={{
                    textAlign: "center",
                    ...addMargin(
                      isDesktop
                        ? { top: "20px", bottom: "20px" }
                        : { top: "35px", bottom: "25px" }
                    ),
                  }}
                >
                  <span
                    css={{
                      background: "#7CD1E0",
                      borderRadius: "100px",
                      padding: "5px 20px",
                    }}
                  >
                    Suggested
                  </span>
                </h6>
                <DataWrapper type={`description`}>
                  {parse(
                    suggestions.description?.value || "",
                    parseHTMLOptions
                  )}
                </DataWrapper>
              </div>
            </div>
            <CaptchaNotice />
            <FormButtons
              submitText={"Yes"}
              cancelText={`No`}
              onCancelButton={() =>
                setSuggestionsConsidered(FORM_SUGGESTION_TYPE, currentId)
              }
              notSureCloseOption
              submitDisabled={false}
              isSubmitting={isSubmitting}
            />
          </form>
        </div>
      </div>
    </div>
  );
};

export default SubmitDescriptionForm;
