/** @jsxImportSource @emotion/react */
import { jsx, css } from "@emotion/react";

import React from "react";

type Props = {
  color: string;
  css: ReturnType<typeof css>;
};

const BuildingIcon: React.FC<Props> = ({ color = "#3D3D3D", css }) => {
  return (
    <svg
      viewBox="0 0 22 22"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      css={css}
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M11 2c-4.97056 0-9 4.02944-9 9 0 4.9706 4.02944 9 9 9 4.9706 0 9-4.0294 9-9 0-4.97056-4.0294-9-9-9zM0 11C0 4.92487 4.92487 0 11 0c6.0751 0 11 4.92487 11 11 0 6.0751-4.9249 11-11 11-6.07513 0-11-4.9249-11-11z"
        fill={color}
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M11 10c.5523 0 1 .4477 1 1v4c0 .5523-.4477 1-1 1s-1-.4477-1-1v-4c0-.5523.4477-1 1-1z"
        fill={color}
      />
      <path
        d="M12 7c0 .55228-.4477 1-1 1s-1-.44772-1-1 .4477-1 1-1 1 .44772 1 1z"
        fill={color}
      />
    </svg>
  );
};

export default BuildingIcon;
