/** @jsxImportSource @emotion/react */
import { jsx } from "@emotion/react";
import React from "react";
import Paragraph from "components/Paragraph";
import { useScreenSizeStore } from "stores";
import { addSize, addFlex, addPadding } from "utils";

import arinCircle from "assets/images/arinCircle.png";
import BasicButton from "components/BasicButton";
import LinkUnderline from "components/LinkUnderline";

type Props = {
  headline: string;
  text: string;
  viewSuggestion: () => void;
  dismissMissSuggestion: () => void;
};

const SuggestionTeaser: React.FC<Props> = ({
  headline,
  text,
  viewSuggestion,
  dismissMissSuggestion,
}) => {
  const { screenIs } = useScreenSizeStore();
  return (
    <div
      css={{
        background: "rgba(153, 153, 153, 0.15)",
        border: "2px dashed #979797",
        borderRadius: "16px",
        padding: "16px 25px",
        position: "relative",
        textAlign: "center",
        display: "flex",
        flexDirection: "column",
        margin: "25px 0",
      }}
    >
      <div
        role="img"
        aria-label=""
        title=""
        css={{
          label: "arin-image",
          position: "absolute",
          left: "-25px",
          top: "-23.5px",
          ...addSize(
            screenIs({
              default: {
                width: { default: "50px", min: "50px" },
                height: { default: "47px", min: "47px" },
              },
              mobile: { width: "40px", height: "37px" },
            })
          ),
          backgroundImage: `url("${arinCircle}")`,
          backgroundSize: "cover",
          backgroundPosition: "center top",
          overflow: "hidden",
        }}
      ></div>
      <Paragraph
        bold
        css={{
          color: "#fff",
        }}
      >
        {headline}
      </Paragraph>
      <Paragraph
        type={`small`}
        css={{
          color: "#fff",
        }}
      >
        {text}
      </Paragraph>
      <BasicButton
        onClick={() => viewSuggestion()}
        label={`Submit an enquiry`}
        css={{
          background: "#228192",
          borderRadius: "8px",
          width: "100%",
          marginTop: "15px",
          height: "40px",
        }}
        innerCss={{
          ...addFlex({ x: "center", y: "center" }),
          ...addPadding({ horizontal: "5px" }),
          borderRadius: "8px",
          whiteSpace: "nowrap",
          fontWeight: "bold",
          color: "white",
          width: "100%",
        }}
      >
        <Paragraph
          bold
          css={{
            color: "#fff",
          }}
          type={`small`}
        >
          View suggestion
        </Paragraph>
      </BasicButton>
      <LinkUnderline
        label={`Dismiss suggestion`}
        onClick={() => dismissMissSuggestion()}
        css={{
          marginTop: "10px",
        }}
        innerSpanCss={{
          fontSize: "14px",
          color: "#7CD1E0",
        }}
      >
        Dismiss
      </LinkUnderline>
    </div>
  );
};

export default SuggestionTeaser;
