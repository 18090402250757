/** @jsxImportSource @emotion/react */
import { jsx, keyframes } from "@emotion/react";
import { useTheme } from "@emotion/react";
import React from "react";
import { addPadding, addFlex } from "utils";
import BasicIcon from "components/BasicIcon";

const spinAnimation = keyframes`
  to { transform: rotate(360deg); }
`;

type Props = {
  isLoading?: boolean;
  inline?: boolean;
  height?: string;
  verticalPadding?: string;
  size?: number;
  color?: string;
  backgroundColor?: string;
};

const LoadingSpinner: React.FC<Props> = ({
  isLoading = true,
  inline = false,
  height = "100%",
  verticalPadding = "32px",
  size = 100,
  color = "#228192",
  backgroundColor = "#e8e8e8",
}) => {
  const theme = useTheme();
  const mainSpinnerContent = (
    <div
      css={{
        position: "relative",
        ...addPadding({ vertical: verticalPadding }),
        ...addFlex({ x: "center", y: "center" }),
        height,
        width: "100%",
      }}
    >
      <div
        css={{
          position: "relative",
          ...addFlex({ x: "center", y: "center" }),
          animation: `${spinAnimation} 1s ease infinite`,
          animationPlayState: isLoading ? "running" : "paused",
        }}
      >
        <BasicIcon
          name="LoadingCircle"
          size={size}
          color={theme.mainColor}
          secondColor={backgroundColor}
        />
      </div>
    </div>
  );

  if (inline) {
    return mainSpinnerContent;
  }
  return (
    <div
      aria-hidden={isLoading ? "true" : "false"}
      css={{
        label: "absolute-NoResults-holder",
        position: "absolute",
        top: "0",
        left: "0",
        width: "100%",
        height,
        pointerEvents: isLoading ? "all" : "none",
        opacity: isLoading ? 1 : 0,
        transition: "opacity 0.3s ease-in-out",
        ...addFlex({ x: "center", y: "center" }),
        display: isLoading ? "flex" : "none",
      }}
      data-automation={`loading-spinner`}
    >
      {mainSpinnerContent}
    </div>
  );
};

export default LoadingSpinner;
