import "react-app-polyfill/ie11";
import "react-app-polyfill/stable";
import React, { useEffect } from "react";
import ReactDOM from "react-dom";
import { Global, css } from "@emotion/react";
import * as Sentry from "@sentry/react";
import { Integrations } from "@sentry/tracing";
import { Router } from "react-router-dom";
import "./index.css";
import App from "./App";
import * as serviceWorker from "./serviceWorker";
import { useScreenSizeStore } from "./stores/screenSize";
import debounce from "lodash.debounce";
import createdHistory from "utils/history";
import tenantConfig from "tenants";
import ReactGA from "react-ga";
import { useShowNextModalAfterSubmittingEffect } from "components/SendableForm/utils/FormHelpers";
import TagManager from "react-gtm-module";

const { REACT_APP_ENV, REACT_APP_GTM_CODE, REACT_APP_TENANT } = process.env;

if (REACT_APP_ENV !== "production") {
  const metaTag = document.createElement("meta");
  metaTag.name = "robots";
  metaTag.content = "noindex, nofollow";
  document.head.append(metaTag);
}

document.title = tenantConfig.text.documentTitle;
const fontsLink = document.createElement("link");
fontsLink.href = tenantConfig.theme.fonts.googleFontsLink;
fontsLink.rel = "stylesheet";
fontsLink.id = "Injected";
document.head.append(fontsLink);
const favicon: any = document.getElementById("favicon");

switch (REACT_APP_TENANT) {
  case "arin":
    favicon.href = "./favicon-arin.ico";
    break;
  case "singascope":
    favicon.href = "./favicon-singascope.ico";
    break;
  case "monashUni":
    favicon.href = "./favicon-monash.ico";
    break;
}

if (REACT_APP_ENV !== "development" && REACT_APP_GTM_CODE) {
  TagManager.initialize({
    gtmId: REACT_APP_GTM_CODE,
  });
}

// useEffect(() => {
//   if (window.location.host.indexOf("arin.org.au") === 0) {
//     const script = document.createElement("script");
//     script.innerHTML = `(function(w,d,s,l,i){w[l]=w[l]||[];w[l].push({'gtm.start':
//                         new Date().getTime(),event:'gtm.js'});var f=d.getElementsByTagName(s)[0],
//                         j=d.createElement(s),dl=l!='dataLayer'?'&l='+l:'';j.async=true;j.src=
//                         'https://www.googletagmanager.com/gtm.js?id='+i+dl;f.parentNode.insertBefore(j,f);
//                         })(window,document,'script','dataLayer','GTM-KS2265X');`;
//     document.head.appendChild(script);
//     const noscript = document.createElement("noscript");
//     noscript.innerHTML = `<iframe src="https://www.googletagmanager.com/ns.html?id=GTM-KS2265X" height="0" width="0" style="display:none;visibility:hidden"></iframe>`;
//     document.body.prepend(noscript);
//   }
// }, []);

// Add screen resize event listener
window.addEventListener(
  "resize",
  debounce(
    () => {
      useScreenSizeStore.getState().updateValues();
    },
    250,
    { leading: false, maxWait: 3000, trailing: true }
  )
);

setTimeout(() => {
  useScreenSizeStore.getState().updateValues();
}, 500);

// Sentry reporting
Sentry.init({
  dsn: "https://7d2347575af24055aae210dcdde93635@o479031.ingest.sentry.io/5548519",
  integrations: [new Integrations.BrowserTracing()],

  // We recommend adjusting this value in production, or using tracesSampler
  // for finer control
  tracesSampleRate: 1.0,
  ignoreErrors: ["ResizeObserver loop limit exceeded"],
});

ReactDOM.render(
  <Router history={createdHistory}>
    <Global
      styles={css`
        body,
        input,
        h1,
        h2,
        h3,
        h4,
        h5 {
          font-family: ${tenantConfig.theme.fonts.globalFontFamily};
        }
      `}
    />
    <App />
  </Router>,
  document.getElementById("root")
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
