/** @jsxImportSource @emotion/react */
import { jsx } from "@emotion/react";
import { useTheme } from "@emotion/react";
import React, { Fragment, useState } from "react";
import Paragraph from "components/Paragraph";
import CaptchaNotice from "components/CaptchaNotice";
import FormButtons from "components/SendableForm/FormButtons";
import { Facility, Service, Equipment } from "utils/types";
import suggestCapabilityTag, {
  SubmissionCapabilityType,
} from "services/api/suggestCapabilityTag";
import { getValidationToken } from "services/validationToken";

type Props = {
  searchTerm: string | undefined;
  facility: Facility | null | undefined;
  missMatchedCapabilities: {
    clickedEquipment: (Service | Equipment)[];
    clickedServices: (Service | Equipment)[];
  };
  onSubmit: () => void;
  onCancelButton: () => void;
};

function submitSearchTermToTag(
  type: SubmissionCapabilityType,
  id: string,
  value: string,
  captcha: string
) {
  suggestCapabilityTag([{ id, type }], [value], captcha);
}

const SingleTerm: React.FC<Props> = ({
  searchTerm,
  facility,
  missMatchedCapabilities,
  onSubmit,
  onCancelButton,
}) => {
  const { clickedEquipment, clickedServices } = missMatchedCapabilities;
  const [isSubmitting, setIsSubmitting] = useState(false);
  const type = !!clickedEquipment.length ? "EQUIPMENT" : "SERVICE";
  const givenCapability = !!clickedEquipment.length
    ? clickedEquipment[0]
    : clickedServices[0];
  const theme = useTheme();

  return (
    <Fragment>
      {givenCapability && (
        <div
          css={{
            background: "#F8F8F8",
            border: "1px solid rgba(148, 148, 148, 0.25)",
            borderRadius: "2px",
            padding: "15px 25px",
          }}
        >
          {givenCapability && (
            <h2
              css={{
                color: theme.mainColor,
              }}
            >
              {givenCapability.title}
            </h2>
          )}
        </div>
      )}

      <Paragraph
        css={{
          marginTop: "30px",
          marginBottom: "40px",
        }}
      >
        By associating this equipment with your search term, it will{" "}
        <strong>
          {`improve ${
            facility ? `${facility?.title} ` : ""
          }listing and help others find it more easily in the
            future.`}
        </strong>
      </Paragraph>

      <form
        onSubmit={async (e) => {
          e.preventDefault();
          setIsSubmitting(true);
          const validationToken =
            (await getValidationToken(`submitContactForm`)) || "";
          submitSearchTermToTag(
            type,
            givenCapability.id,
            searchTerm || "",
            validationToken
          );
          onSubmit();
          setIsSubmitting(false);
        }}
      >
        <CaptchaNotice />
        <FormButtons
          submitText={"Yes"}
          cancelText={`No`}
          onCancelButton={onCancelButton}
          notSureCloseOption
          submitDisabled={false}
          isSubmitting={isSubmitting}
        />
      </form>
    </Fragment>
  );
};

export default SingleTerm;
