import getConfiguration from "configuration";
import { FirebaseAnalytics, AnalyticsCallOptions } from "utils/types/fireBase";
import { initializeApp } from "firebase/app";
import { getAnalytics, Analytics } from "firebase/analytics";
import type { FirebaseApp } from "global";

let analytics = {} as Analytics;

function analyticsIsInitialised() {
  return !(Object.keys(analytics).length === 0);
}

const RE_TRY_INITALISE_ANALYTICS = {
  numberOfTimes: 25,
  delay: 300,
} as const;

function initialiseAnalytics(): void {
  if (!getConfiguration().shouldCollectAnalyticsData) {
    console.warn(`The application is not collecting any analytics while shouldCollectAnalyticsData is set to false.
  If you would like to collect analytics please set the configuration shouldCollectAnalyticsData to true.`);
    return;
  }

  let tries = 0;
  const apps = [];
  const intervalID = setInterval(() => {
    if (!apps.length) {
      const app = initializeApp(getConfiguration().fireBaseConfig);
      analytics = getAnalytics(app);
      apps.push(app);
    }

    tries++;

    if (!!apps?.length || tries >= RE_TRY_INITALISE_ANALYTICS.numberOfTimes) {
      window.clearInterval(intervalID);

      if (!apps.length) {
        console.error("FAILED TO INITIALISE FIREBASE");
      }
    }
  }, RE_TRY_INITALISE_ANALYTICS.delay);
}

type MockFirebaseAnalytics = {
  logEvent: (
    eventName: string,
    eventParams?: { [key: string]: string | number | boolean },
    options?: AnalyticsCallOptions
  ) => void;
  app: FirebaseApp;
};

function getAnalyticsPublisher(): Analytics | MockFirebaseAnalytics {
  if (!analyticsIsInitialised()) {
    initialiseAnalytics();
  }

  // if analytics still isn't initialised
  if (!analyticsIsInitialised()) {
    const mockFirebaseAnalytics: MockFirebaseAnalytics = {
      logEvent(
        eventName: string,
        eventParams?: { [key: string]: string | number | boolean },
        options?: AnalyticsCallOptions
      ) {
        console.warn(
          "MOCK ANALYTICS EVENT",
          "tried to log event called",
          eventName,
          " event data below"
        );
        console.warn(eventParams, options);
      },
      app: {
        name: "",
        options: {},
        automaticDataCollectionEnabled: false,
        delete: async () => {},
      },
    };

    return mockFirebaseAnalytics;
  }

  return analytics;
}
export { initialiseAnalytics, getAnalyticsPublisher };
