/** @jsxImportSource @emotion/react */
import { jsx } from "@emotion/react";
import React, { useCallback } from "react";
import { useScreenSizeStore } from "stores/screenSize";

type Sizes = "small" | "intro" | "normal";

type Props = {
  bold?: boolean;
  type?: Sizes;
  className?: string;
  children?: React.ReactNode;
};

const Paragraph: React.FC<Props> = ({
  children,
  className,
  bold = false,
  type = "normal",
}) => {
  const { screenIs } = useScreenSizeStore();

  const getFontSize = useCallback(() => {
    switch (type) {
      case "small":
        return screenIs({ default: "14px", tablet: "14px", mobile: "12px" });
      case "intro":
        return screenIs({ default: "24px", tablet: "18px", mobile: "16px" });
      case "normal":
      default:
        return screenIs({ default: "18px", tablet: "16px", mobile: "14px" });
    }
  }, [screenIs, type]);

  const getLineHeight = useCallback(() => {
    switch (type) {
      case "small":
        return screenIs({ default: "20px", mobile: "16px" });
      case "intro":
        return screenIs({ default: "40px", tablet: "30px", mobile: "26px" });
      case "normal":
      default:
        return screenIs({ default: "30px", tablet: "24px", mobile: "24px" });
    }
  }, [screenIs, type]);

  return (
    <p
      css={{
        fontWeight: bold ? 700 : 400,
        fontSize: getFontSize(),
        lineHeight: getLineHeight(),
        marginTop: 0,
        marginBottom: 0,
      }}
      className={className}
    >
      {children}
    </p>
  );
};

export default Paragraph;
