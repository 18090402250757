import { AllIconNames } from "icons";

export type Alternative = {
  property: "description" | "image" | "title";
  value: string;
  id: string;
};
export type Contact = {
  id: string;
  email: string;
  firstName: string;
  lastName: string;
  phone: string;
  externalId: string;
  location: string;
  role: string;
};

export type Service = {
  description: string;
  id: string;
  title: string;
  alternatives?: Alternative[];
  externalId?: string;
  images?: string[];
};

export type Equipment = Service & {
  alternative?: Alternative[];
  images?: string[];
};
export type Coords = {
  lat: number;
  long: number;
};
export type Facility = {
  id: string;
  title: string;
  location: string;
  services: Service[];
  equipment: Equipment[];
  coords?: Coords;
  contacts?: Contact[];
  description?: string;
  externalId?: string;
  institutionId?: string;
  institutionName?: string;
};

export type FacilityResponse = Exclude<
  Facility,
  "institutionId" | "institutionName"
>;

export type Institution = {
  id: string;
  name: string;
  location?: string;
  contact?: Contact;
  facilities?: Facility[];
};
export type InstitutionDetail = Pick<Institution, "name" | "location" | "id">;

export type FacilityCapabilitiesType = "Service" | "Equipment";

export type SearchResultResponseFacility = Exclude<
  Facility,
  "services" | "equipment" | "contacts"
>;

export type SearchResultFacilityService = Pick<Service, "id" | "title">;
export type SearchResultFacilityEquipment = Pick<Equipment, "id" | "title">;

export type SearchResultFacilityCapability = {
  services: SearchResultFacilityService[];
  equipment: SearchResultFacilityEquipment[];
};

export type SearchResultResponse = {
  facility: SearchResultResponseFacility;
  score: number;
  institution: InstitutionDetail;
  mismatches: SearchResultFacilityCapability;
  matches: SearchResultFacilityCapability;
  program: Program;
  programType: ProgramType;
  relationshipType: string;
};

export type AllFacilitiesResponse = {
  facility: Pick<Facility, "id" | "title" | "location">;
  institution: Pick<Institution, "id" | "name" | "location">;
};

export type Summary = {
  id: string;
  name: string;
  facilitiesCount: number;
  equipmentCount: number;
  servicesCount: number;
};

export type OrganisationTypes = `PRIVATE` | `ACADEMIC` | `COMMERCIAL`;

export type ProgramDescription = {
  title: string;
  body: string;
  tags: Tag[];
};

export type Program = {
  id: string;
  externalId: string;
  name: string;
  logo: string;
  summary: string;
  banner: string;
  keyContacts: ProgramContact[];
	moreInfo: [MoreInfoItem];
  attributionTitle: string;
  attributionBody: string;
  description: ProgramDescription[];
};

export type ProgramContact = {
  name: string;
  role: string;
  address: string;
  phone: string;
  email: string;
  url: string;
};

export type MoreInfoItem = {
  name: string;
	address: string;
	phone: string;
	email: string;
	url: string;
};

export type ProgramType = {
  id: string;
  externalId: string;
  name: string;
  logo: string;
  summary: string;
  programs?: [Program];
};

export type ProgramMeta = {
  program: Program;
  programType: ProgramType;
  facilities?: SearchResultResponse[];
};

export type SearchTerms = {
  term: string;
  results: number;
};

export type Tag = {
  label: string;
};

export const validEnvironments = [
  "development",
  "stage",
  "uat",
  "test",
  "production",
] as const;

export type Environment = typeof validEnvironments[number];

export type NavigationLink = {
  icon: AllIconNames;
  linkTo: string;
  linkText: string;
  label: string;
};

export type GeoLocation = { latitude: number; longitude: number };

export type AutoLocality = {
  name: string;
  score: number;
  coordinates: {
    lat: number;
    long: number;
  };
};

export type MoreInfoFieldName = "address" | "phone" | "email" | "url";