/** @jsxImportSource @emotion/react */
import { jsx } from "@emotion/react";
import React from "react";
import { addFlex, addMargin } from "../../utils";
import HyperLink from "../HyperLink";
import { MoreInfoFieldName } from "../../utils/types";

type Props = {
  icon: string;
  content: string;
  type?: MoreInfoFieldName;
  bold?: boolean;
};

export function renderByType(content: string, type?: MoreInfoFieldName) {
  switch (type) {
    case "email":
      return (
        <HyperLink label={"send email"} mailTo={content}>
          {content}
        </HyperLink>
      );
    case "phone":
      return (
        <HyperLink label={"call"} phoneTo={content}>
          {content}
        </HyperLink>
      );
    case "url":
      return (
        <HyperLink label={"visit website"} linkTo={content}>
          {content.replace(/^https?:\/\//, "")}
        </HyperLink>
      );
    default:
      return content;
  }
}

export const IconRow: React.FC<Props> = ({ icon, content, type, bold }) => {
  return content ? (
    <div
      css={{
        display: "flex",
        flexDirection: "row",
      }}
    >
      <div
        css={{
          ...addFlex({ x: "left", y: "top" }),
          width: "30px",
        }}
      >
        <img src={icon} />
      </div>
      <div
        css={{
          ...addFlex({ x: "right", y: "top" }),
          fontSize: "14px",
          fontWeight: bold ? "bold" : "normal",
          lineHeight: "18px",
          ...addMargin({ bottom: "10px " }),
        }}
      >
        {renderByType(content, type)}
      </div>
    </div>
  ) : null;
};

export default IconRow;
