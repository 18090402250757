/** @jsxImportSource @emotion/react */
import { jsx, css } from "@emotion/react";

import React from "react";

type Props = {
  color: string;
  css: ReturnType<typeof css>;
};

const CheckInCircleIcon: React.FC<Props> = ({ color = "#3D3D3D", css }) => {
  return (
    <svg
      viewBox="0 0 16 16"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      css={css}
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M7.72166 0C6.65619 0 5.65358.203515 4.7138.61055c-.93977.40703-1.75682.95772-2.45117 1.65208-.69436.69435-1.24504 1.5114-1.65208 2.45117C.203515 5.65358 0 6.65619 0 7.72166c0 1.06548.203515 2.06809.61055 3.00784.40704.9398.95772 1.7598 1.65208 2.4602.69435.7003 1.5114 1.251 2.45117 1.6521.93978.401 1.94239.6015 3.00786.6015 1.06548 0 2.06809-.2005 3.00784-.6015.9398-.4011 1.7568-.9518 2.4512-1.6521.6944-.7004 1.245-1.5204 1.6521-2.4602.407-.93975.6105-1.94236.6105-3.00784 0-1.06547-.2035-2.06808-.6105-3.00786-.4071-.93977-.9577-1.75682-1.6521-2.45117-.6944-.69436-1.5114-1.24505-2.4512-1.65208C9.78975.203515 8.78714 0 7.72166 0zm4.39774 10.5411l-1.5802 1.5803-2.81934-2.80138-2.8193 2.80138-1.58025-1.5803 2.81931-2.81928-2.81931-2.81931 1.58025-1.58024 2.8193 2.8193 2.81934-2.8193 1.5802 1.58024-2.81929 2.81931 2.81929 2.81928z"
        fill={color}
      />
    </svg>
  );
};

export default CheckInCircleIcon;
