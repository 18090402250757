/** @jsxImportSource @emotion/react */
import { jsx, css } from "@emotion/react";

import React from "react";

type Props = {
  color: string;
  secondColor: string;
  css: ReturnType<typeof css>;
};

const LoadingCircle: React.FC<Props> = ({
  color = "#228192",
  secondColor = "#D5D5D5",
  css,
}) => {
  return (
    <svg
      viewBox="0 0 100 100"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      css={css}
    >
      <circle cx="50" cy="50" r="45" stroke={secondColor} strokeWidth="10" />
      <path
        d="M50 5a45 45 0 0127.81 80.37"
        stroke={color}
        strokeWidth="10"
        strokeLinecap="round"
      />
    </svg>
  );
};

export default LoadingCircle;
