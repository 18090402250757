/** @jsxImportSource @emotion/react */
import { jsx, css } from "@emotion/react";

import React from "react";

type Props = {
  color: string;
  css: ReturnType<typeof css>;
};

const LocationPin: React.FC<Props> = ({ color = "#3D3D3D", css }) => {
  return (
    <svg
      viewBox="0 0 16 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      css={css}
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M7.99972 11.3805c.78747 0 1.45861-.2911 2.01338-.8732.5549-.58218.8323-1.2864.8323-2.11269 0-.8263-.2774-1.53052-.8323-2.11268-.55477-.58216-1.22591-.87324-2.01338-.87324-.78748 0-1.45861.29108-2.01342.87324-.55482.58216-.83222 1.28638-.83222 2.11268 0 .82629.2774 1.53051.83222 2.11269.55481.5821 1.22594.8732 2.01342.8732zM8 0c2.2193 0 4.1074.816892 5.6644 2.45071C15.2215 4.08452 16 6.06571 16 8.39437c0 1.16432-.2774 2.49763-.8322 4.00003-.5548 1.5023-1.226 2.9108-2.0134 4.2253-.7875 1.3146-1.566 2.5446-2.3356 3.6902-.7696 1.1455-1.42282 2.0563-1.95974 2.7324L8 24c-.21477-.2629-.50112-.6103-.85906-1.0423-.35794-.4319-1.00223-1.2957-1.93289-2.5915-.93065-1.2958-1.74496-2.554-2.44295-3.7747-.69799-1.2206-1.33333-2.6009-1.90604-4.1408C.286351 10.9108 0 9.55869 0 8.39437c0-2.32866.778516-4.30985 2.33557-5.94366C3.89263.816892 5.78075 0 8 0z"
        fill={color}
      />
    </svg>
  );
};

export default LocationPin;
