import { gql } from "apollo-boost";

import Api from "services/api";
import { SearchResultResponse, ProgramMeta } from "utils/types";
import { escapeQuotesAndBacklashes } from "utils/formatters";

type SearchResult = {
  facilities: {
    results: SearchResultResponse[];
    programMeta: ProgramMeta;
  };
};

const LIMIT_SEARCH_RESULTS = 50;

const getSearchResult = (search: string) =>
  Api.query<SearchResult>({
    query: gql`
      {
        facilities(search: "${escapeQuotesAndBacklashes(
          search
        )}", limit: ${LIMIT_SEARCH_RESULTS}){
          results {
            facility {
              description
              location
              coords {
                lat
                long
              }
              id
              title
            }
            institution {
              name
              location
              id
            }
            matches {
              services {
                id
                title
              }
              equipment {
                id
                title
              }
            }
            mismatches {
              services {
                id
                title
              }
              equipment {
                id
                title
              }
            }
            score
            program {
              id
              name
              logo
              banner
              summary
              description {
                title
                body
              }
            }
            programType {
              id
              name
              logo
            }
            relationshipType
          }
          total
          programMeta {
            program {
              id
              name
              logo
              summary
              description {
                title
                body
              }
            }
            programType {
              id
              name
              summary
              logo
            }
          }
        }

      }
    `,
  }).then((result) => result);

export default getSearchResult;
