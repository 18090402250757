/** @jsxImportSource @emotion/react */
import { jsx, css } from "@emotion/react";

import React from "react";

type Props = {
  color: string;
  css: ReturnType<typeof css>;
};

const WarningIcon: React.FC<Props> = ({ color = "#3D3D3D", css }) => {
  return (
    <svg
      viewBox="0 0 29 29"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      css={css}
    >
      <path
        d="M4.23 26.58c-.6 0-1.2-.12-1.81-.48a3.7 3.7 0 01-1.33-4.95L11.36 3.99c.36-.49.72-.97 1.2-1.21a3.7 3.7 0 012.79-.36 4 4 0 012.3 1.69l10.14 17.04c.36.6.48 1.2.48 1.8a3.3 3.3 0 01-1.08 2.55 3.04 3.04 0 01-2.42 1.08H4.23zM13.4 5.32L3.26 22.35c-.36.6-.12 1.33.49 1.7.12.12.36.12.48.12h20.42c.36 0 .6-.12.85-.37.24-.24.36-.48.36-.84 0-.24 0-.36-.12-.6L15.59 5.31c-.37-.6-1.09-.73-1.7-.49a.94.94 0 00-.48.49z"
        fill={color}
      />
      <path
        d="M14.5 16.92c-.73 0-1.2-.49-1.2-1.21v-4.83c0-.73.47-1.21 1.2-1.21.72 0 1.2.48 1.2 1.2v4.84c0 .72-.48 1.2-1.2 1.2zM14.5 21.75c-.36 0-.6-.12-.85-.36a1.1 1.1 0 01-.36-.85c0-.12 0-.36.12-.48s.12-.24.24-.36.25-.25.37-.25c.24-.12.48-.12.72-.12.12 0 .12 0 .24.12.12 0 .12 0 .24.13 0 0 .13.12.25.12.12.12.24.24.24.36s.12.36.12.48c0 .36-.12.6-.36.85-.37.24-.6.36-.97.36z"
        fill={color}
      />
    </svg>
  );
};

export default WarningIcon;
