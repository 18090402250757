/** @jsxImportSource @emotion/react */
import { jsx, css } from "@emotion/react";

import React from "react";

type Props = {
  color: string;
  css: ReturnType<typeof css>;
};

const AimIcon: React.FC<Props> = ({ color = "#3D3D3D", css }) => {
  return (
    <svg
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      css={css}
      viewBox="0 0 34 34"
    >
      <path
        fill={color}
        fillRule="evenodd"
        clipRule="evenodd"
        d="M15.584 5.667c-5.477 0-9.917 4.44-9.917 9.916 0 5.477 4.44 9.917 9.917 9.917 5.476 0 9.916-4.44 9.916-9.917 0-5.476-4.44-9.916-9.916-9.916zm-12.75 9.916c0-7.041 5.708-12.75 12.75-12.75 7.041 0 12.75 5.709 12.75 12.75 0 7.042-5.709 12.75-12.75 12.75-7.042 0-12.75-5.708-12.75-12.75z"
      />
      <path
        fill={color}
        fillRule="evenodd"
        clipRule="evenodd"
        d="M22.586 22.586a1.417 1.417 0 012.003 0l6.163 6.162a1.417 1.417 0 01-2.004 2.004l-6.162-6.163a1.417 1.417 0 010-2.003z"
      />
    </svg>
  );
};

export default AimIcon;
