/** @jsxImportSource @emotion/react */
import { jsx, css } from "@emotion/react";
import React, { Fragment } from "react";
import { useScreenSizeStore } from "stores/screenSize";
import { addFlex, addMargin, addPadding } from "utils";
import Paragraph from "components/Paragraph";
import HyperLink from "components/HyperLink";

type LearnMoreLink = {
  linkText: string;
  href: string;
  label: string;
  type: "hyperLink" | "mailTo";
};

type Props = {
  isNew: boolean;
  image: string;
  headline: string;
  text: string;
  learnMore: LearnMoreLink;
};

const HomepageBanner: React.FC<Props> = ({
  isNew,
  image,
  headline,
  text,
  learnMore,
}) => {
  const { screenIs, isDesktop } = useScreenSizeStore();

  const LEARN_MORE_LINK_STYLES = isDesktop
    ? css({
        position: "absolute",
        bottom: "40px",
        fontWeight: "bold",
        lineHeight: "30px",
        fontSize: "18px",
      })
    : css({
        fontWeight: "bold",
      });

  return (
    <Fragment>
      <div
        css={{
          width: "100%",
          position: "relative",
          backgroundColor: "#EEF4F6",
          borderRadius: "8px",
          height: screenIs({
            desktop: "auto",
            tablet: "auto",
            mobile: "auto",
          }),
          flexDirection: screenIs({
            default: "row",
            tablet: "column",
            mobile: "column",
          }),
          ...addFlex(
            screenIs({
              default: { x: "center", y: "top" },
              mobile: { x: "left", y: "top", direction: "down" },
            })
          ),
          ...addMargin({
            top: screenIs({
              default: "70px",
              tablet: "46px",
              mobile: "34px",
            }),
            bottom: screenIs({ default: "100px", mobile: "40px" }),
          }),
          ...addPadding({
            top: screenIs({
              default: "50px",
              tablet: "36px",
              mobile: "24px",
            }),
            bottom: screenIs({ default: "80px", mobile: "20px" }),
          }),
        }}
      >
        <div
          css={{
            ...addFlex({ x: "left", y: "top", direction: "down" }),
            margin: screenIs({
              default: "0 100px 0 50px",
              tablet: "0 50px 0 25px",
              mobile: "0",
            }),
            padding: screenIs({
              default: "0px",
              tablet: "30px",
              mobile: "30px",
            }),
            width: "100%",
          }}
        >
          <span
            css={{
              display: isNew ? "block" : "none",
              ...addMargin({
                vertical: "5px",
                bottom: "15px",
              }),
              ...addPadding({
                vertical: "5px",
                horizontal: "15px",
              }),
              fontWeight: "bold",
              fontSize: "12px",
              lineHeight: "15px",
              color: "white",
              background: "#228192",
              borderRadius: "100px",
              whiteSpace: "nowrap",
            }}
          >
            NEW!
          </span>
          <Paragraph
            bold
            css={{
              fontSize: "24px",
              marginBottom: screenIs({ default: "12px", mobile: "10px" }),
              marginTop: "0",
              color: "#2F5673",
            }}
          >
            {headline}
          </Paragraph>
          <Paragraph
            css={{
              marginBottom: 0,
              width: "100%",
              height: "auto",
              lineHeight: "30px",
              fontSize: "18px",
            }}
          >
            {text}
          </Paragraph>
          {learnMore.type === "mailTo" ? (
            <HyperLink
              label={learnMore.label}
              mailTo={learnMore.href}
              css={LEARN_MORE_LINK_STYLES}
              fontSize={screenIs({ default: "18px", mobile: "14px" })}
            >
              {learnMore.linkText}
            </HyperLink>
          ) : (
            <HyperLink
              label={learnMore.label}
              linkTo={learnMore.href}
              css={LEARN_MORE_LINK_STYLES}
              fontSize={screenIs({ default: "18px", mobile: "14px" })}
            >
              {learnMore.linkText}
            </HyperLink>
          )}
        </div>
        <div
          css={{
            ...addFlex(
              screenIs({
                default: { x: "right", y: "center", direction: "down" },
                mobile: { x: "center", y: "center", direction: "down" },
              })
            ),
            width: screenIs({
              default: "270px",
              mobile: "100%",
            }),
            margin: "auto 0",
          }}
        >
          <img
            src={image}
            css={{
              ...addFlex({ x: "center", y: "center" }),
              margin: screenIs({
                default: "0 50px 0 0",
                mobile: "0",
              }),
              padding: screenIs({
                default: "0",
                mobile: "0 30px",
              }),
              width: "100%",
            }}
            alt={``}
          />
        </div>
      </div>
    </Fragment>
  );
};

export default HomepageBanner;
