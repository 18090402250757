/** @jsxImportSource @emotion/react */
import { jsx, css } from "@emotion/react";
import React from "react";
import { useScreenSizeStore } from "stores";
import { addFlex, addPadding } from "utils";

type Props = {};

const AdminReportContent: React.FC<Props> = () => {
  const { screenIs, isLandscapeTablet, isMobile } = useScreenSizeStore();
  return (
    <div
      css={{
        label: "admin-report-title",
        ...addFlex({ x: "center", y: "center" }),
        ...addPadding(
          screenIs({
            default: { horizontal: "75px", vertical: "45px" },
            mobile: { horizontal: "15px", vertical: "20px" },
          })
        ),
        color: "#2F5673",
        width: "100%",
      }}
      data-automation={`staticContentHeadline`}
    >
      <h1>Admin Report</h1>
    </div>
  );
};

export default AdminReportContent;
