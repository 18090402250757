/** @jsxImportSource @emotion/react */
import { jsx } from "@emotion/react";
import React from "react";

import FooterLink from "./FooterLink";
import VicStateLogo from "icons/imageIcons/VicStateLogo";
import { useScreenSizeStore } from "stores";
import { addFlex, addPadding, addSize, addMargin } from "utils";
import BasicButton from "components/BasicButton";
import { useTheme } from "@emotion/react";

type Props = {};

const Footer: React.FC<Props> = React.memo(() => {
  const { screenIs, isMobile } = useScreenSizeStore();
  const theme = useTheme();

  const ArinFooter = () => (
    <footer
      className="site-footer-section"
      css={{
        label: "Footer",
        ...addFlex({
          x: screenIs({ default: "left", mobile: "center" }),
          y: "center",
        }),
        ...addPadding({
          vertical: screenIs({ default: "30px", mobile: "10px" }),
        }),
        ...addMargin({
          horizontal: screenIs({ default: "0", mobile: "15px" }),
        }),
        justifyContent: "space-between",
        width: screenIs({ default: "100%", mobile: "calc(100% - 30px)" }),
        borderTop: screenIs({
          default: "1px solid #D5D5D5",
          tablet: "1px solid #D5D5D5",
        }),
        flexDirection: "row",
        display: "grid",
        gridTemplateColumns: "1fr 1fr",
      }}
    >
      <BasicButton
        label="Vic Gov Website"
        linkTo="https://www.vic.gov.au/"
        target="_blank"
      >
        <VicStateLogo />
      </BasicButton>
      <div
        css={{
          display: "flex",
          margin: isMobile ? "5px 0" : "",
          justifyContent: "center",
        }}
      >
        <FooterLink text="Your privacy is important." linkTo="/privacy" />
        <div
          css={{
            ...addSize({ size: isMobile ? "3px" : "4px" }),
            borderRadius: "8px",
            margin: isMobile ? "20px 2px" : "20px 11px",
            background: theme.footerAccent,
          }}
        />
        <FooterLink text="Cookies" linkTo="/cookies" />
      </div>
    </footer>
  );

  const MonashUniFooter = () => (
    <footer
      css={{
        label: "Footer",
        ...addFlex({
          x: screenIs({ default: "left", mobile: "center" }),
          y: "center",
        }),
        ...addPadding({
          vertical: screenIs({ default: "30px", mobile: "10px" }),
        }),
        ...addMargin({
          horizontal: screenIs({ default: "0", mobile: "15px" }),
        }),
        justifyContent: "space-between",
        width: screenIs({ default: "100%", mobile: "calc(100% - 30px)" }),
        borderTop: screenIs({
          default: "1px solid #D5D5D5",
          tablet: "1px solid #D5D5D5",
        }),
        flexDirection: "row",
      }}
    >
      <div
        css={{
          display: "flex",
          margin: isMobile ? "5px 0" : "",
        }}
      >
        <FooterLink text="Terms of Service" linkTo="/terms-of-service" />
        <div
          css={{
            ...addSize({ size: isMobile ? "3px" : "4px" }),
            borderRadius: "8px",
            margin: isMobile ? "20px 2px" : "20px 11px",
            background: theme.footerAccent,
          }}
        />
        <FooterLink text="Privacy Policy" linkTo="/privacy" />
      </div>
      <p>Copyright © 2021 Monash University</p>
    </footer>
  );

  const SingascopeFooter = () => (
    <footer
      css={{
        label: "Footer",
        ...addFlex({
          x: screenIs({ default: "left", mobile: "center" }),
          y: "center",
        }),
        ...addPadding({
          vertical: screenIs({ default: "30px", mobile: "10px" }),
        }),
        ...addMargin({
          horizontal: screenIs({ default: "0", mobile: "15px" }),
        }),
        justifyContent: "space-between",
        width: screenIs({ default: "100%", mobile: "calc(100% - 30px)" }),
        borderTop: screenIs({
          default: "1px solid #D5D5D5",
          tablet: "1px solid #D5D5D5",
        }),
        flexDirection: "row",
      }}
    >
      <div
        css={{
          display: "flex",
          margin: isMobile ? "5px 0" : "",
        }}
      >
        <FooterLink
          text="Terms of Service"
          linkTo="https://www.singascope.sg/terms-of-use"
        />
        <div
          css={{
            ...addSize({ size: isMobile ? "3px" : "4px" }),
            borderRadius: "8px",
            margin: isMobile ? "20px 2px" : "20px 11px",
            background: theme.footerAccent,
          }}
        />
        <FooterLink
          text="Privacy Policy"
          linkTo="https://www.singascope.sg/privacy-policy"
        />
      </div>
      <div css={{ fontSize: 14, fontWeight: 600 }}>
        {new Date().getFullYear()} Singascope. All rights reserved
      </div>
    </footer>
  );

  const tenant = process.env.REACT_APP_TENANT;
  switch (tenant) {
    case "arin":
      return ArinFooter();
    case "singascope":
      return SingascopeFooter();
    case "monashUni":
      return MonashUniFooter();
    default:
      return ArinFooter();
  }
});

export default Footer;
