/** @jsxImportSource @emotion/react */
import { jsx, css } from "@emotion/react";

import React from "react";

type Props = {
  color: string;
  css: ReturnType<typeof css>;
};

const SelectArrow: React.FC<Props> = ({ color = "#3D3D3D", css }) => {
  return (
    <svg
      width="8"
      height="14"
      viewBox="0 0 8 14"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      css={css}
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M0.619926 5C0.452644 5 0.307503 4.93513 0.184502 4.80539C0.0614998 4.67565 0 4.53094 0 4.37126C0 4.21158 0.0639596 4.06687 0.191882 3.93713L4 0L7.82288 3.93713C7.94096 4.05689 8 4.2016 8 4.37126C8 4.54092 7.9385 4.68812 7.8155 4.81287C7.6925 4.93763 7.54736 5 7.38007 5H0.619926Z"
        fill={color}
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M7.38007 9C7.54736 9 7.6925 9.06487 7.8155 9.19461C7.9385 9.32435 8 9.46906 8 9.62874C8 9.78842 7.93604 9.93313 7.80812 10.0629L4 14L0.177122 10.0629C0.05904 9.94311 0 9.7984 0 9.62874C0 9.45908 0.0615 9.31188 0.184502 9.18713C0.307504 9.06238 0.452644 9 0.619926 9H7.38007Z"
        fill={color}
      />
    </svg>
  );
};

export default SelectArrow;
