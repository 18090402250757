/** @jsxImportSource @emotion/react */
import { jsx, css } from "@emotion/react";

import React from "react";

type Props = {
  color: string;
  css: ReturnType<typeof css>;
};

const MailIcon: React.FC<Props> = ({ color = "#3D3D3D", css }) => {
  return (
    <svg
      viewBox="0 0 24 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      css={css}
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M21.6338 5.02931V2.51469L12 8.772 2.36621 2.51469v2.51462L12 11.2281l9.6338-6.19879zm0-5.02931c.6385 0 1.1925.25341 1.662.760234C23.7653 1.26706 24 1.85185 24 2.51462V17.4854c0 .6628-.2347 1.2475-.7042 1.7544-.4695.5068-1.0235.7602-1.662.7602H2.3662c-.6385 0-1.19249-.2534-1.661975-.7602C.234739 18.7329 0 18.1482 0 17.4854V2.51462C0 1.85185.234739 1.26706.704225.760234 1.17371.25341 1.7277 0 2.3662 0h19.2676z"
        fill={color}
      />
    </svg>
  );
};

export default MailIcon;
