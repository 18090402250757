import { gql } from "apollo-boost";
import Api from "services/api";
import { Summary } from "utils/types";

type GetSummaryResultResponse = {
  getSummary: Summary[];
};

const getSummary = () =>
  Api.query<GetSummaryResultResponse>({
    query: gql`
      {
        getSummary {
          id
          name
          facilitiesCount
          equipmentCount
          servicesCount
        }
      }
    `,
  }).then((result) => result.data.getSummary);

export default getSummary;
