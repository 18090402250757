/** @jsxImportSource @emotion/react */
import { jsx } from "@emotion/react";
import BasicButton from "../BasicButton";
import { addFlex, addSize } from "utils";
import { useScreenSizeStore } from "stores";
import { useTheme } from "@emotion/react";

type Props = { text: string; linkTo: string };

const FooterLink: React.FC<Props> = ({ text, linkTo }) => {
  const { isMobile } = useScreenSizeStore();
  const theme = useTheme();
  return (
    <BasicButton
      css={{
        position: "relative",
        whiteSpace: "nowrap",
        borderRadius: "5px",
        flexShrink: 0,
      }}
      innerCss={{
        ...addFlex({ x: "center", y: "center" }),
        ...addSize({ size: "100%" }),
        position: "relative",
        padding: "5px",
        fontWeight: "bold",
        cursor: "pointer",
        fontSize: isMobile ? "12px" : "14px",
        transition: "color 0.15s ease-in-out",
        textDecoration: "underline",
        color: theme.mainColor,
        ":hover": {
          color: theme.searchBarHover,
        },
      }}
      linkTo={linkTo}
      label={text}
    >
      {text}
    </BasicButton>
  );
};

export default FooterLink;
