import { gql } from "apollo-boost";
import Api from "services/api";
import { AutoLocality } from "utils/types";

type GetAutoLocalityResult = {
  autoLocality: AutoLocality[];
};

const autoLocality = (searchTerm: string) =>
  Api.query<GetAutoLocalityResult>({
    query: gql`
      {
        autoLocality(input: "${searchTerm}") {
          name,
          score,
          coordinates {
            lat,
            long,
          }
        }
      }
    `,
  }).then((result) => result.data.autoLocality);

export default autoLocality;
