/** @jsxImportSource @emotion/react */
import { jsx } from "@emotion/react";
import React, { Fragment } from "react";

import { addSize, addFlex } from "utils";
import { useScreenSizeStore } from "stores";
import Column from "./Column";
import ColumnDivider from "./ColumnDivider";
import { FacilityLinkListItem } from "..";
import { FacilityCapabilitiesType } from "utils/types";
import { splitItemsToColumns } from "./splitItemsToColumns";
import AutoHeightAnimator from "components/AutoHeightAnimator";

type Props = {
  linkItems: FacilityLinkListItem[];
  type: FacilityCapabilitiesType;
  isOpen: boolean;
};

const StackedItems: React.FC<Props> = React.memo(
  ({ linkItems, type, isOpen }) => {
    const { screenIs, isMobile } = useScreenSizeStore();
    const [leftItems, rightItems] = splitItemsToColumns(linkItems);

    return (
      <AutoHeightAnimator faster manualUpdateKey={isOpen ? "open" : "closed"}>
        <div
          css={{
            ...addSize({ width: "100%" }),
            ...addFlex({ x: "edges", y: "top", direction: "right" }),
            alignItems: "stretch",
            position: "relative",
            overflow: isOpen ? "auto" : "hidden",
          }}
        >
          <Column
            linkItems={screenIs({
              default: leftItems,
              mobile: [...leftItems, ...rightItems],
            })}
            type={type}
          />
          {rightItems.length > 0 && !isMobile && (
            <Fragment>
              <ColumnDivider />
              <Column linkItems={rightItems} type={type} />
            </Fragment>
          )}
        </div>
      </AutoHeightAnimator>
    );
  }
);

export default StackedItems;
