import development from "./development";
import production from "./production";
import stage from "./stage";
import { validEnvironments, Environment } from "utils/types";

type ApiConfiguration = {
  uri: string;
  apiKey: string;
  tenantId: string;
};

type FireBaseConfig = {
  apiKey: string;
  authDomain: string;
  projectId: string;
  appId: string;
  measurementId: string;
};

export type Configuration = {
  api: ApiConfiguration;
  fireBaseConfig: FireBaseConfig;
  shouldCollectAnalyticsData: boolean;
  reCaptchaKey: string;
};

function getSetEnvironment(): string {
  const { REACT_APP_ENV, NODE_ENV } = process.env;
  return REACT_APP_ENV || NODE_ENV;
}

export function getValidEnvironment(): Environment {
  const environment = getSetEnvironment();
  if (!validEnvironments.includes(environment as Environment))
    throw new Error(
      `${typeof environment} ${environment} is not a valid environment`
    );
  return environment as Environment;
}

export default function getConfiguration(): Configuration {
  const environment = getValidEnvironment();
  switch (environment) {
    case "development":
      return development;
    case "stage":
      return stage;
    case "production":
      return production;
    default:
      console.warn(
        `No config for environment : ${environment} returning production`
      );
      return production;
  }
}
