/** @jsxImportSource @emotion/react */
import { jsx, css } from "@emotion/react";

import React from "react";

type Props = {
  color: string;
  css: ReturnType<typeof css>;
};

const AimIcon: React.FC<Props> = ({ color = "#3D3D3D", css }) => {
  return (
    <svg
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      css={css}
    >
      <path
        d="M12 7.875C9.70312 7.875 7.875 9.75 7.875 12c0 2.2969 1.82812 4.125 4.125 4.125 2.25 0 4.125-1.8281 4.125-4.125 0-2.25-1.875-4.125-4.125-4.125zm0 6c-1.0781 0-1.875-.7969-1.875-1.875 0-1.0312.7969-1.875 1.875-1.875 1.0312 0 1.875.8438 1.875 1.875 0 1.0781-.8438 1.875-1.875 1.875zm11.25-3h-2.3438c-.5156-4.03125-3.75-7.26562-7.7812-7.78125V.75c0-.375-.375-.75-.75-.75h-.75c-.4219 0-.75.375-.75.75v2.34375c-4.07812.51563-7.3125 3.75-7.82812 7.78125H.75c-.421875 0-.75.375-.75.75v.75c0 .4219.328125.75.75.75h2.29688c.51562 4.0781 3.75 7.3125 7.82812 7.8281V23.25c0 .4219.3281.75.75.75h.75c.375 0 .75-.3281.75-.75v-2.2969c4.0312-.5156 7.2656-3.75 7.7812-7.8281H23.25c.375 0 .75-.3281.75-.75v-.75c0-.375-.375-.75-.75-.75zM12 18.75c-3.75 0-6.75-3-6.75-6.75 0-3.70312 3-6.75 6.75-6.75 3.7031 0 6.75 3.04688 6.75 6.75 0 3.75-3.0469 6.75-6.75 6.75z"
        fill={color}
      />
    </svg>
  );
};

export default AimIcon;
