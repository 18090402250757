import React from "react";

type Props = {};

const ArinLogoIcon: React.FC<Props> = () => {
  return (
    <svg viewBox="0 0 55 21" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M15.52 21h5.45L13.62 1.55h-6.3L0 21h5.45l1-2.95h8.07l1 2.95zM10.47 5.92l2.74 7.9H7.76l2.71-7.9zM22.29 21h4.49v-8.93c.58-.81 2.13-1.3 3.3-1.3.46 0 .84.05 1.1.14V6.56c-1.69 0-3.47 1-4.4 2.13V6.91h-4.5V21zM50.5 21H55v-9.98c0-2.6-1.46-4.46-4.67-4.46a6.37 6.37 0 00-4.87 2.07V6.91h-4.49V21h4.5v-9.1a3.48 3.48 0 012.73-1.37c1.4 0 2.3.61 2.3 2.33V21z"
        fill="#3D3D3D"
      />
      <path
        d="M35.47 5.13a2.55 2.55 0 002.57-2.56A2.55 2.55 0 0035.47 0a2.55 2.55 0 00-2.57 2.57 2.55 2.55 0 002.57 2.56zM33.23 21h4.49V6.9h-4.5V21z"
        fill="#228192"
      />
    </svg>
  );
};

export default ArinLogoIcon;
