import { gql } from "apollo-boost";
import Api from "services/api";

type SubmitFeedbackResponse = {
  submitFeedback: null;
};

export type SubmitFeedbackInputType = {
  captcha: string;
  page: string;
  reason: string;
  comment: string;
  location: string;
  searchTerm: string;
  capabilities: string;
};

const submitFeedback = ({
  captcha,
  page,
  reason,
  comment,
  location,
  searchTerm,
  capabilities,
}: SubmitFeedbackInputType) =>
  Api.mutate<SubmitFeedbackResponse>({
    variables: {
      input: {
        page,
        reason,
        comment,
        location,
        searchTerm,
        capabilities,
      },
      captcha,
    },
    mutation: gql`
      mutation submitFeedback($input: InputFeedback!, $captcha: String!) {
        submitFeedback(input: $input, captcha: $captcha) {
          comment
        }
      }
    `,
  }).then((result) => result.data);

export default submitFeedback;
