/** @jsxImportSource @emotion/react */
import { jsx } from "@emotion/react";
import React from "react";
import { addFlex, addPadding } from "utils";

import { SendableFormProps } from "stores/utils/storeMakers/makeModalStore/types";

import SearchTermToTagFrom from "./formContents/SearchTermToTagFrom";

import SendFeedbackForm from "./formContents/SendFeedbackForm";
import SubmitDescriptionForm from "./formContents/SubmitDescriptionForm";
import SubmitEnquiryForm from "./formContents/SubmitEnquiryForm";
import SubmitImageForm from "./formContents/SubmitImageForm";
import SubmitTitle from "./formContents/SubmitTitle";
import BasicButton from "components/BasicButton";
import BasicIcon from "components/BasicIcon";

// Uses modal props to choose which form content to show

type Props = SendableFormProps & {
  closeModal: () => void;
  children?: React.ReactNode;
};

const formContentByName = {
  sendFeedback: SendFeedbackForm,
  searchTermToTag: SearchTermToTagFrom,
  submitEnquiry: SubmitEnquiryForm,
  submitImage: SubmitImageForm,
  submitDescription: SubmitDescriptionForm,
  submitTitle: SubmitTitle,
};

const SendableForm: React.FC<Props> = ({
  formName,
  feedBackType,
  feedBackTypeText,
  nextAction,
  children,
  closeModal,
}) => {
  return (
    <div
      css={{
        ...addFlex({ x: "left", y: "center" }),
        width: "100%",
        flexShrink: 0, // stops this modal content from shrinking in safari
        borderRadius: "10px",
      }}
    >
      <BasicButton
        label="close modal"
        onClick={() => {
          closeModal();
        }}
        css={{
          position: "absolute",
          top: "15px",
          right: "15px",
          borderRadius: "50px",
          ...addPadding({ default: "10px" }),
          perspective: 10000000, // fixes borderRadius sometimes not showing for safari
        }}
      >
        <BasicIcon
          name="Close"
          color="#949494"
          //size={screenIs({ default: 21, desktop: 36 })}
          size={28}
        />
      </BasicButton>
      {React.createElement(formContentByName[formName], {
        feedBackType,
        feedBackTypeText,
        nextAction,
      })}
    </div>
  );
};

export default SendableForm;
