/** @jsxImportSource @emotion/react */
import { jsx, css } from "@emotion/react";

import React from "react";

type Props = {
  color: string;
  css: ReturnType<typeof css>;
};

const CheckIcon: React.FC<Props> = ({ color = "#3D3D3D", css }) => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" css={css} viewBox="0 0 24 24">
      <path fill="none" d="M0 0h24v24H0V0z" />
      <path
        d="M9 16.2l-3.5-3.5a.98.98 0 00-1.4 0 .98.98 0 000 1.4l4.19 4.19a1 1 0 001.41 0L20.3 7.7a.98.98 0 000-1.4.98.98 0 00-1.4 0L9 16.2z"
        style={{ fill: color }}
      />
    </svg>
  );
};

export default CheckIcon;
