/** @jsxImportSource @emotion/react */
import { jsx } from "@emotion/react";
import { useTheme } from "@emotion/react";
import { useLocation } from "react-router-dom";
import React, { useRef, Fragment } from "react";
import { useProgramTypeStore, useScreenSizeStore } from "stores";
import { addFlex, addSize, addPadding } from "utils";
import FadeDiv from "components/FadeDiv";

type Props = {};

const ProgramTypeContent: React.FC<Props> = () => {
  const location = useLocation();
  const { screenIs } = useScreenSizeStore();
  const { current: localContext } = useRef({
    latestPathname: location.pathname,
  });
  const { programType } = useProgramTypeStore();
  localContext.latestPathname = location.pathname;

  const theme = useTheme();

  return (
    <Fragment>
      <FadeDiv
        isVisible={true}
        css={{
          ...addFlex({
            x: "left",
            y: screenIs({
              default: "center",
              tablet: "center",
              mobile: "top",
            }),
            direction: "down",
          }),
          ...addSize({
            size: "100%",
            height: screenIs({
              default: "280px",
              tablet: "280px",
              mobile: "160px",
            }),
          }),
          ...addPadding(
            screenIs({
              default: { horizontal: "75px", top: "55px", bottom: "145px" },
              tablet: { horizontal: "25px", top: "43px", bottom: "29px" },
              mobile: { horizontal: "15px", top: "20px", bottom: "64px" },
            })
          ),
          label: "program-type-focusbox",
        }}
      >
        {programType ? (
          <h1
            data-automation={`programTypeHeadline`}
            css={{
              color: theme.topFocusBoxHeading,
              fontFamily: theme.fonts.heading,
              fontSize: screenIs({
                default: "auto",
                mobile: "30px",
              }),
            }}
          >
            {programType.name} funded programs and facilities
          </h1>
        ) : null}
      </FadeDiv>
    </Fragment>
  );
};

export default ProgramTypeContent;
