/** @jsxImportSource @emotion/react */
import { jsx } from "@emotion/react";
import { useTheme } from "@emotion/react";
import React, { useState, useEffect, useCallback } from "react";
import { useScreenSizeStore } from "stores";

import MobileHeader from "./MobileHeader";

import Checkbox from "components/CheckBox";
import Paragraph from "components/Paragraph";
import {
  InstitutionDetail,
  SearchResultResponse,
  AllFacilitiesResponse,
} from "utils/types";
import AutoHeightAnimator from "components/AutoHeightAnimator";
import { addPadding, addFlex, addMargin } from "utils";
import { FUTURE_INSTITUTION_NAMES } from "utils/constants";
import tenantConfig from "tenants";

type Props = {
  toggleActiveInstitution: (institutionId: string, newValue: boolean) => void;
  searchResultIds: string[];
  activeInstitutionIds: string[];
  facilityCollection: {
    [facilityId: string]: SearchResultResponse | AllFacilitiesResponse;
  };
};
type InstitutionFilters = InstitutionDetail & {
  count: number;
};

const FacilitiesFilter: React.FC<Props> = ({
  searchResultIds,
  toggleActiveInstitution,
  activeInstitutionIds,
  facilityCollection,
}) => {
  const { screenIs } = useScreenSizeStore();
  const isDesktop = useScreenSizeStore((state) => state.isDesktop);

  // NOTE  For some reason, if this component is inside a conditionally rendered JSX in it's parent ,
  // then isMobile/isDesktop etc doesn't update when taken directly from useScreenSizeStore() (const { isDesktop } = useScreenSizeStore();)
  // but screenIs still works
  // taking it using a parameter works (const isDesktop = useScreenSizeStore((state) => state.isDesktop))

  const [isOpen, setOpen] = useState(true);
  const [institutionFilter, setInstitutionFilter] = useState<
    InstitutionFilters[]
  >([] as InstitutionFilters[]);

  const getUniqueInstitutions = useCallback(() => {
    const uniqueInstitutions: InstitutionFilters[] = [];

    searchResultIds
      .map((searchResultId) => facilityCollection[searchResultId].institution)
      .forEach((institution) => {
        let match = false;
        uniqueInstitutions.forEach((uniqueInstitution) => {
          if (uniqueInstitution.id === institution?.id) {
            uniqueInstitution.count++;
            match = true;
          }
        });
        if (!match && institution) {
          uniqueInstitutions.push({ ...institution, ...{ count: 1 } });
        }
      });
    return uniqueInstitutions;
  }, [searchResultIds, facilityCollection]);

  useEffect(() => {
    if (isDesktop) {
      setOpen(true);
    }
  }, [isDesktop]);

  useEffect(() => {
    setInstitutionFilter(getUniqueInstitutions());
  }, [getUniqueInstitutions]);

  const checkboxBottomMargin = screenIs({
    default: "17px",
    tablet: "15px",
    mobile: "9px",
  });

  return (
    <div
      css={{
        background: "#F8F8F8",
        borderRadius: "8px",
        overflow: "hidden",
        marginBottom: isDesktop ? "20px" : "15px",
      }}
    >
      {screenIs({
        default: <MobileHeader toggleMenu={setOpen} isOpen={isOpen} />,
        desktop: (
          <div
            css={{
              paddingLeft: "40px",
              paddingRight: "40px",
              paddingTop: "24px",
              paddingBottom: "0px",
            }}
          >
            <Paragraph type={`intro`}>
              Showing{" "}
              {tenantConfig.text.usePlatfrom ? "platforms" : "facilities"} from:
            </Paragraph>
          </div>
        ),
      })}
      <AutoHeightAnimator faster initialHeight={0}>
        <div
          aria-hidden={isOpen ? "false" : "true"}
          css={{
            height: screenIs({
              default: isOpen ? "auto" : 0,
              desktop: "auto",
            }),
            maxHeight: screenIs({
              default: isOpen ? "auto" : 0,
              desktop: "auto",
            }),
            opacity: isOpen ? 1 : 0,
            transition: "opacity 0.25s",
          }}
        >
          <div
            css={{
              padding: screenIs({
                default: "8px 40px 20px 40px",
                tablet: "0px 25px 15px 25px",
                mobile: "0px 15px 15px 15px",
              }),
            }}
          >
            {screenIs({
              tablet: <Paragraph>Showing facilities from:</Paragraph>,
              default: null,
            })}
            {screenIs({
              default: (
                <div
                  css={{
                    width: "100%",
                    height: "1px",
                    background: "#D5D5D5",
                    marginTop: screenIs({ default: "4px", mobile: "8px" }),
                    marginBottom: screenIs({
                      default: "25px",
                      mobile: "10px",
                    }),
                  }}
                />
              ),
              mobile: null,
            })}
            {institutionFilter.map(({ name, id, count }) => (
              <Checkbox
                key={id}
                label={`${name} (${count})`}
                id={id}
                name={name}
                css={{
                  marginBottom: checkboxBottomMargin,
                }}
                value={activeInstitutionIds.includes(id)}
                disabled={count === 0}
                onChange={(newValue) => {
                  toggleActiveInstitution(id, newValue);
                }}
                whiteSpace={name.length < 25 ? "nowrap" : "normal"}
              />
            ))}

            {tenantConfig.text.futureFacilityNames.length > 0 &&
              tenantConfig.text.futureFacilityNames.map((name: any) => (
                <Checkbox
                  key={name}
                  label={
                    <div css={{ ...addFlex({ x: "center", y: "center" }) }}>
                      <div>{name} (0) </div>
                      <span
                        css={{
                          ...addFlex({ x: "center", y: "center" }),
                          ...addPadding({
                            vertical: "5px",
                            horizontal: "10px",
                          }),
                          ...addMargin({ left: "15px" }),
                          fontWeight: "bold",
                          fontSize: "12px",
                          lineHeight: "15px",
                          color: "white",
                          background: "#949494",
                          borderRadius: "100px",
                          whiteSpace: "nowrap",
                        }}
                      >
                        Coming Soon
                      </span>
                    </div>
                  }
                  id={name}
                  name={name}
                  css={{ marginBottom: checkboxBottomMargin }}
                  value={false}
                  disabled={true}
                  onChange={() => {
                    // updateActiveInstitution(id);
                  }}
                />
              ))}
          </div>
        </div>
      </AutoHeightAnimator>
    </div>
  );
};

export default FacilitiesFilter;

//SearchResultFilter
