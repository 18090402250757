import { Configuration } from "../";
import tenantConfig from "tenants";

const configuration: Configuration = {
  api: {
    uri: process.env.REACT_APP_API_URL || "",
    apiKey: process.env.REACT_APP_API_KEY || "",
    tenantId: tenantConfig.tenantId,
  },
  fireBaseConfig: {
    apiKey: "AIzaSyBH_FuqlNxxvffPjLiiOIVUjBrcuPRBOic",
    authDomain: "arinorgau.firebaseapp.com",
    projectId: "arinorgau",
    appId: "1:597215957054:web:4dd3d4b2088fb98d94416f",
    measurementId: "G-DLRPBSPF2P",
  },
  shouldCollectAnalyticsData: true,
  reCaptchaKey: "6LfKluIUAAAAAFJEzqPawgX71jCIrSgzGw5J-p_X",
} as const;

export default configuration;
