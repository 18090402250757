import { create } from "zustand";
import { ProgramMeta } from "utils/types";
import getProgram from "services/api/getProgram";

type ProgramStoreState = {
  program: ProgramMeta | undefined | null;
  isLoadingProgram: boolean;
};

type ProgramStore = ProgramStoreState & {
  setProgram: (program: ProgramMeta | null) => void;
  resetProgram: () => void;
  setIsLoadingProgram: (newValue: boolean) => void;
  resetIsLoadingProgram: () => void;
  fetchProgram: (
    pathRoute: string,
    expectedRoute: string,
    programId?: string | undefined | null
  ) => void;
};

const useProgramStore = create<ProgramStore>((set, get) => ({
  program: undefined,
  isLoadingProgram: false,
  setProgram: (program) => set(() => ({ program })),
  resetProgram: () => set({ program: undefined }),
  setIsLoadingProgram: (newValue) => {
    set((state) => ({
      ...state,
      isLoadingProgram: newValue,
    }));
  },
  resetIsLoadingProgram: () => set({ isLoadingProgram: undefined }),
  fetchProgram: async (pathRoute, expectedRoute, programId) => {
    if (pathRoute !== expectedRoute) return;
    if (!programId) return;
    if (get().isLoadingProgram) return;
    get().resetProgram();
    get().setIsLoadingProgram(true);
    try {
      const programDetailsResponse = await getProgram(programId);
      await get().setProgram(programDetailsResponse.data.getProgram);

      get().setIsLoadingProgram(false);
    } catch (error) {
      console.error(error);
    }
  },
}));

export { useProgramStore };
