/** @jsxImportSource @emotion/react */
import { jsx, css } from "@emotion/react";

import React from "react";

type Props = {
  color: string;
  css: ReturnType<typeof css>;
};

const CrossIcon: React.FC<Props> = ({ color = "#3D3D3D", css }) => {
  return (
    <svg
      width="16"
      height="16"
      viewBox="0 0 16 16"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      css={css}
    >
      <path
        d="M15.6653 1.95098C16.1116 1.50467 16.1116 0.781049 15.6653 0.334735C15.219 -0.111578 14.4953 -0.111578 14.049 0.334735L8 6.38376L1.95098 0.334735C1.50467 -0.111578 0.781049 -0.111578 0.334735 0.334735C-0.111578 0.781049 -0.111578 1.50467 0.334735 1.95098L6.38376 8L0.334735 14.049C-0.111578 14.4953 -0.111578 15.219 0.334735 15.6653C0.781049 16.1116 1.50467 16.1116 1.95098 15.6653L8 9.61624L14.049 15.6653C14.4953 16.1116 15.219 16.1116 15.6653 15.6653C16.1116 15.219 16.1116 14.4953 15.6653 14.049L9.61624 8L15.6653 1.95098Z"
        fill={color}
      />
    </svg>
  );
};

export default CrossIcon;
