export function numberWithCommas(x: string | number): string {
  return x.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
}

export function sanitiseSearchTerm(searchTerm: string): string {
  return searchTerm.trim();
}

export function escapeQuotesAndBacklashes(text: string): string {
  return text.replace(/\\/g, "\\\\").replace(/[\""]/g, '\\"');
}
