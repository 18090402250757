/** @jsxImportSource @emotion/react */
import { jsx } from "@emotion/react";
import { useTheme } from "@emotion/react";
import React from "react";

import { useScreenSizeStore } from "stores/screenSize";
import BasicButton from "components/BasicButton";
import { addMargin } from "utils";

type Props = {
  linkTo: string;
  institutionName: string;
  title: string;
};

const ResultTitle: React.FC<Props> = ({ title, institutionName, linkTo }) => {
  const theme = useTheme();
  const { screenIs } = useScreenSizeStore();

  return (
    <h3
      css={{
        ...addMargin({ bottom: screenIs({ default: "11px", desktop: "9px" }) }),
        fontSize: screenIs({ default: "24px", tablet: "22px", mobile: "18px" }),
        fontWeight: 600,
        fontFamily: theme.fonts.heading,
      }}
    >
      <BasicButton
        label={`learn more about ${title}`}
        linkTo={linkTo}
        css={{
          width: "auto",
          height: "auto",
          color: theme.mainColor,
          display: "inline-flex",
          textDecoration: "underline",
          verticalAlign: "top",
        }}
        innerCss={{
          padding: 0,
        }}
      >
        {title}
      </BasicButton>
      {` at ${institutionName}`}
    </h3>
  );
};

export default ResultTitle;
