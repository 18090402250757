/** @jsxImportSource @emotion/react */
import { jsx, css } from "@emotion/react";

import React from "react";

type Props = {
  color: string;
  css: ReturnType<typeof css>;
};

const CheckThickIcon: React.FC<Props> = ({ color = "#3D3D3D", css }) => {
  return (
    <svg
      viewBox="0 0 14 10"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      css={css}
    >
      <path
        d="M5.87 9.32l7.25-6.94a.59.59 0 000-.85l-.9-.85a.65.65 0 00-.89 0l-5.9 5.66-2.76-2.65a.65.65 0 00-.9 0l-.89.86a.59.59 0 000 .85l4.1 3.92c.25.24.64.24.9 0z"
        fill={color}
      />
    </svg>
  );
};

export default CheckThickIcon;
