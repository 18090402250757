/** @jsxImportSource @emotion/react */
import { jsx } from "@emotion/react";
import { useTheme } from "@emotion/react";

import React, { Fragment, Ref } from "react";
import TooltipItem from "./TooltipItem";
import { addFlex, addSize } from "utils";
import LinkUnderline from "components/LinkUnderline";
import { useScreenSizeStore } from "stores";

type Props = {
  texts: string[];
  title: string;
  facilityId: string;
  noWrap: boolean;
  ref?: Ref<HTMLDivElement>;
};

const Tooltip: React.FC<Props> = React.forwardRef<HTMLDivElement, Props>(
  ({ texts, title, facilityId, noWrap }, theRef) => {
    const isMobile = useScreenSizeStore((state) => state.isMobile);

    const MAX_TOOLTIPS_TO_SHOW = 5;

    const hasExtraMatches = texts.length > MAX_TOOLTIPS_TO_SHOW;
    const extraMatchesAmount = hasExtraMatches
      ? texts.length - MAX_TOOLTIPS_TO_SHOW
      : 0;

    const theme = useTheme();

    return (
      <div
        css={{
          label: "tooltip-relative-holder",
          position: "relative",
          paddingBottom: "10px",
        }}
      >
        <div
          ref={theRef}
          css={{
            label: "tooltip-main-inner",
            position: "relative",
            ...addSize({
              width: noWrap ? { min: "160px", max: "330px" } : "330px",
            }),
            background: "#FFFFFF",
            boxShadow: "0px 8px 16px rgba(0, 0, 0, 0.08)",
            borderRadius: "8px",
            padding: "8px",
            border: `2px solid ${theme.mainColor}`,
            pointerEvents: "all",
          }}
        >
          {texts
            .slice(0, MAX_TOOLTIPS_TO_SHOW)
            .map((theText: string, index: number) => {
              const isLastItem = index === texts.length - 1;

              return (
                <Fragment key={theText + index}>
                  <TooltipItem text={theText} noWrap={noWrap} />
                  {!isLastItem && (
                    <hr
                      css={{
                        border: "none",
                        borderBottom: "1px solid #D5D5D5",
                      }}
                    />
                  )}
                </Fragment>
              );
            })}
          <LinkUnderline
            linkTo={`/facility/${facilityId}`}
            label={`find out more about this facility`}
            css={{
              position: "relative",
              padding: "8px",
              fontSize: "14px",
              lineHeight: "20px",
            }}
          >
            {`${
              texts.length > MAX_TOOLTIPS_TO_SHOW
                ? extraMatchesAmount +
                  ` more match${extraMatchesAmount > 1 ? "es" : ""}`
                : ""
            }`}
            {`${texts.length < MAX_TOOLTIPS_TO_SHOW ? "read more" : ""}`}
          </LinkUnderline>
        </div>
        <div
          css={{
            label: "tooltip-triangle",
            ...addFlex({ x: "center", y: "center" }),
            position: "absolute",
            width: "100%",
            bottom: 5,
            left: 0,
          }}
        >
          <div
            css={{
              label: "tooltip-triangle",
              ...addSize({ size: "10px" }),
              position: "relative",
              backgroundColor: "white",
              transform: "rotate(45deg)",
              borderBottom: "2px solid #228192",
              borderRight: "2px solid #228192",
            }}
          ></div>
        </div>
      </div>
    );
  }
);

export default Tooltip;
