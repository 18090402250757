/** @jsxImportSource @emotion/react */
import { jsx } from "@emotion/react";

import React, { useState, useEffect, useRef, useLayoutEffect } from "react";
import { useHover } from "hooks";
import Tooltip from "./Tooltip";
import { useSpring, animated } from "react-spring";
import { addSize, addFlex } from "utils";
import { useScreenSizeStore } from "stores";

type Props = {
  texts: string[];
  title: string;
  facilityId: string;
  noWrap: boolean;
  children: React.ReactNode;
};
const SPRING_CONFIGS = {
  open: { mass: 1, tension: 2200, friction: 90 },
  close: { mass: 1, tension: 3000, friction: 90 },
};

const TooltipWrapper: React.FC<Props> = ({
  texts,
  title,
  facilityId,
  noWrap,
  children,
}) => {
  const [delayedIsHovered, setDelayedIsHovered] = useState(false);
  const [shouldRenderTooltip, setShouldRenderTooltip] = useState(false);

  const springProps = useSpring({
    visibleAmount: delayedIsHovered ? 1 : 0,
    config: delayedIsHovered ? SPRING_CONFIGS.open : SPRING_CONFIGS.close,
    onRest: (props) => {
      if (props.value.visibleAmount < 0.1) setShouldRenderTooltip(false);
    },
  });
  const [hoverRef, isHovered] = useHover();
  const [hoverToolipRef, isTooltipHovered] = useHover();
  const [mobileXOffset, setMobileXOffset] = useState(0);
  const { current: localContext } = useRef({
    updateDelayedIsHoveredTimeout: null as any,
    prevIsHovered: false,
    prevIsTooltipHovered: false,
  });

  const isMobile = useScreenSizeStore((state) => state.isMobile);

  const isTouch =
    !!("ontouchstart" in window) || window.navigator.maxTouchPoints > 0;

  useEffect(() => {
    clearTimeout(localContext.updateDelayedIsHoveredTimeout);

    if (!localContext.prevIsHovered && isHovered) {
      setDelayedIsHovered(isHovered || isTooltipHovered);
      setShouldRenderTooltip(isHovered || isTooltipHovered);
    }

    localContext.updateDelayedIsHoveredTimeout = window.setTimeout(() => {
      setDelayedIsHovered(isHovered || isTooltipHovered);
      if (isTouch) {
        window.setTimeout(() => {
          setDelayedIsHovered(false);
        }, 5000);
      }
    }, 150);
  }, [
    isTouch,
    isHovered,
    isTooltipHovered,
    localContext.prevIsHovered,
    localContext.prevIsTooltipHovered,
    localContext.updateDelayedIsHoveredTimeout,
  ]);
  useEffect(() => {
    localContext.prevIsHovered = isHovered;
  }, [isHovered, localContext.prevIsHovered]);
  useEffect(() => {
    localContext.prevIsTooltipHovered = isTooltipHovered;
  }, [isTooltipHovered, localContext.prevIsTooltipHovered]);

  useEffect(() => {
    if (shouldRenderTooltip && hoverToolipRef.current && isMobile) {
      const tooltipBoundingRect =
        hoverToolipRef.current.getBoundingClientRect();

      const realDistanceFromLeft = tooltipBoundingRect.left - mobileXOffset;
      const realDistanceFromRight =
        window.innerWidth - tooltipBoundingRect.right + mobileXOffset;
      const centerXPosition =
        realDistanceFromLeft + tooltipBoundingRect.width / 2;
      const centerXOffset = window.innerWidth / 2 - centerXPosition;
      const isCentered = Math.abs(centerXOffset) < 2;
      const tooltipIsWiderThanScreen =
        tooltipBoundingRect.width > window.innerWidth;

      let newXOffset = 0;

      if (tooltipIsWiderThanScreen) {
        if (!isCentered) {
          newXOffset = centerXOffset;
        }
        if (isCentered) {
          newXOffset = mobileXOffset;
        }
      } else {
        newXOffset = mobileXOffset;

        if (realDistanceFromRight < 0) {
          newXOffset = realDistanceFromRight;
        }
        if (realDistanceFromLeft < 0) {
          newXOffset = -realDistanceFromLeft;
        }
      }

      setMobileXOffset(newXOffset);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [hoverToolipRef, shouldRenderTooltip]);

  return (
    <div css={{ position: "relative" }}>
      <div ref={hoverRef} css={{ position: "relative" }}>
        {children}
      </div>
      {shouldRenderTooltip && (
        <animated.div
          css={{
            position: "absolute",
            minWidth: "100%",
            bottom: "100%",
            left: 0,
            display: "flex",
            pointerEvents: delayedIsHovered ? "all" : "none",
          }}
          style={{
            opacity: springProps.visibleAmount,
            transform: springProps.visibleAmount.to(
              (theAmount) => `translate(0px, ${(1 - theAmount) * 10}px)`
            ),
          }}
        >
          <div
            css={{
              ...addSize({ width: "100%", height: "0" }),
              ...addFlex({ x: "center", y: "center" }),
              position: "relative",
              background: "orange",
            }}
          >
            <div
              css={{
                position: "absolute",
                ...addSize({ width: "180px", height: "0" }),
                ...addFlex({ x: "center", y: "bottom" }),
                transform: isMobile
                  ? `translateX(${mobileXOffset}px)`
                  : undefined,
                background: "blue",
              }}
            >
              <Tooltip
                texts={texts}
                ref={hoverToolipRef}
                title={title}
                facilityId={facilityId}
                noWrap={noWrap}
              />
            </div>
          </div>
        </animated.div>
      )}
    </div>
  );
};

export default TooltipWrapper;
