/** @jsxImportSource @emotion/react */
import { jsx, css } from "@emotion/react";

import React from "react";

type Props = {
  color: string;
  css: ReturnType<typeof css>;
};

const AimIcon: React.FC<Props> = ({ color = "#3D3D3D", css }) => {
  return (
    <svg
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      css={css}
      viewBox="0 0 40 40"
    >
      <path
        fill={color}
        fillRule="evenodd"
        clipRule="evenodd"
        d="M31.179 8.822c.65.65.65 1.706 0 2.357l-20 20a1.667 1.667 0 11-2.357-2.357l20-20c.65-.651 1.706-.651 2.357 0z"
      />
      <path
        fill={color}
        fillRule="evenodd"
        clipRule="evenodd"
        d="M8.822 8.822c.65-.651 1.706-.651 2.357 0l20 20a1.667 1.667 0 11-2.357 2.357l-20-20a1.667 1.667 0 010-2.357z"
      />
    </svg>
  );
};

export default AimIcon;
