import { ITenant } from "../tenant";
import arinTheme from "./theme";
import arinImage from "assets/images/arinImage.png";
import arinCircle from "assets/images/arinCircle.png";
import arinConfusedImage from "assets/images/arinConfused.png";
import ArinLogoIcon from "icons/imageIcons/ArinLogo";

const arin: ITenant = {
  name: "ARIN Portal",
  tenantId: "dda0f81746c44dc075557380a5a998a3",
  theme: arinTheme,
  images: {
    loadingOverlay: arinImage,
    logo: ArinLogoIcon,
    quickQuestions: arinCircle,
    notFound: arinConfusedImage,
  },
  text: {
    documentTitle:
      "Arin | Search for research equipment and services in Australia",
    searchingIn: "Australia",
    loadingFacilityNames: [
      "University of Melbourne",
      "RMIT",
      "Monash University",
      "Murdoch Childrens Research Institute",
    ],
    futureFacilityNames: ["WEHI", "CSIRO", "Peter Mac"],
    usePlatfrom: false,
    ncrisPrograms: true,
    feedback: {
      title: "help improve ARIN",
      heading: "Provide feedback on your search",
    },
  },
  userTypes: {
    PUBLIC: "Public",
    COMMERCIAL: "Commercial",
    ACADEMIC: "Academic",
    PRIVATE: "Private",
  },
  ncrisProgramTypeId: "ncris",
};

export default arin;
