/** @jsxImportSource @emotion/react */
import { jsx } from "@emotion/react";
import { useTheme } from "@emotion/react";

import React from "react";
import {
  useSearchResultsStore,
  useScreenSizeStore,
  useSummaryStore,
} from "stores";
import { useEffect, useState, useRef, useCallback } from "react";
import { useTransition, animated } from "react-spring";
import { addFlex, addSize } from "utils";
import shallow from "zustand/shallow";
import { PositionStyle } from "utils/styles";
import tenantConfig from "tenants";

type Props = {};

const SHOWN_TEXTS = ["", ...tenantConfig.text.loadingFacilityNames];

const totalDuration = 2750; // 400
const singleTextDuration = totalDuration / SHOWN_TEXTS.length;
const opacityTransitionTime = 350; // 150
const opacityTimeoutPadding = 50;

function truncateText(text: string, maxCharacters: number | null): string {
  if (maxCharacters === null) {
    return text;
  }
  const ellipsis = "...";

  return text.length <= maxCharacters
    ? text
    : text.slice(0, maxCharacters - ellipsis.length) + ellipsis;
}

const LoadingResultsOverlay: React.FC<Props> = React.memo(() => {
  const { screenIs, isLandscapeTablet } = useScreenSizeStore();
  const theme = useTheme();

  const { current: localContext } = useRef({
    nextStepTimeout: null as null | number,
    previousText: null as null | string,
    previousIsShowingLoadingOverlay: null as null | boolean,
    previousShouldRender: null as null | boolean,
  });

  const [isShowingLoadingOverlay, setIsShowingLoadingOverlay] =
    useSearchResultsStore(
      (state) => [
        state.isShowingLoadingOverlay,
        state.setIsShowingLoadingOverlay,
      ],
      shallow
    );

  const totalCount = useSummaryStore((state) => state.totalCount);

  const [currentText, setCurrentText] = useState(SHOWN_TEXTS[0]);
  const [shouldRender, setShouldRender] = useState(false);
  const [currentOpacity, setCurrentOpacity] = useState(0);

  const transitions = useTransition(currentText, {
    key: (currentTextParam: any) => currentTextParam,
    from: {
      transform: "translateY(100%)",
      opacity: 0,
      position: "absolute" as PositionStyle,
      top: 0,
      left: 0,
    },
    enter: {
      transform: "translateY(0%)",
      opacity: 1,
      position: "relative" as PositionStyle,
    },
    leave: {
      transform: "translateY(-100%)",
      opacity: 0,
      position: "absolute" as PositionStyle,
      top: 0,
      left: 0,
    },
  });

  const shownTextIndex = SHOWN_TEXTS.indexOf(currentText);
  const shownTextNumber = shownTextIndex + 1;
  const isLastText = shownTextNumber === SHOWN_TEXTS.length;

  const handleIsShowingLoadingOverlayChange = useCallback(() => {
    if (
      isShowingLoadingOverlay === localContext.previousIsShowingLoadingOverlay
    )
      return;
    if (isShowingLoadingOverlay === true) {
      setCurrentOpacity(0);
      setCurrentText(SHOWN_TEXTS[0]);
      setShouldRender(true);
    } else {
      if (localContext.nextStepTimeout) {
        window.clearTimeout(localContext.nextStepTimeout);
      }
      setCurrentOpacity(0);
      localContext.nextStepTimeout = window.setTimeout(() => {
        setShouldRender(false);
      }, opacityTransitionTime + opacityTimeoutPadding);
    }
    localContext.previousIsShowingLoadingOverlay = isShowingLoadingOverlay;
  }, [
    isShowingLoadingOverlay,
    localContext.nextStepTimeout,
    localContext.previousIsShowingLoadingOverlay,
  ]);

  useEffect(() => {
    if (shouldRender === localContext.previousShouldRender) return;

    if (shouldRender) {
      requestAnimationFrame(() => {
        setCurrentOpacity(1);
      });
    }
    localContext.previousShouldRender = shouldRender;
  }, [shouldRender, localContext.previousShouldRender]);

  useEffect(handleIsShowingLoadingOverlayChange, [
    handleIsShowingLoadingOverlayChange,
    isShowingLoadingOverlay,
  ]);

  useEffect(() => {
    if (currentText === localContext.previousText) return;
    if (localContext.nextStepTimeout) {
      window.clearTimeout(localContext.nextStepTimeout);
    }
    if (isLastText) {
      localContext.nextStepTimeout = window.setTimeout(() => {
        setIsShowingLoadingOverlay(false);
      }, singleTextDuration);
    } else {
      localContext.nextStepTimeout = window.setTimeout(() => {
        setCurrentText(SHOWN_TEXTS[shownTextIndex + 1]);
      }, singleTextDuration);
    }

    localContext.previousText = currentText;
  }, [
    currentText,
    isLastText,
    localContext.nextStepTimeout,
    localContext.previousText,
    setIsShowingLoadingOverlay,
    shownTextIndex,
  ]);

  const imageWidth = screenIs({ default: 130, tablet: 90, mobile: 50 });

  const imageHeightFromWidthRatio = 350 / 270;
  // const imageHeight = screenIs({ default: 350, tablet: 207, mobile: 133 });
  const imageHeight = imageWidth * imageHeightFromWidthRatio;

  const currentTextHeight = screenIs({
    default: "70px",
    tablet: "50px",
    mobile: "35px",
  });

  if (!shouldRender) {
    return null;
  }

  return (
    <div
      aria-live="polite"
      aria-label="Loading search results from multiple facilities"
      data-automation="results-loader"
      role="status"
      css={{
        ...addSize({ width: "100%", height: "100vh" }),
        ...addFlex({ x: "center", y: "center" }),
        position: "fixed",
        top: 0,
        left: 0,
        overflow: "hidden",
        zIndex: 100,
        background: "rgba(0,0,0, 0.75)",
        color: "white",
        opacity: currentOpacity,
        transition: `opacity ${opacityTransitionTime}ms ease-in-out`,
      }}
    >
      <div
        css={{
          label: "main-content",
          ...addFlex({ x: "center", y: "center" }),
        }}
      >
        <div
          css={{
            label: "arin-image-holder",
            position: "relative",
            ...addSize({
              // on mobile the image takes up width so all of the content can be more centered
              width: screenIs({ default: "0px", mobile: "30px" }),
              height: imageHeight + "px",
            }),
          }}
        >
          {tenantConfig.images.loadingOverlay && (
            <div
              role="img"
              aria-label="Periscope character"
              title="Periscope character"
              css={{
                label: "arin-image",
                position: "absolute",
                right: 0,
                top: 0,
                ...addSize({
                  width: `${imageWidth}px`,
                  height: `${imageHeight}px`,
                }),
                backgroundImage: `url("${tenantConfig.images.loadingOverlay}")`,
                backgroundSize: "cover",
                backgroundPosition: "center top",
              }}
            ></div>
          )}
        </div>
        <div
          css={{
            label: "text-content",
            marginLeft: screenIs({ default: "20px", mobile: "5px" }),
          }}
        >
          <div
            css={{
              label: "description-text",
              fontSize: screenIs({
                default: "2rem",
                tablet: "1.5rem",
                mobile: "0.75rem",
              }),
            }}
          >
            Searching within {totalCount?.facilitiesCount}{" "}
            <span css={{ color: theme.resultsOverlayTextColor }}>
              {tenantConfig.text.usePlatfrom ? "platforms" : "facilities"}
            </span>{" "}
            at...
          </div>
          <div
            css={{
              label: "current-text-holder",
              ...addFlex({ x: "left", y: "center", direction: "down" }),
              ...addSize({ width: "100%", height: currentTextHeight }),
              position: "relative",
            }}
          >
            <div
              css={{
                label: "current-text-holder-inner",
                ...addFlex({ x: "left", y: "center", direction: "down" }),
                ...addSize({ width: "100vw", height: currentTextHeight }),
                // positioned absolutely so text can be longer than parent, without recentering everything
                position: "absolute",
                height: currentTextHeight,
                overflow: "hidden",
              }}
            >
              {transitions((props, currentText) => (
                <animated.div
                  css={{
                    ...addFlex({
                      x: "left",
                      y: "center",
                      direction: "down",
                    }),
                    ...addSize({
                      width: "320px",
                      height: currentTextHeight,
                    }),
                    textOverflow: "ellipsis",
                    whiteSpace: "nowrap",
                    color: theme.resultsOverlayTextColor,
                    fontSize: screenIs({
                      default: "3rem",
                      tablet: "2rem",
                      mobile: "1.2rem",
                    }),
                    fontWeight: "bold",
                  }}
                  style={props}
                >
                  {truncateText(
                    currentText,
                    screenIs({
                      default: isLandscapeTablet ? 28 : null,
                      tablet: 35,
                      mobile: 23,
                    })
                  )}
                </animated.div>
              ))}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
});

export default LoadingResultsOverlay;
