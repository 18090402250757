/** @jsxImportSource @emotion/react */
import { jsx, css } from "@emotion/react";

import React from "react";

type Props = {
  color: string;
  css: ReturnType<typeof css>;
};

const PhoneIcon: React.FC<Props> = ({ color = "#3D3D3D", css }) => {
  return (
    <svg
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      css={css}
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M4.8125 10.375c2.00001 3.875 4.93748 6.8125 8.8125 8.8125l2.9375-2.9375c.4167-.4167.875-.5208 1.375-.3125 1.5.5 3.0833.75 4.75.75.375 0 .6875.125.9375.375S24 17.625 24 18v4.6875c0 .375-.125.6875-.375.9375s-.5625.375-.9375.375c-6.25 0-11.5937-2.2187-16.03125-6.6562C2.21873 12.9062 0 7.56253 0 1.3125 0 .9375.124999.625.375.375.625001.125.937498 0 1.3125 0H6c.375 0 .6875.125.9375.375s.375.5625.375.9375c0 1.66668.25 3.24999.75 4.75.16667.54167.0625 1-.3125 1.375L4.8125 10.375z"
        fill={color}
      />
    </svg>
  );
};

export default PhoneIcon;
