import { create } from "zustand";
import { Facility, Program, ProgramType } from "utils/types";
import getFacility from "services/api/facility";

type FacilityStoreState = {
  facility: Facility | undefined | null;
  program: Program | undefined | null;
  programType: ProgramType | undefined | null;
  isLoadingFacility: boolean;
};

type FacilityStore = FacilityStoreState & {
  setFacility: (facility: Facility | null) => void;
  setProgram: (program: Program | null) => void;
  setProgramType: (programType: ProgramType | null) => void;
  resetFacility: () => void;
  setIsFacilityLoading: (newValue: boolean) => void;
  resetIsFacilityLoading: () => void;
  fetchFacility: (id: string) => void;
};

const useFacilityStore = create<FacilityStore>((set, get) => ({
  facility: undefined,
  isLoadingFacility: false,
  program: undefined,
  programType: undefined,
  setFacility: (facility) => set(() => ({ facility })),
  setProgram: (program: Program | null) => set(() => ({ program })),
  setProgramType: (programType: ProgramType | null) =>
    set(() => ({ programType })),
  resetFacility: () => set({ facility: undefined }),
  resetIsFacilityLoading: () => set({ isLoadingFacility: undefined }),
  setIsFacilityLoading: (newValue) => {
    set((state) => ({
      ...state,
      isLoadingFacility: newValue,
    }));
  },
  fetchFacility: async (id) => {
    if (!id) return;
    if (get().isLoadingFacility) return;
    get().resetFacility();
    get().setIsFacilityLoading(true);
    try {
      const facilityResponse = await getFacility(id);
      await get().setFacility(
        facilityResponse.data.facility === null ||
          facilityResponse.data.facility.facility === null
          ? null
          : {
              ...facilityResponse.data.facility.facility,
              ...{
                institutionId: facilityResponse.data.facility.institution.id,
                institutionName:
                  facilityResponse.data.facility.institution.name,
              },
            }
      );
      await get().setProgram(facilityResponse.data.facility?.program);
      await get().setProgramType(facilityResponse.data.facility?.programType);

      get().setIsFacilityLoading(false);
    } catch (error) {
      console.error(error);
    }
  },
}));

export { useFacilityStore };
