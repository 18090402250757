/** @jsxImportSource @emotion/react */
import { jsx } from "@emotion/react";
import { useTheme } from "@emotion/react";
import { useLocation } from "react-router-dom";
import React, { useRef, Fragment } from "react";
import { useProgramStore, useScreenSizeStore } from "stores";
import { addFlex, addSize, addPadding } from "utils";
import FadeDiv from "components/FadeDiv";
import { PATHS } from "utils/routes";

type Props = {};

const ProgramContent: React.FC<Props> = () => {
  const location = useLocation();
  const { screenIs } = useScreenSizeStore();
  const { current: localContext } = useRef({
    latestPathname: location.pathname,
  });
  const { program } = useProgramStore();
  localContext.latestPathname = location.pathname;

  const theme = useTheme();

  return program ? (
    <Fragment>
      <FadeDiv
        isVisible={true}
        css={{
          ...addFlex({
            x: "left",
            y: screenIs({
              default: "center",
              tablet: "center",
              mobile: "top",
            }),
            direction: "down",
          }),
          ...addSize({
            size: "100%",
            height: screenIs({
              default: "280px",
              tablet: "280px",
              mobile: "160px",
            }),
          }),
          ...addPadding(
            screenIs({
              default: { horizontal: "75px", top: "55px", bottom: "145px" },
              tablet: { horizontal: "25px", top: "43px", bottom: "29px" },
              mobile: { horizontal: "15px", top: "20px", bottom: "64px" },
            })
          ),
          label: "program-focusbox",
        }}
      >
        <a
          css={{
            fontSize: "15px",
            fontWeight: "bold",
            color: "#2F2F2F",
            textDecoration: "none",
            textTransform: "uppercase",
            letterSpacing: "0.5px",
          }}
          className={"back"}
          href={PATHS.programType.replace(
            ":id",
            program.programType.externalId
          )}
        >
          Back to {program.programType.name} programs
        </a>
        <h1
          data-automation={`programHeadline`}
          css={{
            color: theme.topFocusBoxHeading,
            fontFamily: theme.fonts.heading,
            fontSize: screenIs({
              default: "auto",
              mobile: "30px",
            }),
          }}
        >
          {program.program.name}
        </h1>
      </FadeDiv>
    </Fragment>
  ) : null;
};

export default ProgramContent;
