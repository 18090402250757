import { load } from "recaptcha-v3";
import getConfiguration from "configuration";

export async function getValidationToken(actionName: string) {
  try {
    const recaptcha = await load(getConfiguration().reCaptchaKey);
    const token = await recaptcha.execute(actionName);

    return token;
  } catch (error) {
    console.error(`Failed to get reCAPTCHA token :`, error);
    return null;
  }
}
