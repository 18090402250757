/** @jsxImportSource @emotion/react */
import { jsx } from "@emotion/react";
import { useTheme } from "@emotion/react";
import React, { useState, useEffect } from "react";
import Paragraph from "components/Paragraph";
import BasicIcon from "components/BasicIcon";
import BasicButton from "components/BasicButton";
import ProvideLocation from "components/ProvideLocation";
import { SortSearchResultsType } from "screens/SearchContent";

type Props = {
  setSortBy: (location: SortSearchResultsType) => void;
  sortBy: SortSearchResultsType;
  isLoadingLocation: boolean;
};

const SortByMenu: React.FC<Props> = ({
  setSortBy,
  sortBy,
  isLoadingLocation,
}) => {
  const [isMenuOpen, setMenuOpen] = useState<boolean>(false);
  useEffect(() => setMenuOpen(false), [sortBy, setSortBy]);
  const theme = useTheme();

  return (
    <div
      css={{
        position: "relative",
      }}
    >
      <Paragraph
        css={{
          whiteSpace: "nowrap",
          display: "flex",
          justifyContent: "flex-end",
        }}
      >
        <span>Sort by:</span>
        <BasicButton
          onClick={() => setMenuOpen(!isMenuOpen)}
          label={`${isMenuOpen ? "close" : "open"} sort by menu`}
          innerCss={{
            whiteSpace: "nowrap",
            display: "flex",
            alignItems: "center",
            color: theme.mainColor,
            margin: "0 5px",
            fontWeight: "bold",
            borderBottom: `1px solid ${theme.mainColor}`,
          }}
          hasPopUp
        >
          {` `}
          {sortBy === "Relevance" ? "Most relevant" : "Location"}
          {` `}
          <BasicIcon
            size={10}
            height={6}
            name={`Arrow`}
            color={theme.mainColor}
            css={{
              transform: isMenuOpen ? "rotate(-180deg)" : "rotate(0deg)",
              transition: "transform 0.25s",
              transformOrigin: "center center",
              margin: "0 5px",
            }}
          />
        </BasicButton>
      </Paragraph>
      <div
        css={{
          background: "#fff",
          position: "absolute",
          top: "30px",
          width: "340px",
          right: 0,
          display: isMenuOpen ? "block" : "none",
          boxShadow: "0px 8px 16px rgba(0, 0, 0, 0.08)",
          zIndex: 999,
        }}
        aria-expanded={isMenuOpen}
      >
        <BasicButton
          label={`Sort by location`}
          onClick={() => setSortBy(`Relevance`)}
          css={{
            width: "100%",
          }}
          innerCss={{
            width: "100%",
          }}
        >
          <div
            css={{
              padding: "12px 20px",
              display: "flex",
              justifyContent: "space-between",
              background: sortBy === "Relevance" ? "#EEF4F6" : "#fff",
              width: "340px",
              borderBottom: "2px solid #d5d5d5",
            }}
          >
            <Paragraph
              bold
              css={{
                color: theme.mainColor,
              }}
            >
              Most relevant
            </Paragraph>
            {sortBy === "Relevance" && (
              <BasicIcon name={`Check`} color={theme.mainColor} size={24} />
            )}
          </div>
        </BasicButton>

        <div>
          <ProvideLocation
            sortBy={sortBy}
            setSortBy={setSortBy}
            isLoadingLocation={isLoadingLocation}
          />
        </div>
      </div>
    </div>
  );
};

export default SortByMenu;
