import { gql } from "apollo-boost";
import Api from "services/api";
import { AllFacilitiesResponse } from "utils/types";

type AllFacilities = {
  allFacilities: AllFacilitiesResponse[];
};

const getAllFacilities = () =>
  Api.query<AllFacilities>({
    query: gql`
      {
        allFacilities {
          facility {
            title
            location
            id
          }
          institution {
            id
            name
            location
          }
        }
      }
    `,
  }).then((result) => result.data);

export default getAllFacilities;
