import { match, MatchResult, pathToRegexp } from "path-to-regexp";

type RouteInfo = {
  name: string | undefined;
  params: MatchResult | undefined;
};
const PATHS = {
  index: "/",
  search: "/search",
  privacy: "/privacy",
  cookies: "/cookies",
  termsOfService: "/terms-of-service",
  facility: "/facility/:id",
  facilities: "/facilities",
  programType: "/pt/:id",
  program: "/program/:id",
  ncris: "/ncris",
  about: "/about",
  adminReport: "/reports/admin",
};

type PathName = keyof typeof PATHS;

function normaliseRoute(givenRoute: string): string {
  return givenRoute.substr(1).split("/")[0].toString();
}

function getUrlBase(givenRoute: string): string {
  if (givenRoute === PATHS.index) return "index";
  return (
    Object.keys(PATHS).find(
      (key) =>
        normaliseRoute(givenRoute) === normaliseRoute(PATHS[key as PathName])
    ) || ""
  );
}

function matchAnParseUrlInfo(
  givenRoute: string,
  urlBaseName: string
): MatchResult | boolean {
  return match(PATHS[urlBaseName as PathName], {
    decode: decodeURIComponent,
  })(givenRoute);
}

function getRouteInfo(givenRoute: string): RouteInfo {
  const urlBaseName = getUrlBase(givenRoute);
  const matchResult = !!urlBaseName
    ? matchAnParseUrlInfo(givenRoute, urlBaseName)
    : false;
  return matchResult && typeof matchResult === "object"
    ? {
        name: urlBaseName,
        params: matchResult,
      }
    : { name: undefined, params: undefined };
}

function isValidRoute(givenRoute: string): boolean {
  return !!getRouteInfo(givenRoute).name;
}

function parseAndValidateHash(
  hash: string
): undefined | { [key: string]: string } {
  if (hash.length <= 0) return undefined;
  return hash
    .substr(1)
    .split("&")
    .map((keyValue) => {
      const [key, value] = keyValue.split("=");
      return { key, value };
    })
    .reduce((obj, item) => Object.assign(obj, { [item.key]: item.value }), {});
}

function pathMatchesPattern(pattern: string, currentRoute: string): boolean {
  return !!pathToRegexp(pattern).exec(currentRoute);
}
export default PATHS;
export {
  isValidRoute,
  PATHS,
  getRouteInfo,
  parseAndValidateHash,
  pathMatchesPattern,
};
