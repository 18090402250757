/** @jsxImportSource @emotion/react */
import { jsx, css } from "@emotion/react";

import React from "react";

type Props = {
  color: string;
  css: ReturnType<typeof css>;
};

const CheckInCircleIcon: React.FC<Props> = ({ color = "#3D3D3D", css }) => {
  return (
    <svg
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      css={css}
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M12 24c6.6274 0 12-5.3726 12-12 0-6.62742-5.3726-12-12-12C5.37258 0 0 5.37258 0 12c0 6.6274 5.37258 12 12 12zm6.795-14.90717l-8.05 7.71077c-.2734.2619-.7166.2619-.99001 0l-4.55001-4.3583c-.27331-.2618-.27331-.6864 0-.9482l.98998-.9483c.27335-.2618.71662-.2618.98997 0l3.06507 2.936 6.5651-6.28844c.2734-.26183.7166-.26179.9899 0l.99.94825c.2733.2618.2733.68638 0 .94822z"
        fill={color}
      />
    </svg>
  );
};

export default CheckInCircleIcon;
