import { getValidEnvironment } from "configuration";
import development from "./development";

export type Features = {
  trendingSearches: boolean;
  autoCompleteSearch: boolean;
  facilitiesDirectory: boolean;
  ncrisPrograms: boolean;
  aboutPage: boolean;
  submitEnquiry: boolean;
  sortOptions: boolean;
};

export default function getFeatureToggles(): Features {
  const environment = getValidEnvironment();

  switch (environment) {
    case "development":
      return development;
    default:
      return {
        trendingSearches: true,
        autoCompleteSearch: true,
        facilitiesDirectory: true,
        ncrisPrograms: true,
        aboutPage: true,
        submitEnquiry: true,
        sortOptions: true,
      };
  }
}

export function hasFeature(featureName: keyof Features) {
  return getFeatureToggles()[featureName];
}
