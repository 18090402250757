/** @jsxImportSource @emotion/react */
import { jsx } from "@emotion/react";
import { useTheme } from "@emotion/react";
import React from "react";
import { useScreenSizeStore } from "stores";
import Paragraph from "components/Paragraph";
import TooltipWrapper from "components/TooltipWrapper";
import { addFlex, addSize } from "utils";

type Props = {
  count: number;
  title: string;
  tooltipTexts: string[];
  facilityId: string;
};

const MatchesSummaryCount: React.FC<Props> = ({
  count,
  title,
  tooltipTexts,
  facilityId,
}) => {
  const { screenIs } = useScreenSizeStore();
  const theme = useTheme();

  return (
    <div
      css={{
        position: "relative",
        ...addFlex({ x: "left", y: "center" }),
      }}
    >
      <TooltipWrapper
        texts={tooltipTexts}
        title={title}
        facilityId={facilityId}
        noWrap={true}
      >
        <div
          css={{
            position: "relative",
            ...addFlex({ x: "left", y: "center" }),
          }}
        >
          <div
            css={{
              ...addFlex({ x: "center", y: "center" }),
              ...addSize({
                width: screenIs({
                  default: { default: "45px", min: "45px" },
                  tablet: { default: "40px", min: "40px" },
                  mobile: { default: "30px", min: "30px" },
                }),
                height: screenIs({
                  default: "32px",
                  tablet: "25px",
                  mobile: "20px",
                }),
              }),
              position: "relative",
              background: theme.searchMatchesSummaryCount,
              borderRadius: "100px",
              color: "#fff",
              fontWeight: "bold",
              marginRight: "10px",
            }}
          >
            <Paragraph bold type="small">
              {count.toString()}
            </Paragraph>
          </div>
          <div css={{ position: "relative", width: "100%" }}>{title}</div>
        </div>
      </TooltipWrapper>
    </div>
  );
};

export default MatchesSummaryCount;
