/** @jsxImportSource @emotion/react */
import { jsx } from "@emotion/react";
import { useTheme } from "@emotion/react";
import React from "react";

import { useScreenSizeStore } from "stores/screenSize";
import BasicButton from "components/BasicButton";
import { addMargin, addFlex } from "utils";
import HyperLink from "components/HyperLink";
import LinkUnderline from "components/LinkUnderline";
import { createStyleSheet } from "utils/styles";
import BasicIcon from "components/BasicIcon";
import useStackedModals from "stores/modals";

type Props = {};

const YouVerifiedNote: React.FC<Props> = ({}) => {
  const { openNewModal } = useStackedModals();
  const theme = useTheme();

  const styles = createStyleSheet({
    container: {
      ...addFlex({ x: "left", y: "center", direction: "right" }),
    },
    text: {
      fontFamily: theme.fonts.main,
      fontStyle: "normal",
      fontWeight: "bold",
      fontSize: "14px",
      lineHeight: "20px",
      marginTop: "3px",
    },
    tickIconHolder: {
      ...addFlex({ x: "center", y: "center" }),
      marginRight: "10px",
    },
    link: { color: "#7cd2e1" },
  });

  return (
    <div css={styles.container}>
      <div css={styles.tickIconHolder}>
        <BasicIcon name="CheckInCircle" color="#2DA4AE" size={24} />
      </div>
      <div css={styles.text}>
        {`You verified this suggestion. `}

        <LinkUnderline
          onClick={() => {
            openNewModal({
              modalContentProps: {},
              modalContentName: "learnAboutVerifying",
              modalType: "popup",
            });
          }}
          label={"Learn more"}
          css={styles.link}
        >
          Learn more
        </LinkUnderline>
      </div>
    </div>
  );
};

export default YouVerifiedNote;
