/** @jsxImportSource @emotion/react */
import { jsx, css } from "@emotion/react";
import React, { useRef, useEffect } from "react";
import AutoHeightAnimator from "components/AutoHeightAnimator";
import { addFlex, addPadding, addMargin } from "utils";
import PlainRadioInput from "../PlainRadioInput";

type InputElementType = "text" | "phone" | "email" | "textArea" | "radio";

type RadioOption = { value: string; label: string };

type Props = {
  id: string;
  label: string;
  inputElementType?: InputElementType;
  required?: boolean;
  placeholder?: string;
  value: string | number;
  onChange: (newValue: string | number) => void;
  errors?: string[];
  onFocus: () => void;
  onBlur: () => void;
  radioOptions?: RadioOption[];
};

const SHARED_INPUT_CSS = css({
  background: "#FFFFFF",
  border: "1px solid #D5D5D5",
  boxSizing: "border-box",
  borderRadius: "4px",
  fontSize: "14px",
  lineHeight: "20px",
  padding: "14px",
  outline: "none",
  width: "100%",
});

const SendableFormInputWrapper: React.FC<Props> = ({
  id,
  label,
  inputElementType = "text",
  required = false,
  placeholder = "",
  value,
  onChange,
  errors = [],
  onFocus,
  onBlur,
  radioOptions,
}) => {
  const { current: localContext } = useRef({
    latestErrors: errors,
  });

  if (errors.length > 0) {
    localContext.latestErrors = [...errors];
  }
  return (
    <div
      css={{
        ...addFlex({ x: "left", y: "top", direction: "down" }),
        width: "100%",
        flexShrink: 0,
      }}
    >
      <label
        htmlFor={id}
        css={{
          fontWeight: "bold",
          fontSize: "14px",
          lineHeight: "20px",
          color: "#2F2F2F",
          ...addMargin({
            top: label ? "18px" : "0px",
            bottom: label ? "10px" : "0px",
          }),
        }}
      >
        {label}
      </label>
      {["text", "phone", "email"].includes(inputElementType) && (
        <input
          type={inputElementType === "phone" ? "tel" : inputElementType}
          id={id}
          placeholder={placeholder}
          required={required}
          css={SHARED_INPUT_CSS}
          value={value || ""}
          onChange={(event) => {
            onChange(event.target.value);
          }}
          onFocus={onFocus}
          onBlur={onBlur}
        />
      )}
      {inputElementType === "textArea" && (
        <textarea
          id={id}
          placeholder={placeholder}
          required={required}
          css={SHARED_INPUT_CSS}
          rows={4}
          value={value}
          onChange={(event) => {
            onChange(event.target.value);
          }}
          onFocus={onFocus}
          onBlur={onBlur}
        ></textarea>
      )}
      {inputElementType === "radio" &&
        radioOptions &&
        typeof value === "string" && (
          <PlainRadioInput
            inputId={id}
            options={radioOptions}
            value={value}
            onChange={(newValue) => {
              onChange(newValue);
            }}
            onFocus={onFocus}
            onBlur={onBlur}
          />
        )}

      <AutoHeightAnimator initialHeight={0.1}>
        <div css={{ color: "red", fontSize: "14px", position: "relative" }}>
          {errors.map((errorMessage) => (
            <span css={{ opacity: 0 }} key={errorMessage}>
              {errorMessage}
            </span>
          ))}
          <div
            aria-hidden="true"
            css={{
              position: "absolute",
              top: 0,
              left: 0,
              opacity: errors.length > 0 ? 1 : 0,
              transition: "opacity 0.2s ease-in-out",
              zIndex: 100,
            }}
          >
            {localContext.latestErrors.map((errorMessage) => (
              <span key={errorMessage}>{errorMessage}</span>
            ))}
          </div>
        </div>
      </AutoHeightAnimator>
    </div>
  );
};

export default SendableFormInputWrapper;
