// useFocus

import { useState, useRef, useCallback, useEffect } from "react";

// NOTE: to combine with useHover, can use <div ref={composeRefs(hoverRef, focusRef)} />
export default function useFocus() {
  const [value, setValue] = useState(false);

  const ref = useRef<
    HTMLButtonElement & HTMLDivElement & HTMLInputElement & HTMLAnchorElement
  >(null);

  const handleFocus = useCallback(() => setValue(true), []);
  const handleBlur = useCallback(() => setValue(false), []);

  useEffect(() => {
    const theNode = ref.current;
    if (theNode) {
      theNode.addEventListener("focus", handleFocus);
      theNode.addEventListener("blur", handleBlur);

      return () => {
        theNode.removeEventListener("focus", handleFocus);
        theNode.removeEventListener("blur", handleBlur);
      };
    }
  }, [handleBlur, handleFocus, ref]); // Recall only if ref changes

  return [ref, value, setValue] as [typeof ref, boolean, typeof setValue];
}
