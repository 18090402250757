/** @jsxImportSource @emotion/react */
import { jsx } from "@emotion/react";
import React, { useState } from "react";
import { useLocation } from "react-router-dom";
import {
  useScreenSizeStore,
  useFacilityStore,
  useCapabilitySuggestionStore,
} from "stores";

import { addFlex, addPadding } from "utils";
import { getValidationToken } from "services/validationToken";
import FormButtons from "components/SendableForm/FormButtons";
import { FeedbackType } from "stores/utils/storeMakers/makeModalStore/types";
import CaptchaNotice from "components/CaptchaNotice";
import { parseAndValidateHash } from "utils/routes";
import FormHeader from "components/SendableForm/FormHeader";
import MultiStepFormCount from "components/SendableForm/MultiStepFormCount";
import {
  getCapability,
  submitValidateAlternative,
  useShowNextModalAfterSubmittingEffect,
} from "components/SendableForm/utils/FormHelpers";
import { Equipment } from "utils/types";
import { NextFormAction } from "../types";

type Props = {
  feedBackTypeText?: string;
  feedBackType?: FeedbackType;
  nextAction?: NextFormAction;
};

const FORM_SUGGESTION_TYPE = "images" as const;

const SubmitImageForm: React.FC<Props> = () => {
  const { hash } = useLocation();
  const parsedHash = parseAndValidateHash(hash);
  const { screenIs, isDesktop } = useScreenSizeStore();

  const [facility] = useFacilityStore(({ facility }) => [facility]);

  const currentId = parsedHash && parsedHash.id ? parsedHash.id : undefined;

  const { title } = currentId
    ? getCapability(currentId, facility)
    : ({} as Equipment);

  const {
    suggestions,
    numberOfSuggestions,
    setSuggestionsConsidered,
    numberOfSuggestionsCurrentPage,
    setSuggestionsConfirmed,
  } = useCapabilitySuggestionStore();

  const currentStep = numberOfSuggestionsCurrentPage();

  const [isSubmitting, setIsSubmitting] = useState(false);
  const [isFormSubmittedSuccessfully, setIsFormSubmittedSuccessfully] =
    useState(false);

  useShowNextModalAfterSubmittingEffect(isFormSubmittedSuccessfully);

  return (
    <div
      css={{
        ...addFlex({ x: "left", y: "top" }),
        overflow: "auto",
        width: "100%",
        maxHeight: `${window.innerHeight - 30}px`,
      }}
    >
      <div
        css={{
          width: isDesktop ? "840px" : "100%",
          height: "auto",
        }}
      >
        <FormHeader capabilityName={title}>
          <h2 css={{ color: "#2F5673" }}>
            Is this an image of a{" "}
            <span css={{ fontStyle: "italic" }}>{title}</span>
          </h2>
          {numberOfSuggestions > 1 && (
            <MultiStepFormCount
              currentStep={currentStep}
              maxSteps={numberOfSuggestions}
            />
          )}
        </FormHeader>
        <div
          css={{
            ...addPadding(
              screenIs({
                default: {
                  top: "36px",
                  bottom: "0",
                  horizontal: "50px",
                },
                tablet: {
                  top: "36px",
                  bottom: "0",
                  horizontal: "40px",
                },
                mobile: { top: "0px", horizontal: "20px", bottom: "0" },
              })
            ),
          }}
        >
          <form
            onSubmit={async (e) => {
              e.preventDefault();
              setIsSubmitting(true);
              const validationToken =
                (await getValidationToken(`submitImageValidation`)) || "";
              await submitValidateAlternative(
                {
                  id:
                    suggestions.images && suggestions.images.length
                      ? suggestions.images[0].id
                      : "",
                  captcha: validationToken,
                },
                setIsFormSubmittedSuccessfully
              );

              setSuggestionsConsidered(FORM_SUGGESTION_TYPE, currentId);
              setSuggestionsConfirmed(FORM_SUGGESTION_TYPE);
              setIsSubmitting(false);
            }}
          >
            <div
              css={{
                display: "flex",
                flexDirection: "row",
                background: "#000",
                justifyContent: "center",
                padding: "22px 0",
              }}
            >
              {suggestions.images && !!suggestions.images?.length && (
                <img
                  src={suggestions.images[0].value}
                  alt={`is this a ${facility?.title}?`}
                  css={{
                    width: "100%",
                    padding: "20px",
                  }}
                />
              )}
            </div>
            <CaptchaNotice />
            <FormButtons
              submitText={"Yes"}
              cancelText={`No`}
              onCancelButton={() =>
                setSuggestionsConsidered(FORM_SUGGESTION_TYPE, currentId)
              }
              notSureCloseOption
              submitDisabled={false}
              isSubmitting={isSubmitting}
            />
          </form>
        </div>
      </div>
    </div>
  );
};

export default SubmitImageForm;
