/** @jsxImportSource @emotion/react */
import { jsx } from "@emotion/react";
import { useTheme } from "@emotion/react";
import React from "react";

import BasicButton from "components/BasicButton";
import { addSize } from "utils";
import { useScreenSizeStore } from "stores";
import { ScreenSizeHelperOptions } from "stores/screenSize";

type CommonProps = {
  label: string;
  children: string;
  automationId?: string;
  className?: string;
  fontSize?: string;
  onClick?: () => void;
  linkTo?: string;
  mailTo?: string;
  phoneTo?: string;
};

type Props =
  | (CommonProps & {
      linkTo: string;
      onClick?: () => void;
    })
  | (CommonProps & {
      onClick: () => void;
      linkTo?: string;
    })
  | (CommonProps & {
      mailTo: string;
    })
  | (CommonProps & {
      phoneTo: string;
    });

const HyperLink: React.FC<Props> = ({
  linkTo,
  mailTo,
  phoneTo,
  fontSize,
  onClick,
  children,
  label,
  automationId,
  className,
}) => {
  const { screenIs } = useScreenSizeStore();
  const theme = useTheme();

  const givenFontSize = fontSize
    ? fontSize
    : screenIs({ default: "14px", mobile: "12px" });

  const givenLineHeight = fontSize
    ? screenIs({ default: `${fontSize + 5}px`, mobile: `${fontSize + 5}px` })
    : "20px";

  return (
    <BasicButton
      key={automationId}
      automationId={automationId || label}
      label={label}
      linkTo={linkTo}
      mailTo={mailTo}
      phoneTo={phoneTo}
      onClick={onClick}
      isSubmitButton={false}
      css={{
        label: " HyperLink",
        ...addSize({ size: "auto" }),
        color: theme.mainColor,
        display: "inline-flex",
      }}
      innerCss={{
        padding: 0,
        textDecorationLine: "underline",
        fontStyle: "normal",
        fontWeight: "bold",
        fontSize: givenFontSize,
        lineHeight: givenLineHeight,
      }}
      className={className}
    >
      {children}
    </BasicButton>
  );
};

export default HyperLink;
