/** @jsxImportSource @emotion/react */
import { jsx } from "@emotion/react";
import React from "react";
import AddressBook from "icons/imageIcons/AddressBook";
import { addFlex } from "utils";
import Paragraph from "components/Paragraph";

type Props = {};

const NoContacts: React.FC<Props> = () => (
  <div
    css={{
      width: "100%",
      ...addFlex({ x: "center", y: "center" }),
      flexDirection: "column",
      padding: "80px 0",
    }}
  >
    <div css={{ maxWidth: "70px", height: "110px" }}>
      <AddressBook />
    </div>
    <Paragraph
      bold
      css={{ maxWidth: "190px", textAlign: "center", marginTop: "20px" }}
    >
      No contact information available
    </Paragraph>
  </div>
);

export default NoContacts;
