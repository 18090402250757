import { Features } from "../";

const features: Features = {
  trendingSearches: true,
  autoCompleteSearch: true,
  facilitiesDirectory: true,
  ncrisPrograms: true,
  aboutPage: true,
  submitEnquiry: true,
  sortOptions: true,
} as const;

export default features;
