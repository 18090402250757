import { useState, useRef, useEffect } from "react";

export default function useHover() {
  const [value, setValue] = useState(false);

  const ref = useRef<
    HTMLButtonElement & HTMLDivElement & HTMLInputElement & HTMLAnchorElement
  >(null);

  const handleMouseOver = () => {
    setValue(true);
  };
  const handleMouseOut = () => {
    setValue(false);
  };

  useEffect(() => {
    const theNode = ref.current;
    if (theNode) {
      theNode.addEventListener("mouseover", handleMouseOver);
      theNode.addEventListener("mouseout", handleMouseOut);

      return () => {
        theNode.removeEventListener("mouseover", handleMouseOver);
        theNode.removeEventListener("mouseout", handleMouseOut);
      };
    }
  }, [handleMouseOut, handleMouseOver, ref]); // Recall only if ref or the functions change

  return [ref, value, setValue] as [typeof ref, boolean, typeof setValue];
}
