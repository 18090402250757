/** @jsxImportSource @emotion/react */
import { jsx } from "@emotion/react";

import React, { useState } from "react";

import { useEffect, useRef, useCallback } from "react";

import shallow from "zustand/shallow";
import { useArrowKeyChoosableList } from "hooks";
import { hasFeature } from "services/featureToggle";
import { useSearchResultsStore, useSearchBarStore } from "stores";
import { addSize } from "utils";
import TrendingSearches from "components/TopFocusBox/SearchContent/TrendingSearches";
import AutocompleteSearches from "components/TopFocusBox/SearchContent/AutocompleteSearches";
import getAutoComplete from "services/api/autoComplete";
import getTrends, { Trend } from "services/api/trends";

type Props = {};

const canShowSearchHints =
  hasFeature("trendingSearches") || hasFeature("autoCompleteSearch");

let hasSeenAutoCompleteResponsesForTerm = false;

const SearchHintsOverlay: React.FC<Props> = React.memo(() => {
  const hintsScrollRef = useRef<HTMLDivElement>(null);

  const { current: localContext } = useRef({
    nextStepTimeout: null as null | number,
    previousText: null as null | string,
    previousIsShowingLoadingOverlay: null as null | boolean,
    previousShouldRender: null as null | boolean,
  });

  const [
    isFocused,
    searchText,
    updateSearchText,
    submitSearch,
    measuredSearchBar,
  ] = useSearchBarStore(
    (state) => [
      state.isFocused,
      state.searchText,
      state.updateSearchText,
      state.submitSearch,
      state.measuredSearchBar,
    ],
    shallow
  );

  const [isShowingLoadingOverlay] = useSearchResultsStore(
    (state) => [state.isShowingLoadingOverlay],
    shallow
  );

  const [autoComplete, setAutoComplete] = useState<string[]>([]);
  const [trends, setTrends] = useState<Trend[]>([]);

  if (autoComplete.length > 0) {
    hasSeenAutoCompleteResponsesForTerm = true;
  }

  if (searchText.length === 0) {
    hasSeenAutoCompleteResponsesForTerm = false;
  }

  const hintsType = !hasSeenAutoCompleteResponsesForTerm
    ? "trending"
    : "autocomplete";

  const [activeTrendingItem] = useArrowKeyChoosableList({
    items: trends.map(({ searchTerm }) => searchTerm),
    isActive: isFocused && hintsType === "trending",
  });

  const [activeAutocompleteItem] = useArrowKeyChoosableList({
    items: autoComplete,
    isActive: isFocused && hintsType === "autocomplete",
  });

  const submittableHintText =
    (hintsType === "trending" &&
      activeTrendingItem !== "" &&
      activeTrendingItem) ||
    (hintsType === "autocomplete" &&
      activeAutocompleteItem !== "" &&
      activeAutocompleteItem);

  const keyDownEventListener = useCallback(
    (event: KeyboardEvent) => {
      if (isFocused) {
        switch (event.key) {
          case "Enter":
            if (submittableHintText) {
              event.preventDefault();
              updateSearchText(submittableHintText);
              submitSearch();
            }
            break;
        }
      }
    },
    [isFocused, submitSearch, submittableHintText, updateSearchText]
  );

  useEffect(() => {
    document.addEventListener("keypress", keyDownEventListener);
    return () => {
      document.removeEventListener("keypress", keyDownEventListener);
    };
  }, [keyDownEventListener]);

  useEffect(() => {
    if (searchText.length) {
      getAutoComplete(searchText).then((result) => {
        setAutoComplete(result.autocomplete);
      });
    }
  }, [searchText]);

  useEffect(() => {
    getTrends().then((result) => {
      setTrends(result.trends);
    });
  }, []);

  if (!canShowSearchHints) {
    return null;
  }

  return (
    <div
      aria-label="Loading search results from multiple facilities"
      data-automation="search-hints-overlay"
      role="status"
      css={{
        ...addSize({
          width: "100%",
          height: `100vh`,
        }),
        position: "absolute",
        top: 0,
        left: 0,
        overflow: "visible",
        zIndex: 10,
        pointerEvents: "none",
      }}
    >
      <div
        ref={hintsScrollRef}
        id="TopFocusBox-SearchContent-popupContainer"
        aria-hidden={isFocused ? "false" : "true"}
        css={{
          label: "TopFocusBox-SearchContent-popupContainer",
          position: "absolute",
          top: 0,
          left: 0,
          ...addSize({ height: "fit-content" }),
          background: "white",
          transition:
            "opacity 0.25s ease-in-out, width 0.35s ease-in-out, transform 0.35s ease-in-out",

          borderRadius: "8px",
          boxShadow: "0px 0px 10px rgba(0,0,0,0.25)",
          zIndex: 100,
          "::-webkit-scrollbar": {
            width: 0,
            background: "transparent",
          },
          "::-scrollbar": {
            width: 0,
            background: "transparent",
          },
        }}
        style={{
          width: `${measuredSearchBar.width}px`,
          maxHeight: "630px",
          overflowY: "auto",
          opacity: isFocused && !isShowingLoadingOverlay ? 1 : 0,
          transform: `translate(${measuredSearchBar.left}px,${
            measuredSearchBar.bottom + 10 + (isFocused ? 0 : 0)
          }px)`,
          pointerEvents: isFocused ? "all" : "none",
        }}
      >
        {hintsType === "trending" ? (
          <TrendingSearches activeItem={activeTrendingItem} trends={trends} />
        ) : (
          <AutocompleteSearches
            activeItem={activeAutocompleteItem}
            hintsScrollRef={hintsScrollRef}
            autoComplete={autoComplete}
          />
        )}
      </div>
    </div>
  );
});

export default SearchHintsOverlay;
