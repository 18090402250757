/** @jsxImportSource @emotion/react */
import { jsx, css } from "@emotion/react";

import React from "react";

import allIcons, { AllIconNames } from "icons";

type Props = {
  name: AllIconNames;
  size: number;
  height?: number;
  color?: string;
  secondColor?: string;
  className?: string;
};

function pxToRem(pixelNumber: number) {
  return pixelNumber / 16 + "rem";
}

const BasicIcon: React.FC<Props> = ({
  name,
  size,
  height,
  className,
  color = "#3D3D3D",
  secondColor = "#D5D5D5",
}) => (
  <div
    css={{
      label: "BasicIcon",
      position: "relative",
      width: pxToRem(size),
      height: pxToRem(height || size),
      minWidth: pxToRem(size),
      minHeight: pxToRem(height || size),
    }}
    className={className}
  >
    {React.createElement(
      allIcons[name] as React.FC<{
        color: string;
        secondColor?: string;
        css: ReturnType<typeof css>;
      }>,
      {
        color,
        secondColor,
        css: css({
          maxWidth: "100%",
          maxHeight: "100%",
          position: "absolute",
          top: 0,
          left: 0,
        }),
      }
    )}
  </div>
);

/*


*/

export default BasicIcon;
