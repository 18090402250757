/** @jsxImportSource @emotion/react */
import { jsx, css } from "@emotion/react";
import React, { Fragment } from "react";
import Paragraph from "components/Paragraph";
import { useScreenSizeStore } from "stores";
import { Program, ProgramType } from "../../utils/types";
import { addFlex, addMargin } from "../../utils";
import { useTheme } from "@emotion/react";
import { getParseHTMLOptions } from "../../utils/parseHtml";
import parse from "html-react-parser";
import PATHS from "../../utils/routes";

type Props = {
  program: Program;
  programType: ProgramType;
};

const FacilityProgram: React.FC<Props> = ({ program, programType }) => {
  const { screenIs } = useScreenSizeStore();
  const theme = useTheme();
  const customParseHTMLOptions = getParseHTMLOptions(theme.mainColor);

  return program ? (
    <Fragment>
      <div
        css={{
          width: "100%",
          position: "relative",
          backgroundColor: "#EEF4F6",
          borderRadius: "8px",
          height: screenIs({
            desktop: "auto",
            tablet: "auto",
            mobile: "auto",
          }),
          flexDirection: screenIs({
            default: "row",
            tablet: "column",
            mobile: "column",
          }),
          ...addFlex(
            screenIs({
              default: { x: "center", y: "top", direction: "down" },
              mobile: { x: "left", y: "top", direction: "down" },
            })
          ),
          ...addMargin({
            bottom: "40px",
          }),
          padding: "24px",
        }}
      >
        <div
          css={{
            ...addFlex({ x: "left", y: "top", direction: "down" }),
            width: "100%",
          }}
        >
          {program.logo || programType.logo ? (
            <div
              css={{
                ...addFlex({ x: "left", y: "center" }),
                width: "100%",
              }}
            >
              {program.logo ? (
                <img
                  css={{
                    maxHeight: "70px",
                    maxWidth: screenIs({ default: "130px", mobile: "45%" }),
                    marginRight: "30px",
                  }}
                  src={program.logo}
                />
              ) : null}
              {programType.logo ? (
                <img
                  css={{
                    maxHeight: "70px",
                    maxWidth: screenIs({ default: "130px", mobile: "45%" }),
                  }}
                  src={programType.logo}
                />
              ) : null}
            </div>
          ) : null}
          <div
            css={{
              ...addFlex({ x: "left", y: "center" }),
              width: "100%",
              marginTop: "20px",
            }}
          >
            <span css={{ fontSize: "20px" }}>
              This facility is part of{" "}
              <a
                css={{ color: theme.mainColor }}
                href={`/program/${program.id}`}
              >
                {program.name}
              </a>
            </span>
          </div>
        </div>
        <div
          css={{
            width: "100%",
            marginTop: "10px",
          }}
        >
          <Paragraph
            css={{
              fontSize: "14px",
            }}
          >
            {program.summary && parse(program.summary, customParseHTMLOptions)}
            &nbsp;
            <a
              css={{ color: theme.mainColor, fontWeight: "bold" }}
              href={PATHS.programType.replace(":id", programType.id)}
            >
              View all {programType.name} funded programs
            </a>
          </Paragraph>
        </div>
      </div>
    </Fragment>
  ) : null;
};

export default FacilityProgram;
