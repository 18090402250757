/** @jsxImportSource @emotion/react */
import { jsx, css } from "@emotion/react";

import React from "react";

type Props = {
  color: string;
  css: ReturnType<typeof css>;
};

const GlassesIcon: React.FC<Props> = ({ color = "#3D3D3D", css }) => {
  return (
    <svg
      viewBox="0 0 24 18"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      css={css}
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M24 9.99994l-1.863-7.26315c-.2192-.94737-.8767-1.789478-1.7534-2.210531-.8768-.421053-1.9726-.52631581-2.9589-.210526l-.6576.210526c-.3287.105263-.5479.526311-.4383.842101l.1096.42106c.1095.31579.5479.52631.8767.42105l.5479-.21053c.4384-.10526.9863-.10526 1.4247.10527.4383.21052.7671.63158.8767 1.05263l1.6438 6.21052c-.8767-.31579-2.0822-.52631-3.3972-.52631-1.6439 0-3.6165.42105-5.5891 1.47365h-1.5342C9.31507 9.2631 7.45205 8.84205 5.69863 8.84205c-1.31507 0-2.41096.21052-3.39726.42105l1.64384-6.10526c.10958-.42105.43835-.84211.76712-1.05263.43836-.21053.87671-.21053 1.42466-.10527l.54794.21053c.32877.10526.76712-.10526.87671-.42105l.21918-.63158c.10959-.315792-.10959-.631581-.43835-.736844L6.68493.210469c-.9863-.315789-1.9726-.21052581-2.9589.210527C2.84932.947312 2.19178 1.68415 1.9726 2.63152L.109589 9.99994C0 10.1052 0 10.3157 0 10.5263v2.8421c0 2.5263 2.19178 4.5263 4.82192 4.5263h1.53424c2.52055 0 4.60274-1.7895 4.82194-4.2105l.1096-1.579h1.5342l.1096 1.579c.2192 2.421 2.3014 4.2105 4.8219 4.2105h1.5343c2.6301 0 4.8219-2 4.8219-4.5263v-2.8421C24 10.3157 24 10.1052 24 9.99994zM9.09589 13.5789c-.10959 1.3684-1.31507 2.421-2.84932 2.421H4.82192c-1.53425 0-2.84932-1.1579-2.84932-2.6315v-1.8948c.87671-.3158 2.08219-.7368 3.72603-.7368 1.20548 0 2.41096.2105 3.61644.7368l-.21918 2.1053zm12.93151-.2105c0 1.4736-1.2055 2.6315-2.8493 2.6315h-1.5343c-1.4246 0-2.7397-1.0526-2.8493-2.421l-.2192-2.1053c1.2055-.5263 2.411-.7368 3.6165-.7368 1.5342 0 2.8493.421 3.726.7368v1.8948h.1096z"
        fill={color}
      />
    </svg>
  );
};

export default GlassesIcon;
