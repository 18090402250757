/** @jsxImportSource @emotion/react */
import { jsx, css } from "@emotion/react";

import React from "react";

type Props = {
  color: string;
  css: ReturnType<typeof css>;
};

const ArrowIcon: React.FC<Props> = ({ color = "#3D3D3D", css }) => {
  return (
    <svg
      viewBox="0 0 14 8"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      css={css}
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M.292893.292893c.390524-.3905241 1.023687-.3905241 1.414217 0L7 5.58579 12.2929.292893c.3905-.3905241 1.0237-.3905241 1.4142 0 .3905.390524.3905 1.023687 0 1.414217l-5.99999 6c-.39053.39052-1.02369.39052-1.41422 0l-5.999997-6c-.3905241-.39053-.3905241-1.023693 0-1.414217z"
        fill={color}
      />
    </svg>
  );
};

export default ArrowIcon;
