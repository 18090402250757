/** @jsxImportSource @emotion/react */
import { jsx } from "@emotion/react";
import React from "react";
import { addFlex, addPadding } from "utils";
import { useScreenSizeStore } from "stores";

type Props = { searchTerm: string };

const SearchTerm: React.FC<Props> = ({ searchTerm }) => {
  const { screenIs } = useScreenSizeStore();

  return (
    <div
      css={{
        ...addFlex({ x: "center", y: "center" }),
        ...addPadding({
          horizontal: screenIs({ default: "10px", mobile: "4px" }),
        }),
        background: "#EEF4F6",
        borderRadius: "4px",
        height: screenIs({ default: "36px", mobile: "25px" }),
        // desktop paragraph (small)
        fontWeight: "bold",
        fontSize: screenIs({ default: "14px", mobile: "10px" }),
        color: "#616161",
      }}
    >
      {`“${searchTerm}”`}
    </div>
  );
};

export default SearchTerm;
