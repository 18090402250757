/** @jsxImportSource @emotion/react */
import { jsx, CSSObject } from "@emotion/react";
import { useTheme } from "@emotion/react";
import React, { CSSProperties } from "react";

import BasicButton from "components/BasicButton";

type CommonProps = {
  label: string;
  automationId?: string;
  className?: string;
  onClick?: () => void;
  linkTo?: string;
  disabled?: boolean;
  innerSpanCss?: Pick<CSSObject, keyof CSSProperties & { label: string }>;
  children: React.ReactNode;
};

type Props =
  | (CommonProps & {
      linkTo: string;
      onClick?: () => void;
    })
  | (CommonProps & {
      onClick: () => void;
      linkTo?: string;
    });

const LinkUnderline: React.FC<Props> = ({
  linkTo,
  onClick,
  children,
  label,
  automationId,
  className,
  disabled,
  innerSpanCss = {},
}) => {
  const theme = useTheme();

  return (
    <BasicButton
      key={automationId}
      automationId={automationId || label}
      label={label}
      linkTo={linkTo}
      onClick={onClick}
      isSubmitButton={false}
      disabled={disabled}
      css={{
        width: "auto",
        height: "auto",
        color: theme.mainColor,
        display: "inline-flex",
        overflow: "visible",
        maxWidth: "100%",
      }}
      innerCss={{
        position: "relative",
        padding: 0,
        paddingBottom: "4px",
        textAlign: "left",
        maxWidth: "100%",
      }}
      className={className}
    >
      <span
        css={{
          position: "relative",
          paddingBottom: "0px",
          borderBottom: `1px solid ${theme.mainColor}`,
          borderEndEndRadius: "3px",
          lineHeight: 1.4,
          ...innerSpanCss,
        }}
      >
        {children}
      </span>
    </BasicButton>
  );
};

export default LinkUnderline;
