export function translate3d(x: number, y: number, z: number): string {
  return `translate3d(${x}px, ${y}px, ${z}px);`;
}

type WrapOptions = "wrap" | "nowrap" | "wrap-reverse";
type AlignItemOptions = "center" | "flex-start" | "flex-end";
type JustifyContentOptions =
  | "center"
  | "flex-start"
  | "flex-end"
  | "space-around"
  | "space-between";

type WrapProperty = {
  wrap?: WrapOptions;
};

type AddFlexOptions =
  | {
      direction?: "right";
      x?: "left" | "right" | "center" | "spaced" | "edges";
      y?: "top" | "bottom" | "center";
    }
  | {
      direction: "right";
      x?: "left" | "right" | "center" | "spaced" | "edges";
      y?: "top" | "bottom" | "center";
    }
  | {
      direction: "down";
      x?: "left" | "right" | "center";
      y?: "top" | "bottom" | "center" | "spaced" | "edges";
    }
  | {
      direction: "down" | "right";
      x?: "left" | "right" | "center";
      y?: "top" | "bottom" | "center";
    };

export function addFlex({
  x,
  y,
  direction = "right",
  wrap = "nowrap",
}: AddFlexOptions & WrapProperty) {
  let alignItems: AlignItemOptions = "center";
  let justifyContent: JustifyContentOptions = "flex-start";

  if (direction === "down") {
    switch (x) {
      case "center":
        alignItems = "center";
        break;
      case "left":
        alignItems = "flex-start";
        break;
      case "right":
        alignItems = "flex-end";
        break;
    }

    let justifyContent = "flex-start";
    switch (y) {
      case "center":
        justifyContent = "center";
        break;
      case "top":
        justifyContent = "flex-start";
        break;
      case "bottom":
        justifyContent = "flex-end";
        break;
      case "spaced":
        justifyContent = "space-around";
        break;
      case "edges":
        justifyContent = "space-between";
        break;
    }

    return {
      display: "flex" as "flex",
      flexDirection: "column" as "column",
      alignItems,
      justifyContent,
      flexWrap: wrap,
    };
  }
  if (direction === "right") {
    switch (x) {
      case "center":
        justifyContent = "center";
        break;
      case "left":
        justifyContent = "flex-start";
        break;
      case "right":
        justifyContent = "flex-end";
        break;
      case "spaced":
        justifyContent = "space-around";
        break;
      case "edges":
        justifyContent = "space-between";
        break;
    }

    let alignItems = "center";
    switch (y) {
      case "center":
        alignItems = "center";
        break;
      case "top":
        alignItems = "flex-start";
        break;
      case "bottom":
        alignItems = "flex-end";
        break;
    }

    return {
      display: "flex" as "flex",
      flexDirection: "row" as "row",
      alignItems,
      justifyContent,
      flexWrap: wrap,
    };
  }

  return {
    display: "flex",
  };
}

type AddPaddingOptions = {
  top?: string;
  left?: string;
  bottom?: string;
  right?: string;
  horizontal?: string;
  vertical?: string;
  default?: string;
};

export function addPadding({
  top,
  left,
  bottom,
  right,
  horizontal,
  vertical,
  default: __default,
}: AddPaddingOptions) {
  return {
    paddingTop: top ?? vertical ?? __default,
    paddingBottom: bottom ?? vertical ?? __default,
    paddingLeft: left ?? horizontal ?? __default,
    paddingRight: right ?? horizontal ?? __default,
  };
}

export function addMargin({
  top,
  left,
  bottom,
  right,
  horizontal,
  vertical,
  default: __default,
}: AddPaddingOptions) {
  return {
    marginTop: top ?? vertical ?? __default,
    marginBottom: bottom ?? vertical ?? __default,
    marginLeft: left ?? horizontal ?? __default,
    marginRight: right ?? horizontal ?? __default,
  };
}

type AddSizeOptionsValue = number | string | undefined;

type AddSizeOptionsPropertyObject = {
  min?: AddSizeOptionsValue;
  default?: AddSizeOptionsValue;
  max?: AddSizeOptionsValue;
};

type AddSizeOptionsProperty =
  | AddSizeOptionsPropertyObject
  | AddSizeOptionsValue;

type AddSizeOptions = {
  width?: AddSizeOptionsProperty;
  height?: AddSizeOptionsProperty;
  size?: AddSizeOptionsProperty;
};

function normalizeAddSizeOptionsProperty(
  theValue: AddSizeOptionsValue | AddSizeOptionsProperty
): AddSizeOptionsPropertyObject {
  if (theValue === undefined) {
    return { min: undefined, default: undefined, max: undefined };
  }

  if (typeof theValue === "string" || typeof theValue === "number") {
    return { min: undefined, default: theValue, max: undefined };
  }

  return { min: theValue.min, default: theValue.default, max: theValue.max };
}

export function addSize({ width, height, size }: AddSizeOptions) {
  const editedWidth = normalizeAddSizeOptionsProperty(width);
  const editedHeight = normalizeAddSizeOptionsProperty(height);
  const editedBoth = normalizeAddSizeOptionsProperty(size);

  return {
    minWidth: editedWidth.min ?? editedBoth.min,
    width: editedWidth.default ?? editedBoth.default,
    maxWidth: editedWidth.max ?? editedBoth.max,
    minHeight: editedHeight.min ?? editedBoth.min,
    height: editedHeight.default ?? editedBoth.default,
    maxHeight: editedHeight.max ?? editedBoth.max,
  };
}

// TODO: Could moves the utils above to utils/styles

export function getRandomArrayItem<T_ArrayItem>(theArray: T_ArrayItem[]) {
  return theArray[Math.floor(Math.random() * theArray.length)];
}

export function stripHtml(html: string) {
  let tmp = document.createElement("DIV");
  tmp.innerHTML = html;
  return tmp.textContent || tmp.innerText || "";
}