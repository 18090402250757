/** @jsxImportSource @emotion/react */
import { jsx } from "@emotion/react";
import { useTheme } from "@emotion/react";
import React from "react";
import Paragraph from "components/Paragraph";
import BasicButton from "components/BasicButton";
import arinConfusedImage from "assets/images/arinConfused.png";
import singascopeImage from "assets/images/singascopeImage.png";
import { useScreenSizeStore } from "stores";
import { addFlex } from "utils";
import useStackedModals from "stores/modals";

type Props = {
  searchTerm: string | undefined;
  showImage: boolean;
  showEnquiryButton?: boolean;
};

const NoSearchResults: React.FC<Props> = ({
  searchTerm,
  showEnquiryButton,
  showImage,
}) => {
  const { screenIs, isMobile } = useScreenSizeStore();
  const theme = useTheme();
  const { openNewModal } = useStackedModals();

  const imageToDisplay = () => {
    if (process.env.REACT_APP_TENANT === "singascope") {
      return singascopeImage;
    }
    return arinConfusedImage;
  };

  return (
    <div
      css={{
        minHeight: "100%",
        width: "100%",
        position: "relative",
        ...addFlex(
          screenIs({
            default: { x: "center", y: "center", direction: "right" },
            mobile: { x: "center", y: "center", direction: "down" },
          })
        ),
      }}
      data-automation="noSearchResults"
    >
      {showImage ||
        (process.env.REACT_APP_TENANT !== "monashUni" && (
          <div
            css={{
              width: isMobile ? "100%" : "40%",
              minHeight: "100px",
              flexShrink: 0,
            }}
          >
            <img
              src={imageToDisplay()}
              alt=""
              css={{
                width: isMobile ? "90px" : "220px",
                display: "flex",
                margin: isMobile ? "10px 0" : "auto",
              }}
            />
          </div>
        ))}

      <div css={{ width: isMobile ? "100%" : "60%" }}>
        <h2 css={{ color: theme.mainColor, marginBottom: "23px" }}>
          Your search term
          <span css={{ color: "#000000", wordWrap: "break-word" }}>
            {" "}
            {searchTerm || ""}{" "}
          </span>
          did not match any results.
        </h2>
        <Paragraph>Suggestions:</Paragraph>
        <ul css={{ marginTop: "16px", lineHeight: "35px" }}>
          <li>Make sure that all words are spelled correctly.</li>
          <li>Try different keywords.</li>
          <li>Try more general keywords.</li>
        </ul>
        {showEnquiryButton && (
          <div>
            <BasicButton
              label="Submit an enquiry"
              onClick={() => {
                openNewModal({
                  modalContentProps: {
                    formName: "submitEnquiry",
                  },
                  modalContentName: "sendableForm",
                  modalType: "popup",
                });
              }}
              css={{
                border: `1px solid ${theme.mainColor}`,
                backgroundColor: theme.mainColor,
                width: "100%",
                maxWidth: isMobile ? "100%" : "300px",
                height: "40px",
                label: "submit-an-enquiry",
                borderRadius: "8px",
                marginTop: "10px",
              }}
              innerCss={{
                whiteSpace: "nowrap",
                fontWeight: "bold",
                color: "white",
                width: "100%",
              }}
            >
              Submit an enquiry
            </BasicButton>
          </div>
        )}
      </div>
    </div>
  );
};

export default NoSearchResults;
