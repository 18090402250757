/** @jsxImportSource @emotion/react */
import { jsx, css } from "@emotion/react";
import React, { useState, Fragment } from "react";

import BasicIcon from "components/BasicIcon";
import { useScreenSizeStore } from "stores";
import RadioInputOption from "../RadioInputOption";

type Props = {
  inputId: string;
  options: { value: string; label: string }[];
  onChange: (newValue: string) => void;
  value: string;
};

const FeedbackRadioInput: React.FC<Props> = ({
  inputId,
  options,
  onChange,
  value,
}) => {
  const { isTablet, screenIs } = useScreenSizeStore();

  return (
    <div css={{ display: "flex", flexDirection: isTablet ? "row" : "column" }}>
      {options.map((loopedOption, index) => {
        const isLastOption = index === options.length - 1;

        return (
          <Fragment key={loopedOption.label + loopedOption.value}>
            <label
              onClick={() => {
                onChange(loopedOption.value);
              }}
              css={{
                display: "flex",
                background: "#fff",
                borderRadius: "8px",
                padding: "10px",
                margin: isTablet ? "5px" : "5px 0",
                width: "100%",
                cursor: "pointer",
              }}
            >
              <RadioInputOption
                name={inputId}
                id={loopedOption.value}
                activeOption={value}
                value={loopedOption.label}
                css={{ marginRight: "10px" }}
              />
              <span css={{ lineHeight: 1.4 }}>{loopedOption.label}</span>
            </label>
          </Fragment>
        );
      })}
    </div>
  );
};

export default FeedbackRadioInput;
