import { TenantThemeVars } from "../tenant";

const arinTheme: TenantThemeVars = {
  mainColor: "#228192",
  topFocusBoxHeading: "#2F5673",
  topFocusBoxBackground:
    "linear-gradient(148.22deg, #7CD1E0 33.47%, #1795AB 137.4%)",
  softBackground: "#EEF4F6",
  locationPinColor: "#2F5673",
  searchBarBorder: "#2F5673",
  searchBarBorderFocused: "#3c6d91",
  searchBarHover: "#20425A",
  searchOverlayBackground: "#2F5673",
  searchOverlayBorder: "#EEF4F6",
  inputCheckboxColor: "#228192",
  feedbackButtonActive: "#88BBC4",
  feedbackButtonInactive: "#228192",
  staticSearchBorder: "#2F5673",
  resultsOverlayTextColor: "#50c3e0",
  searchMatchesSummaryCount: "#228192",
  footerAccent: "#228192",
  fonts: {
    googleFontsLink:
      "https://fonts.googleapis.com/css2?family=Muli:ital,wght@0,400;0,700;0,800;1,700&family=Roboto+Mono&display=swap",
    globalFontFamily: "'Muli', sans-serif;",
    heading: "Muli",
    main: "Muli",
  },
};

export default arinTheme;
