import { gql } from "apollo-boost";
import Api from "services/api";

type SubmitTrackUserEventResponse = {
  validateAlternative: boolean | null;
};

type SubmitTrackUserEventType = {
  id: string;
  captcha: string;
};

const validateAlternative = ({ id, captcha }: SubmitTrackUserEventType) =>
  Api.mutate<SubmitTrackUserEventResponse>({
    variables: {
      id,
      captcha,
    },
    mutation: gql`
      mutation validateAlternative($id: String!, $captcha: String!) {
        validateAlternative(id: $id, captcha: $captcha)
      }
    `,
  }).then((result) => result.data);

export default validateAlternative;
