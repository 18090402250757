/** @jsxImportSource @emotion/react */
import { jsx } from "@emotion/react";
import React, { useEffect, useState } from "react";
import Paragraph from "components/Paragraph";
import shallow from "zustand/shallow";
import { useScreenSizeStore, useSearchBarStore } from "stores";
import { addFlex, addMargin, addSize } from "utils";
import DescriptionColumn from "components/DescriptionColumn";
import HomepageBanner from "components/HomepageBanner";

import arinMedalImage from "assets/images/arinMedalSmall.png";
import arinAmbitionImage from "assets/images/arinAmbitionSmall.png";
import arinFacilityImage from "assets/images/arinFacilityIcon.png";
import ncrisImage from "assets/images/ncrisLogo.svg";
import { TenantConfig } from "../../services/api/tenantConfig";
import getTenantConfig from "../../services/api/tenantConfig";
import PATHS from "../../utils/routes";
import localTenantConfig from "../../tenants";

const ncrisIsNew = true;

type Props = {};

const HomeContent: React.FC<Props> = () => {
  const { screenIs } = useScreenSizeStore();

  const [isFocused] = useSearchBarStore((state) => [state.isFocused], shallow);

  const [tenantConfig, setTenantConfig] = useState(null as null | TenantConfig);
  useEffect(() => {
    async function fetchTenantConfig() {
      const res = await getTenantConfig();
      setTenantConfig(res.data.getTenantConfig);
    }
    fetchTenantConfig();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <div
      css={{
        position: "relative",
        top: 0,
        left: 0,
        width: "100%",
        ...addFlex({ x: "left", y: "top", direction: "down" }),
        opacity: isFocused ? 0.35 : 1,
        transition: "opacity .5s",
      }}
    >
      <h4
        css={{
          ...addMargin({
            top: screenIs({
              default: "80px",
              tablet: "62px",
              mobile: "30px",
            }),
            bottom: screenIs({
              default: "20px",
              tablet: "17px",
              mobile: "15px",
            }),
          }),
        }}
      >
        What does Arin do?
      </h4>
      <Paragraph type="intro" css={{ width: "100%" }}>
        Participating research organisations allow Arin to read their equipment
        booking engines, and Arin presents the results to you. You then directly
        contact the facility to arrange booking.
      </Paragraph>
      <div
        css={{
          ...addSize({ width: "100%", height: "1px" }),
          background: screenIs({ default: "#D5D5D5", tablet: "#EEF4F6" }),
          marginTop: screenIs({
            default: "55px",
            tablet: "40px",
            mobile: "30px",
          }),
        }}
      ></div>
      <div
        css={{
          display: "flex",
          flexDirection: screenIs({
            default: "row",
            tablet: "column",
            mobile: "column",
          }),
        }}
      >
        <DescriptionColumn
          image={arinMedalImage}
          headline={`Arin has ambition.`}
          text={`The Australian Research Infrastructure Network (Arin) aims to
          facilitate improved utilisation of the research network available
          across organisations in Australia.`}
          learnMore={{
            label: "Learn more about Arin",
            linkText: "Learn more",
            href: "/about",
            type: "hyperLink",
          }}
        />
        <DescriptionColumn
          image={arinAmbitionImage}
          headline={`Arin learns from you.`}
          text={`The Arin portal learns to improve search results through machine learning algorithms. We need humans to improve the quality of search results.`}
          learnMore={{
            label: "find out how you can train Arin",
            linkText: "Train Arin!",
            href: "/about",
            type: "hyperLink",
          }}
        />
        <DescriptionColumn
          image={arinFacilityImage}
          headline={`Are you a Facility Manager?`}
          text={`Arin connects equipment and services to researchers and industry professionals. Grow your audience and improve your facility’s online presence.`}
          learnMore={{
            label: "contact ARIN and add your facility",
            linkText: "List your facility on Arin!",
            href: "info@arin.org.au",
            type: "mailTo",
          }}
        />
      </div>

      {tenantConfig?.programs?.enabled &&
      tenantConfig?.programs?.ncrisInHomePage ? (
        <HomepageBanner
          isNew={ncrisIsNew}
          image={ncrisImage}
          headline={`NCRIS facilities are now available on Arin.`}
          text={`NCRIS is a national network of world-class research infrastructure projects that support high-quality research that will drive greater innovation in the Australian research sector.
    NCRIS supported facilities and programs help better connect researchers to high quality research outcomes, faster.`}
          learnMore={{
            label: "Learn more",
            linkText: "Learn more",
            href: PATHS.programType.replace(
              ":id",
              localTenantConfig.ncrisProgramTypeId || ""
            ),
            type: "hyperLink",
          }}
        />
      ) : null}
    </div>
  );
};

export default HomeContent;
