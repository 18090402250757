/** @jsxImportSource @emotion/react */
import { jsx } from "@emotion/react";
import { useTheme } from "@emotion/react";
import React from "react";
import BasicButton from "components/BasicButton";
import BasicIcon from "components/BasicIcon";
import { useScreenSizeStore } from "stores";
import { addSize } from "utils";

type Props = {
  textSearch: string;
  setTextSearch: (value: string) => void;
};

const StaticSearchBar: React.FC<Props> = ({ textSearch, setTextSearch }) => {
  const { screenIs } = useScreenSizeStore();
  const theme = useTheme();

  return (
    <div
      css={{
        width: "100%",
        height: "70px",
        margin: "20px 0",
      }}
    >
      <input
        css={{
          border: `2px solid ${theme.staticSearchBorder}`,
          boxSizing: "border-box",
          borderRadius: "8px",
          width: "100%",
          height: "70px",
          fontWeight: "bold",
          fontSize: "18px",
          lineHeight: "30px",
          padding: "0 25px",
          outline: "none",
        }}
        type={`text`}
        value={textSearch}
        onChange={(e) => setTextSearch(e.target.value)}
      />
      <BasicButton
        label="clear"
        css={{
          position: "absolute",
          top: "55px",
          right: "95px",
          transform: "translateY(-50%)",
          width: "30px",
          height: "70px",
          transition: "opacity 0.3s ease-in-out",
          opacity: textSearch.length > 0 ? 1 : 0,
          pointerEvents: textSearch.length === 0 ? "none" : "all",
        }}
        onClick={() => {
          setTextSearch("");
        }}
        disabled={textSearch.length === 0}
      >
        <BasicIcon
          name="Close"
          color="#949494"
          size={screenIs({ default: 40, tablet: 34, mobile: 26 })}
        />
      </BasicButton>

      <BasicButton
        label="search"
        css={{
          ...addSize({
            width: { default: "70px", min: "70px" },
            height: { default: "70px", min: "70px" },
          }),
          transition: "background-color 0.3s ease-in-out",
          backgroundColor: theme.staticSearchBorder,
          color: "white",
          borderTopRightRadius: "8px",
          borderBottomRightRadius: "8px",
          position: "absolute",
          right: 0,
          top: "20px",
        }}
        isSubmitButton
        hoverColor="#20425A"
        disabled
      >
        <BasicIcon
          name="Search"
          color="white"
          size={screenIs({ default: 34, tablet: 30, mobile: 24 })}
        />
      </BasicButton>
    </div>
  );
};

export default StaticSearchBar;
