import {
  useRef,
  useEffect,
  useState,
  useLayoutEffect,
  useCallback,
} from "react";

export default function useArrowKeyChoosableList({
  items,
  isActive,
  hintsScrollRef,
  autoSelectFirstItem = false,
}: {
  items: string[];
  isActive: boolean;
  hintsScrollRef?: HTMLDivElement;
  autoSelectFirstItem?: boolean;
}) {
  const { current: localContext } = useRef({
    itemsOrder: items,
  });

  useEffect(() => {
    localContext.itemsOrder = items;
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [items]);

  const [chosenItem, setChosenItem] = useState(
    autoSelectFirstItem ? localContext.itemsOrder[0] : ""
  );

  useLayoutEffect(() => {
    if (!autoSelectFirstItem && isActive === false) {
      setChosenItem("");
    }
  }, [autoSelectFirstItem, isActive]);

  const currentIndex =
    chosenItem !== "" ? localContext.itemsOrder.indexOf(chosenItem) : -1;
  const lastItemIndex = items.length - 1;

  const chooseNextItem = useCallback(() => {
    let newIndex = currentIndex + 1;
    if (newIndex > lastItemIndex) {
      newIndex = 0;
    }
    setChosenItem(localContext.itemsOrder[newIndex]);
  }, [currentIndex, lastItemIndex, localContext.itemsOrder]);

  const choosePreviousItem = useCallback(() => {
    let newIndex = currentIndex - 1;
    if (newIndex < 0) {
      newIndex = lastItemIndex;
    }
    setChosenItem(localContext.itemsOrder[newIndex]);
  }, [currentIndex, lastItemIndex, localContext.itemsOrder]);

  const keyDownEventListener = useCallback(
    (event: KeyboardEvent) => {
      if (isActive) {
        switch (event.key) {
          case "ArrowDown":
            event.preventDefault();
            chooseNextItem();
            break;
          case "ArrowUp":
            event.preventDefault();
            choosePreviousItem();
            break;
          case "Escape":
            setChosenItem("");
            break;
        }
      }
    },
    [chooseNextItem, choosePreviousItem, isActive]
  );

  useEffect(() => {
    document.addEventListener("keydown", keyDownEventListener);
    return () => {
      document.removeEventListener("keydown", keyDownEventListener);
    };
  }, [keyDownEventListener]);

  return [chosenItem];
}
