import { useModalStore } from "./modal";
import { useModalSecondStore } from "./modalSecond";
import { OpenModalOptions } from "stores/utils/storeMakers/makeModalStore/types";
/*
Handles managing opening modals based on how many are stacked

NOTE: Ideally this could handle any number of modals, for now theres two manually made modal stores

*/

function getOpenModalLevel() {
  const { animationState: animationStateOne } = useModalStore.getState();
  const { animationState: animationStateTwo } = useModalSecondStore.getState();

  const modalOneOpen = !["closing", "closed"].includes(animationStateOne);
  const modalTwoOpen = !["closing", "closed"].includes(animationStateTwo);

  if (modalOneOpen && !modalTwoOpen) {
    return 1;
  }
  if (modalTwoOpen) {
    return 2;
  }

  return 0;
}

function replaceTopModal(options: OpenModalOptions) {
  const { openModal: openModalOne, closeModal: closeModalOne } =
    useModalStore.getState();
  const { openModal: openModalTwo, closeModal: closeModalTwo } =
    useModalSecondStore.getState();
  const modalLevel = getOpenModalLevel();
  switch (modalLevel) {
    case 0: // open new modal
      openModalOne(options);
      break;
    case 1: // close modal one then open with new options
      closeModalOne();
      setTimeout(() => openModalOne(options), 250);
      break;
    case 2: // close modal two then open with new options
      closeModalTwo();
      setTimeout(() => openModalTwo(options), 250);
      break;
  }
}

function openNewModal(options: OpenModalOptions) {
  const { openModal: openModalOne } = useModalStore.getState();
  const { openModal: openModalTwo } = useModalSecondStore.getState();

  const modalLevel = getOpenModalLevel();

  switch (modalLevel) {
    case 0: // open new modal one
      openModalOne(options);
      break;
    case 1: // open new modal two
      openModalTwo(options);
      break;
    case 2: // replace modal 2
      replaceTopModal(options);
      break;
  }
}

function closeTopModal() {
  const { closeModal: closeModalOne } = useModalStore.getState();
  const { closeModal: closeModalTwo } = useModalSecondStore.getState();

  const modalLevel = getOpenModalLevel();

  switch (modalLevel) {
    case 1: // close modal 1
      closeModalOne();
      break;
    case 2: // close modal 2
      closeModalTwo();
      break;
  }
}

function closeAllModals() {
  const { closeModal: closeModalOne } = useModalStore.getState();
  const { closeModal: closeModalTwo } = useModalSecondStore.getState();

  const modalLevel = getOpenModalLevel();

  if (modalLevel !== 0) {
    closeModalTwo();
    closeModalOne();
  }
}

export default function useStackedModals() {
  // using a hook instead of importing functions directly to follow farmilliar useModalStore pattern
  // and to allow rerendering for passing a value like stackedModalAmount if needed in the future

  return {
    replaceTopModal,
    openNewModal,
    closeTopModal,
    closeAllModals,
  };
}

const stackedModalsApi = {
  replaceTopModal,
  openNewModal,
  closeTopModal,
  closeAllModals,
};

export { stackedModalsApi };
