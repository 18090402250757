/** @jsxImportSource @emotion/react */
import { jsx } from "@emotion/react";
import { useTheme } from "@emotion/react";
import React, { useRef, Fragment } from "react";
import { Trend } from "services/api/trends";
import { useScreenSizeStore } from "stores";
import { addFlex, addMargin, addPadding } from "utils";
import SearchTermSummary from "components/SearchTermSummary";

type Props = {
  activeItem: string;
  trends: Trend[];
};

function truncateSearchTermsForScreenSize(
  trends: Trend[],
  maxTerms: number
): Trend[] {
  return trends.slice(0, maxTerms);
}

const TrendingSearches: React.FC<Props> = React.memo(
  ({ trends, activeItem }) => {
    const { isMobile, screenIs } = useScreenSizeStore();
    const theme = useTheme();

    const activeItemRef = useRef<HTMLLIElement>(null);

    const MAX_NUMBERS_OF_TERMS_TO_SHOW = screenIs({
      desktop: 15,
      tablet: 10,
      mobile: 8,
    });

    if (!trends) {
      return <Fragment />;
    }
    return (
      <div
        css={{
          background: theme.searchOverlayBorder,
          border: `2px solid ${theme.searchOverlayBackground}`,
          boxSizing: "border-box",
          boxShadow: "0px 2px 16px rgba(253, 128, 84, 0.16)",
          borderRadius: "8px",
          display: "flex",
          padding: "25px",
          flexDirection: "column",
        }}
      >
        <label
          id="TopFocusBox-SearchContent-popupContainer-label"
          htmlFor="TopFocusBox-SearchContent-popupContainer-currentListbox"
        >
          <h5 css={{ marginBottom: "12px" }}>Trending searches</h5>
        </label>
        <ul
          role="listbox"
          id="TopFocusBox-SearchContent-popupContainer-currentListbox"
          aria-labelledby="TopFocusBox-SearchContent-popupContainer-label"
          css={{
            ...addFlex({
              x: "left",
              y: "top",
              direction: isMobile ? "down" : "right",
            }),
            listStyleType: "none",
            flexWrap: "wrap",
            ...addMargin({ default: "0" }),
            ...addPadding({ default: "0" }),
          }}
        >
          {truncateSearchTermsForScreenSize(
            trends,
            MAX_NUMBERS_OF_TERMS_TO_SHOW
          ).map(({ searchTerm, numberOfResults }) => {
            const isActiveItem = searchTerm === activeItem;

            return (
              <li
                key={searchTerm}
                ref={isActiveItem ? activeItemRef : undefined}
                role="option"
                aria-selected={isActiveItem ? "true" : "false"}
                id={`TopFocusBox-SearchContent-popupContainer-currentListbox-item-${searchTerm}`}
                css={{
                  width: screenIs({
                    default: `${(1 / 3) * 100}%`,
                    tablet: `50%`,
                    mobile: `100%`,
                  }),
                  margin: "2px 0",
                  paddingRight: "5px",
                }}
              >
                <SearchTermSummary
                  searchTerm={searchTerm}
                  results={numberOfResults}
                  isActive={isActiveItem}
                  allowFocus={false}
                />
              </li>
            );
          })}
        </ul>
      </div>
    );
  }
);

export default TrendingSearches;
