/** @jsxImportSource @emotion/react */
import { jsx } from "@emotion/react";
import React, {
  useRef,
  useLayoutEffect,
  useState,
  useCallback,
  useEffect,
} from "react";

import { animated, useSpring } from "react-spring";
import { useScreenSizeStore } from "stores";
import { useThrottledResizeObserver } from "hooks";

type Props = {
  manualUpdateKey?: string;
  delay?: number; // to delay measuring (helps with nested components of animating parents)
  faster?: boolean; // so it can animate quickly before it's parent measures height,
  initialHeight?: number;
  children: React.ReactNode;
};
const SPRING_CONFIGS = {
  default: { mass: 1, tension: 700, friction: 85 },
  fast: { mass: 1, tension: 1600, friction: 100 },
} as const;

const AutoHeightAnimator: React.FC<Props> = React.memo(
  ({ faster, initialHeight = 15, manualUpdateKey, delay, children }) => {
    const contentHolderRef = useRef<HTMLDivElement>(null);

    const { height, updateSizes } = useThrottledResizeObserver({
      delay,
      wait: 500,
      ref: contentHolderRef,
      initialSize: { height: initialHeight },
    });

    const springProps = useSpring({
      animatedHeight: height,
      config: faster ? SPRING_CONFIGS.fast : SPRING_CONFIGS.default,
    });

    useEffect(() => {
      updateSizes();
    }, [manualUpdateKey, updateSizes]);

    return (
      <animated.div
        css={{
          position: "relative",
          willChange: "height",
          width: "100%",
        }}
        style={{ height: springProps.animatedHeight }}
      >
        <div
          css={{
            label: "ref",
            position: "relative",
            width: "100%",
          }}
          ref={contentHolderRef}
        >
          {children}
        </div>
      </animated.div>
    );
  }
);

export default AutoHeightAnimator;
