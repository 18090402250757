import { Service, Equipment } from "utils/types";
import { SubmissionCapabilityType } from "services/api/suggestCapabilityTag";

export type CapabilitiesWithType = Array<
  | (Service & { type: SubmissionCapabilityType })
  | (Equipment & { type: SubmissionCapabilityType })
>;

const NUMBER_CAPABILITIES_TO_SHOW = 6 as const;

export function limitCapabilitiesShown(
  capabilities: CapabilitiesWithType
): CapabilitiesWithType {
  if (capabilities.length <= NUMBER_CAPABILITIES_TO_SHOW) return capabilities;
  return capabilities.slice(0, NUMBER_CAPABILITIES_TO_SHOW);
}
