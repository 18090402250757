import { ITenant } from "./tenant";
import arin from "./arin";
import singascope from "./singascope";
import monashUni from "./monashUni";

const tenantConfig: { [key: string]: ITenant } = {
  arin,
  singascope,
  monashUni,
};

export * from "./tenant.d";
export default tenantConfig[process.env.REACT_APP_TENANT || "arin"];
