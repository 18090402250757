/** @jsxImportSource @emotion/react */
import { jsx, css } from "@emotion/react";
import React, { Fragment } from "react";
import { useScreenSizeStore } from "stores/screenSize";
import { addFlex, addMargin } from "utils";
import Paragraph from "components/Paragraph";
import LinkUnderline from "components/LinkUnderline";
import HyperLink from "components/HyperLink";

type LearnMoreLink = {
  linkText: string;
  href: string;
  label: string;
  type: "hyperLink" | "mailTo";
};

type Props = {
  image: string;
  headline: string;
  text: string;
  learnMore: LearnMoreLink;
};

const DescriptionColumn: React.FC<Props> = ({
  image,
  headline,
  text,
  learnMore,
}) => {
  const { screenIs, isDesktop } = useScreenSizeStore();

  const LEARN_MORE_LINK_STYLES = isDesktop
    ? css({
        position: "absolute",
        bottom: 0,
        fontWeight: "bold",
      })
    : css({
        fontWeight: "bold",
      });

  return (
    <Fragment>
      <div
        css={{
          width: "100%",
          position: "relative",
          height: screenIs({
            desktop: "auto",
            tablet: "auto",
            mobile: "auto",
          }),
          ...addFlex(
            screenIs({
              default: { x: "center", y: "top" },
              mobile: { x: "left", y: "top", direction: "down" },
            })
          ),
          ...addMargin({
            top: screenIs({
              default: "70px",
              tablet: "46px",
              mobile: "34px",
            }),
            bottom: screenIs({ default: "100px", mobile: "40px" }),
          }),
        }}
      >
        <img
          src={image}
          css={{
            margin: screenIs({ default: "0 14px", tablet: "0 12.5px" }),
          }}
          alt={``}
        />
        <div
          css={{
            ...addFlex({ x: "left", y: "top", direction: "down" }),
            width: "100%",
          }}
        >
          <Paragraph
            bold
            css={{
              marginBottom: screenIs({ default: "12px", mobile: "10px" }),
              marginTop: "0",
              color: "#2F5673",
            }}
          >
            {headline}
          </Paragraph>
          <Paragraph
            css={{
              marginBottom: 0,
              width: "100%",
              height: "auto",
              paddingBottom: "50px",
            }}
          >
            {text}
          </Paragraph>
          {learnMore.type === "mailTo" ? (
            <HyperLink
              label={learnMore.label}
              mailTo={learnMore.href}
              css={LEARN_MORE_LINK_STYLES}
              fontSize={screenIs({ default: "16px", mobile: "14px" })}
            >
              {learnMore.linkText}
            </HyperLink>
          ) : (
            <HyperLink
              label={learnMore.label}
              linkTo={learnMore.href}
              css={LEARN_MORE_LINK_STYLES}
              fontSize={screenIs({ default: "16px", mobile: "14px" })}
            >
              {learnMore.linkText}
            </HyperLink>
          )}
        </div>
      </div>
    </Fragment>
  );
};

export default DescriptionColumn;
