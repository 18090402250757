import React, { useState } from "react";
import { useSpring, animated } from "react-spring";

/*
A reusable way to fade a divs contents in and out based on a prop
*/

type Props = {
  isVisible: boolean;
  stayMountedWhenHidden?: boolean;
} & React.HTMLAttributes<HTMLDivElement>;

const FadeDivWithoutMemo: React.FC<Props> = ({
  isVisible,
  stayMountedWhenHidden = false,
  children,
  style,
  ...otherProps
}) => {
  const [isFullyFadedOut, setIsFullyFadedOut] = useState(!isVisible);

  const motionProps = useSpring({
    opacity: isVisible ? 1 : 0,
    onStart() {
      if (isVisible) {
        setIsFullyFadedOut(false);
      }
    },
    onRest() {
      if (!isVisible) {
        setIsFullyFadedOut(true);
      }
    },
  });
  const shouldRenderChildren = !isFullyFadedOut || stayMountedWhenHidden;

  // TODO : accesability labels if faded out, or return null
  return (
    <animated.div
      style={{ ...style, opacity: motionProps.opacity }}
      {...otherProps}
    >
      {shouldRenderChildren && children}
    </animated.div>
  );
};

const FadeDiv: React.FC<Props> = React.memo(FadeDivWithoutMemo);
export default FadeDiv;
