import { useState, useMemo, useCallback, RefObject } from "react";
import useResizeObserver from "use-resize-observer/polyfilled";
import throttle from "lodash.throttle";

type Size = {
  width: number;
  height: number;
};

export default function useThrottledResizeObserver({
  ref,
  delay,
  wait,
  initialSize,
}: {
  ref: RefObject<HTMLElement> | RefObject<HTMLDivElement> | null;
  delay?: number;
  wait: number;
  initialSize?: Partial<Size>;
}) {
  const [size, setSize] = useState({
    width: initialSize?.width ?? 0,
    height: initialSize?.height ?? 0,
  });

  const updateSizes = useCallback(() => {
    if (ref?.current) {
      setSize({
        width: ref.current.offsetWidth,
        height: ref.current.offsetHeight,
      });
    }
  }, [ref]);

  const updateSizesWithDelay = useCallback(() => {
    if (delay) {
      setTimeout(updateSizes, delay);
    } else {
      updateSizes();
    }
  }, [delay, updateSizes]);

  const onResize = useMemo(() => throttle(updateSizesWithDelay, wait), [
    updateSizesWithDelay,
    wait,
  ]);
  const response = useResizeObserver({ onResize, ref });

  return { ...size, updateSizes };
}
