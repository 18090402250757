/** @jsxImportSource @emotion/react */
import { jsx, CSSObject } from "@emotion/react";

import React, {
  useState,
  useRef,
  useLayoutEffect,
  useCallback,
  Fragment,
  CSSProperties,
  useEffect,
} from "react";
import composeRefs from "@seznam/compose-react-refs";
import { animated } from "react-spring";

import { useHover, useFocus } from "../../hooks";

import { addFlex } from "utils";
import { createStyleSheet } from "utils/styles";
import CustomRouteLink from "./CustomRouteLink";

export type RippleSizeState = "start" | "focused" | "animating" | "resetting";

type CommonProps = {
  children: any;
  label: string;
  innerCss?: Pick<CSSObject, keyof CSSProperties & { label: string }>;
  className?: string;
  allowFocus?: boolean;
  linkTo?: string;
  mailTo?: string;
  phoneTo?: string;
  isSubmitButton?: boolean;
  onClick?: () => void;
  disabled?: boolean;
  automationId?: string;
  hoverColor?: string;
  isExpanded?: boolean;
  hasPopUp?: boolean;
  target?: string;
};

type Props =
  | (CommonProps & {
      linkTo: string;
      onClick?: () => void;
    })
  | (CommonProps & {
      onClick: () => void;
      linkTo?: string;
    })
  | (CommonProps & {
      onClick?: () => void;
      linkTo?: string;
      isSubmitButton: boolean;
    })
  | (CommonProps & {
      onClick?: () => void;
      mailTo: string;
    })
  | (CommonProps & {
      onClick?: () => void;
      phoneTo: string;
    });

const AnimatedCustomRouteLink = animated(CustomRouteLink);

const BasicButtonWithoutMemo: React.FC<Props> = ({
  onClick,
  label,
  className,
  allowFocus = true,
  children,
  innerCss,
  linkTo,
  mailTo,
  phoneTo,
  isSubmitButton,
  disabled,
  automationId,
  hoverColor,
  isExpanded,
  hasPopUp,
  target,
}) => {
  const { current: localContext } = useRef({
    canBlurButton: false,
    allowButtonBlurTimeout: null as any,
    mostRecentWasKeyboard: false,
    pointerDownWasCancled: false,
  });
  const [hoverRef, isHovered, manualSetHovered] = useHover();
  const [focusRef, isFocused, manualSetFocused] = useFocus();
  const [isPointerDown, setIsPointerDown] = useState(false);
  const [prevIsPointerDown, setPrevIsPointerDown] = useState(isPointerDown);
  const [prevIsFocused, setPrevIsFocused] = useState(isPointerDown);
  const [rippleState, setRippleState] = useState("start" as RippleSizeState);

  const [clickPosition, setClickPosition] = useState({ x: 0, y: 0 });

  const handlePointerDown = useCallback(
    (event: any) => {
      if (disabled) return false;

      // On firefox, this prevents ":active" from being applied
      // https://github.com/w3c/csswg-drafts/issues/2262#issuecomment-364633886
      event.preventDefault();
      setIsPointerDown(true);

      if (!isFocused) {
        localContext.mostRecentWasKeyboard = false;

        setRippleState("resetting");
      } else {
        localContext.mostRecentWasKeyboard = true;

        setRippleState("start");
      }
      if (hoverRef.current) {
        const clientBoundingRect = hoverRef.current.getBoundingClientRect();
        setClickPosition({
          x: event.clientX - clientBoundingRect.left,
          y: event.clientY - clientBoundingRect.top,
        });
      }
    },
    [disabled, hoverRef, isFocused, localContext.mostRecentWasKeyboard]
  );
  const handlePointerCancel = useCallback(
    (event: any) => {
      // event.preventDefault();
      if (isPointerDown) {
        localContext.pointerDownWasCancled = true;
        setIsPointerDown(false);
      }
    },
    [isPointerDown, localContext.pointerDownWasCancled]
  );

  const handleKeyDown = useCallback(
    (keyEvent: any) => {
      const pressedButtonWithKeyboard = [32, 13].includes(keyEvent.keyCode);

      if (pressedButtonWithKeyboard) {
        // don't let the button lose focus if it's 'pressed' with the keyboard
        if (rippleState !== "animating") {
          setRippleState("animating");
        } else {
          setRippleState("resetting");
          // NOTE: requestAnimationFrame is'nt guarenteed to happen after the next render,
          // storing shouldAnimateAfterResetting in localContext would probably be safer,
          // but it's probably good enough to show visual feedback
          requestAnimationFrame(() => {
            setRippleState("animating");
          });
        }
        localContext.canBlurButton = false;
        clearTimeout(localContext.allowButtonBlurTimeout);
        localContext.allowButtonBlurTimeout = setTimeout(() => {
          localContext.canBlurButton = true;
        }, 500);
      }
    },
    [
      rippleState,
      localContext.allowButtonBlurTimeout,
      localContext.canBlurButton,
    ]
  );

  const handleClickCapture = useCallback(
    (event: any) => {
      localContext.pointerDownWasCancled = false;
      setIsPointerDown(false);

      const usedPointer = event.clientX && event.clientY;

      if (usedPointer) {
        localContext.mostRecentWasKeyboard = false;
      } else {
        localContext.mostRecentWasKeyboard = true;
      }

      if (allowFocus && focusRef.current) {
        focusRef.current.focus(); // updates the latest focus position to the clicked button
      }

      if (usedPointer && !isFocused && focusRef.current) {
        focusRef.current.blur(); // removes the focus which removes focus/highlight styles
      }

      if (onClick) {
        onClick();
      }
    },
    [
      localContext.pointerDownWasCancled,
      localContext.mostRecentWasKeyboard,
      allowFocus,
      focusRef,
      isFocused,
      onClick,
    ]
  );

  // change isHovered and isFocsued if became disabled
  useEffect(() => {
    if (disabled) {
      manualSetHovered(false);
      manualSetFocused(false);
    }
  }, [disabled, manualSetFocused, manualSetHovered]);

  useLayoutEffect(() => {
    const pointerWentUp = prevIsPointerDown && !isPointerDown;
    const justClicked =
      pointerWentUp && localContext.pointerDownWasCancled === false;
    const justCancled =
      pointerWentUp && localContext.pointerDownWasCancled === true;
    const justUnFocused = prevIsFocused && !isFocused;

    const isDoingPressAnimation = rippleState === "animating";

    if (
      (isPointerDown || (isFocused && !justClicked)) &&
      !isDoingPressAnimation
    ) {
      setRippleState("focused");
    }

    if (justClicked) {
      setRippleState("animating");
    }
    if (
      justUnFocused ||
      (isPointerDown && isFocused) ||
      rippleState === "resetting" ||
      justCancled
    ) {
      setRippleState("start");
    }

    if (isFocused) {
      localContext.mostRecentWasKeyboard = true;
    }

    setPrevIsPointerDown(isPointerDown);
    setPrevIsFocused(isFocused);
  }, [
    isPointerDown,
    isFocused,
    prevIsPointerDown,
    prevIsFocused,
    rippleState,
    localContext.mostRecentWasKeyboard,
    localContext.pointerDownWasCancled,
  ]);

  const isALink = linkTo !== undefined;

  const innerContent = (
    <Fragment>
      {hoverColor && (
        <div
          css={styles.hoverStyle}
          style={{
            backgroundColor: hoverColor || "rgba(0, 0, 0, 0)",
            opacity: isHovered ? 1 : 0,
          }}
        />
      )}
      <div
        css={styles.focusBackground}
        style={{
          // backgroundColor: "rgba(184, 249, 254, 1)",
          opacity: isFocused ? 0.25 : 0,
        }}
      />

      <div
        css={innerCss}
        style={{
          transition: `opacity ${isPointerDown ? 0.0 : 0.55}s ease`,
          opacity: `${isPointerDown ? 0.85 : 1}`,
        }}
      >
        {children}
      </div>
    </Fragment>
  );

  let ariaExpandedValue: "true" | "false" | undefined = undefined;
  let ariaHasPopUp: "true" | "false" | undefined = undefined;
  if (isExpanded !== undefined) {
    ariaExpandedValue = isExpanded ? "true" : "false";
  }

  if (hasPopUp !== undefined) {
    ariaHasPopUp = hasPopUp ? "true" : "false";
  }

  const sharedProps = {
    className: className,
    ref: composeRefs(hoverRef, focusRef),
    "aria-label": label,
    "aria-expanded": ariaExpandedValue,
    "aria-haspopup": ariaHasPopUp,
    tabIndex: allowFocus ? undefined : -1,
    onClickCapture: handleClickCapture,
    onKeyDown: handleKeyDown,
    onPointerDown: handlePointerDown,
    onPointerOut: handlePointerCancel,
    disabled,
  };

  if (mailTo) {
    return (
      <a
        href={`mailto:${mailTo}`}
        data-automation={automationId ? automationId : label}
        css={styles.styledButton}
        {...sharedProps}
        style={{ cursor: disabled ? "not-allowed" : "pointer" }}
      >
        {innerContent}
      </a>
    );
  }

  if (phoneTo) {
    return (
      <a
        href={`tel:${phoneTo}`}
        data-automation={automationId ? automationId : label}
        css={styles.styledButton}
        {...sharedProps}
        style={{ cursor: disabled ? "not-allowed" : "pointer" }}
      >
        {innerContent}
      </a>
    );
  }

  if (isALink && linkTo) {
    if (linkTo.includes("https://") || linkTo.includes("http://")) {
      return (
        <a
          href={linkTo}
          data-automation={automationId ? automationId : label}
          css={styles.styledButton}
          {...sharedProps}
          style={{ cursor: disabled ? "not-allowed" : "pointer" }}
          target={target}
        >
          {innerContent}
        </a>
      );
    }

    return (
      <AnimatedCustomRouteLink
        to={linkTo}
        data-automation={automationId ? automationId : label}
        css={styles.styledButton}
        {...sharedProps}
        style={{ cursor: disabled ? "not-allowed" : "pointer" }}
      >
        {innerContent}
      </AnimatedCustomRouteLink>
    );
  }

  return (
    <button
      type={isSubmitButton ? "submit" : "button"}
      data-automation={automationId ? automationId : label}
      css={styles.styledButton}
      {...sharedProps}
      style={{ cursor: disabled ? "not-allowed" : "pointer" }}
    >
      {innerContent}
    </button>
  );
};

const styles = createStyleSheet({
  styledButton: {
    contain: "paint",
    position: "relative",
    ...addFlex({ x: "center", y: "center", direction: "down" }),
    overflow: "hidden",
    borderWidth: "0px",
    ":focus": {
      outline: "none",
    },
    textDecoration: "none",
  },
  focusBackground: {
    contain: "content",
    label: "focus-background",
    position: "absolute",
    top: 0,
    left: 0,
    width: "100%",
    height: "100%",
    pointerEvents: "none",
    transition: "opacity 0.25s ease-in-out",
    zIndex: 10,
  },
  hoverStyle: {
    contain: "content",
    label: "hover-style",
    position: "absolute",
    top: 0,
    left: 0,
    width: "100%",
    height: "100%",
    pointerEvents: "none",
    transition: "opacity 0.25s ease-in-out",
  },
});

const BasicButton: React.FC<Props> = React.memo(BasicButtonWithoutMemo);
export default BasicButton;
