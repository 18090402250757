import Aim from "./Aim";
import Arrow from "./Arrow";
import BackArrow from "./BackArrow";
import Blocks from "./Blocks";
import Building from "./Building";
import Check from "./Check";
import CheckThick from "./CheckThick";
import CheckInCircle from "./CheckInCircle";
import CheckInCircleOutline from "./CheckInCircleOutline";
import Close from "./Close";
import CloseInCircleOutline from "./CloseInCircleOutline";
import Touch from "./Touch";
import LocationPin from "./LocationPin";
import Glasses from "./Glasses";
import Info from "./Info";
import LoadingCircle from "./LoadingCircle";
import Portfolio from "./Portfolio";
import Search from "./Search";
import Mail from "./Mail";
import Phone from "./Phone";
import ScholarHat from "./ScholarHat";
import Settings from "./Settings";
import Warning from "./Warning";
import CrossInCircle from "./CrossInCircle";
import Hamburger from "./Hamburger";
import Cross from "./Cross";
import Home from "./Home";
import SelectArrow from "./SelectArrow";

const allIcons = {
  Aim,
  Arrow,
  BackArrow,
  Blocks,
  Building,
  Check,
  CheckThick,
  CheckInCircle,
  CheckInCircleOutline,
  Close,
  CloseInCircleOutline,
  Touch,
  LocationPin,
  Glasses,
  Info,
  LoadingCircle,
  Portfolio,
  Search,
  Mail,
  Phone,
  ScholarHat,
  Settings,
  Warning,
  CrossInCircle,
  Hamburger,
  Cross,
  Home,
  SelectArrow,
};

export type AllIconNames = keyof typeof allIcons;

export default allIcons;
