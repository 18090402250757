/** @jsxImportSource @emotion/react */
import { jsx } from "@emotion/react";
import React, { useEffect, useState } from "react";
import {
  Facility,
  SearchResultResponse,
  Service,
  Equipment,
  SearchResultFacilityService,
  SearchResultFacilityEquipment,
} from "utils/types";
import { addMargin, addSize, addFlex, addPadding } from "utils";
import FacilityLinksList from "components/FacilityLinksList";
import { useScreenSizeStore } from "stores";
import BasicIcon from "components/BasicIcon";
import Paragraph from "components/Paragraph";

type MatchAndMisMatchEquipment = { match: Equipment[]; misMatch: Equipment[] };
type MatchAndMisMatchServices = { match: Service[]; misMatch: Service[] };
type FacilityMatchAndMisMatchCapabilities = {
  services: MatchAndMisMatchServices;
  equipment: MatchAndMisMatchEquipment;
};

function getMatchingAndMisMatchServices(
  services: Service[],
  matchedServices: SearchResultFacilityService[]
): MatchAndMisMatchServices {
  const allServices: MatchAndMisMatchServices = { match: [], misMatch: [] };
  services.forEach((service) => {
    matchedServices.find((matchedService) => matchedService.id === service.id)
      ? allServices.match.push(service)
      : allServices.misMatch.push(service);
  });

  return allServices;
}

function sortCapabilities(capabilities: Service[] | Equipment[]) {
  return capabilities.sort((a, b) =>
    a.title.trim().toLowerCase().localeCompare(b.title.trim().toLowerCase())
  );
}

function matchingItemsToLinkItems(
  matchingItems: { title: string; id: string }[],
  type: "service" | "equipment"
) {
  return matchingItems.map(({ title, id }) => ({
    id,
    text: title,
    link: `#id=${id}&type=${type}`,
    label: `find out more about ${title}`,
  }));
}

function getMatchingAndMisMatchEquipment(
  equipment: Equipment[],
  matchedEquipment: SearchResultFacilityEquipment[]
): MatchAndMisMatchEquipment {
  const allEquipment: MatchAndMisMatchEquipment = { match: [], misMatch: [] };

  equipment.forEach((equipment) => {
    matchedEquipment.find(
      (matchedEquipment) => matchedEquipment.id === equipment.id
    )
      ? allEquipment.match.push(equipment)
      : allEquipment.misMatch.push(equipment);
  });
  return allEquipment;
}

function getMatchingAndNotMatchingCapabilities(
  searchResult: SearchResultResponse,
  facility: Facility
): FacilityMatchAndMisMatchCapabilities {
  const { services, equipment } = facility;
  return {
    services: getMatchingAndMisMatchServices(
      services,
      searchResult.matches.services
    ),
    equipment: getMatchingAndMisMatchEquipment(
      equipment,
      searchResult.matches.equipment
    ),
  };
}

type Props = {
  searchResult: SearchResultResponse | undefined;
  facility: Facility | undefined | null;
};

const FacilitySearchResults: React.FC<Props> = ({ searchResult, facility }) => {
  const { screenIs } = useScreenSizeStore();
  const [sortedItems, setSortedItems] = useState<
    FacilityMatchAndMisMatchCapabilities | undefined
  >(undefined);

  const linksListStyle = {
    ...addMargin({
      bottom: screenIs({ default: "20px", tablet: "15px", mobile: "10px" }),
    }),
  };

  useEffect(() => {
    if (facility) {
      if (searchResult) {
        setSortedItems(
          getMatchingAndNotMatchingCapabilities(searchResult, facility)
        );
      } else {
        setSortedItems({
          equipment: { match: [], misMatch: facility.equipment },
          services: { match: [], misMatch: facility.services },
        });
      }
    }
  }, [searchResult, facility]);

  if (!sortedItems) {
    return null;
  }

  const widgetsList = [];
  const hasSearchedEquipment = !!sortedItems?.equipment.match.length;
  const hasOtherEquipment = !!sortedItems?.equipment.misMatch.length;
  const hasSearchedServices = !!sortedItems?.services.match.length;
  const hasOtherServices = !!sortedItems?.services.misMatch.length;
  const hasNoInfo =
    !hasSearchedEquipment &&
    !hasOtherEquipment &&
    !hasSearchedServices &&
    !hasOtherServices;

  if (hasSearchedEquipment) {
    widgetsList.push({
      category: "equipment",
      component: (
        <h2 css={{ ...addMargin({ default: "0", bottom: "15px" }) }}>
          Equipment
        </h2>
      ),
    });
  }
  if (hasSearchedEquipment) {
    widgetsList.push({
      category: "equipment",
      component: (
        <FacilityLinksList
          title={`Equipment matching your search`}
          listOpenTitle={`Equipment matching your search`}
          moreText={`equipment matching your search`}
          backgroundColour={`#EEF4F6`}
          showMoreBarBackgroundColour={`#DCE8EC`}
          automationId={`matchingEquipment`}
          type={`Equipment`}
          css={linksListStyle}
          linkItems={matchingItemsToLinkItems(
            sortCapabilities(sortedItems.equipment.match),
            "equipment"
          )}
        />
      ),
    });
  }
  if (hasOtherEquipment) {
    widgetsList.push({
      category: "other equipment",
      component: (
        <FacilityLinksList
          title={
            hasSearchedEquipment
              ? "Other equipment available at this facility"
              : "All equipment available at this facility"
          }
          listOpenTitle={
            hasSearchedEquipment
              ? "Other equipment available at this facility"
              : "All equipment available at this facility"
          }
          moreText={`equipment`}
          automationId={
            hasSearchedEquipment ? `otherEquipment` : `allEquipment`
          }
          type={`Equipment`}
          css={linksListStyle}
          initialNumberOfItemsToShow={
            hasSearchedEquipment && (hasSearchedServices || hasOtherServices)
              ? 0
              : 10
          }
          linkItems={matchingItemsToLinkItems(
            sortCapabilities(sortedItems.equipment.misMatch),
            "equipment"
          )}
        />
      ),
    });
  }
  if (hasSearchedServices || hasOtherServices) {
    widgetsList.push({
      category: "service",
      component: (
        <h2
          css={{
            ...addMargin({
              top: screenIs({
                default: "50px",
                tablet: "30px",
                mobile: "20px",
              }),
              bottom: "15px",
            }),
          }}
        >
          Services offered
        </h2>
      ),
    });
  }
  if (hasSearchedServices) {
    widgetsList.push({
      category: "service",
      component: (
        <FacilityLinksList
          title={`Services matching your search`}
          listOpenTitle={`Services matching your search`}
          moreText={`services matching your search`}
          backgroundColour={`#EEF4F6`}
          showMoreBarBackgroundColour={`#DCE8EC`}
          automationId={`matchingServices`}
          type={`Service`}
          css={linksListStyle}
          linkItems={matchingItemsToLinkItems(
            sortCapabilities(sortedItems.services.match),
            "service"
          )}
        />
      ),
    });
  }
  if (hasOtherServices) {
    widgetsList.push({
      category: "service",
      component: (
        <FacilityLinksList
          initialNumberOfItemsToShow={
            hasSearchedServices && (hasSearchedServices || hasOtherServices)
              ? 0
              : 10
          }
          title={hasSearchedServices ? "Other services" : "All services"}
          listOpenTitle={
            hasSearchedServices ? "Other services" : "All services"
          }
          automationId={hasSearchedServices ? `otherServices` : `allServices`}
          type={`Service`}
          css={linksListStyle}
          linkItems={matchingItemsToLinkItems(
            sortCapabilities(sortedItems.services.misMatch),
            "service"
          )}
          moreText={`services`}
        />
      ),
    });
  }

  const equipmentList = widgetsList.filter((i) => i.category == "equipment");
  const serviceList = widgetsList.filter((i) => i.category == "service");
  const otherEquipmentList = widgetsList.filter(
    (i) => i.category != "equipment" && i.category != "service"
  );
  let widgetsInOrder = [];

  widgetsInOrder =
    hasSearchedServices && !hasSearchedEquipment
      ? [...serviceList, ...otherEquipmentList, ...equipmentList]
      : widgetsList;

  return (
    <div
      css={{
        ...addSize({ width: "100%" }),
        ...addMargin({
          bottom: screenIs({ default: "50px", tablet: "30px", mobile: "14px" }),
        }),
      }}
    >
      {hasNoInfo ? (
        <div
          css={{
            width: "100%",
            ...addFlex({ x: "center", y: "center", direction: "down" }),
            ...addPadding({
              horizontal: screenIs({
                default: "0px",
                desktop: "25px",
              }),
            }),
          }}
        >
          <div
            css={{
              width: "100%",
              ...addFlex({ x: "center", y: "center", direction: "down" }),
              ...addPadding({ horizontal: "25px", vertical: "50px" }),

              borderRadius: "8px",
              backgroundColor: "#f8f8f8",
            }}
          >
            <BasicIcon name="Building" size={100} color=" rgb(213, 213, 213)" />
            <Paragraph bold css={{ textAlign: "center", marginTop: "20px" }}>
              No facility information available
            </Paragraph>
            <Paragraph
              css={{
                textAlign: "center",
                marginTop: "0px",
              }}
            >
              This facility currently has no public available data
            </Paragraph>
          </div>
        </div>
      ) : null}
      {widgetsInOrder.map((item: any) => {
        return item.component;
      })}
    </div>
  );
};

export default FacilitySearchResults;
