/** @jsxImportSource @emotion/react */
import { jsx, css } from "@emotion/react";

import React from "react";

type Props = {
  color: string;
  css: ReturnType<typeof css>;
};

const CheckInCircleOutlineIcon: React.FC<Props> = ({
  color = "#3D3D3D",
  css,
}) => {
  return (
    <svg
      viewBox="0 0 29 29"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      css={css}
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M18.93 4.56a10.88 10.88 0 106.45 9.94v-1.11a1.2 1.2 0 012.41 0v1.11a13.3 13.3 0 11-7.88-12.15 1.2 1.2 0 01-.98 2.21z"
        fill={color}
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M27.44 3.98a1.2 1.2 0 010 1.7l-12.08 12.1a1.2 1.2 0 01-1.71 0l-3.63-3.62a1.2 1.2 0 011.71-1.71l2.77 2.77L25.73 3.98a1.2 1.2 0 011.7 0z"
        fill={color}
      />
    </svg>
  );
};

export default CheckInCircleOutlineIcon;
