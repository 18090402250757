/** @jsxImportSource @emotion/react */
import { jsx } from "@emotion/react";
import React from "react";
import Paragraph from "components/Paragraph";
import BasicIcon from "components/BasicIcon";
import BasicButton from "components/BasicButton";
import { useScreenSizeStore } from "stores";
import { addFlex, addMargin, addSize } from "utils";
import tenantConfig from "tenants";

type Props = {
  isOpen: boolean;
  toggleMenu: (filtersOpen: boolean) => void;
};

const MobileHeader: React.FC<Props> = ({ toggleMenu, isOpen }) => {
  const { screenIs } = useScreenSizeStore();
  return (
    <div
      css={{
        alignItems: "center",
        ...addFlex({ x: "edges", y: "center" }),
        height: screenIs({
          default: "76px",
          tablet: "50px",
          mobile: "40px",
        }),
        paddingLeft: screenIs({
          default: "40px",
          tablet: "25px",
          mobile: "15px",
        }),
        paddingRight: screenIs({
          default: "40px",
          tablet: "25px",
          mobile: "15px",
        }),
      }}
    >
      <div
        css={{
          ...addFlex({ x: "left", y: "center" }),
        }}
      >
        <BasicIcon
          size={screenIs({
            default: 20,
            mobile: 18,
          })}
          name={`Settings`}
          color={`#000`}
        />
        <Paragraph
          bold
          css={{
            ...addMargin({
              vertical: "0",
              left: screenIs({ default: "14px", desktop: "0px" }),
            }),
            whiteSpace: "nowrap",
          }}
        >
          Filter {tenantConfig.text.usePlatfrom ? "Platforms" : "Facilities"}
        </Paragraph>
      </div>
      <BasicButton
        label={`Open filter menu`}
        isExpanded={isOpen}
        css={{
          ...addSize({ size: "40px" }),
          borderRadius: "100px",
          transform: "translateX(15px)",
        }}
        innerCss={{
          ...addFlex({ x: "center", y: "center" }),
          padding: "5px",
          position: "relative",
        }}
        onClick={() => {
          toggleMenu(!isOpen);
        }}
      >
        <BasicIcon
          size={14}
          height={8}
          name={`Arrow`}
          color={`#000`}
          css={{
            transform: isOpen ? "rotate(-180deg)" : "rotate(0deg)",
            transition: "transform 0.25s",
            transformOrigin: "center center",
          }}
        />
      </BasicButton>
    </div>
  );
};

export default MobileHeader;
