/** @jsxImportSource @emotion/react */
import { jsx, css } from "@emotion/react";
import { useTheme } from "@emotion/react";
import React, { useState } from "react";

import { getAnalyticsPublisher } from "services/analytics";
import arinCircle from "assets/images/arinCircle.png";

import BasicIcon from "components/BasicIcon";
import BasicButton from "components/BasicButton";
import Link from "components/LinkUnderline";
import {
  useScreenSizeStore,
  userOrganisationStore,
  useFacilityStore,
  useModalSecondStore,
} from "stores";
import { addSize, addFlex, addMargin, addPadding } from "utils";
import { ORGANISATION_TYPES } from "utils/constants";
import { Facility, OrganisationTypes } from "utils/types";
import Paragraph from "components/Paragraph";
import BoxIconRadioInput from "components/inputs/BoxIconRadioInput";
import { createStyleSheet } from "utils/styles";
import LearnAboutVerifyingAccordion from "./LearnAboutVerifyingAccordion";

type Props = {};

const LearnAboutVerifying: React.FC<Props> = () => {
  const closeModal = useModalSecondStore((state) => state.closeModal);
  const theme = useTheme();
  const { isMobile, screenIs } = useScreenSizeStore();
  const setUserOrganisationType = userOrganisationStore(
    (state) => state.setUserOrganisationType
  );
  const facility = useFacilityStore((state) => state.facility);
  const [activeOrganisationType, setActiveOrganisationType] = useState(
    undefined as OrganisationTypes | undefined
  );

  function handleActionCloseModal(): void {
    closeModal();
  }

  const styles = createStyleSheet({
    container: {
      ...addFlex({ x: "left", y: "top", direction: "down" }),
      overflow: "hidden",
      backgroundColor: "#ffffff",
    },
    closeButton: { position: "absolute", top: "25px", right: "25px" },
    header: {
      ...addFlex({ x: "left", y: "top", direction: "down" }),
      ...addPadding({ top: "30px", bottom: "20px", horizontal: "40px" }),
      width: "100%",
      borderBottom: "1px solid #D5D5D5",
    },
    title: {
      fontFamily: theme.fonts.main,
      fontStyle: "normal",
      fontWeight: "bold",
      fontSize: "24px",
      lineHeight: "32px",
      color: "#2F5673",
    },
    content: {
      ...addFlex({ x: "center", y: "top", direction: "down" }),
      ...addPadding({ top: "30px", bottom: "40px", horizontal: "40px" }),
    },
    description: {
      fontFamily: theme.fonts.main,
      fontStyle: "normal",
      fontWeight: "normal",
      fontSize: "18px",
      lineHeight: "30px",
      color: "#2F2F2F",
      marginBottom: "30px",
    },
    accordionHolder: {},
  });

  return (
    <div
      css={{
        ...styles.container,
        ...addSize({
          size: "100%",
          width: { max: screenIs({ default: "600px", tablet: "530px" }) },
        }),
        borderRadius: screenIs({ default: "15px", mobile: "10" }),
      }}
    >
      <div css={styles.header}>
        <div css={styles.title}>Verifying Arin’s suggestions</div>
      </div>
      <div css={styles.content}>
        <div css={styles.description}>
          When you verify a suggestion from Arin, it sends the verified
          suggestion (anonymously) to the relevant facility manager for review.
          You have not permanently changed the data in Arin.
        </div>
        <div css={styles.accordionHolder}>
          <LearnAboutVerifyingAccordion />
        </div>
      </div>
      <BasicButton
        label="close popup"
        onClick={closeModal}
        css={styles.closeButton}
      >
        <BasicIcon
          name="Close"
          color="#949494"
          size={screenIs({ default: 21, desktop: 36 })}
        />
      </BasicButton>
    </div>
  );
};

export default LearnAboutVerifying;
