/** @jsxImportSource @emotion/react */
import { jsx } from "@emotion/react";
import { useTheme } from "@emotion/react";
import React, { Fragment, useState, useEffect } from "react";
import parse from "html-react-parser";
import BasicButton from "components/BasicButton";
import {
  useModalStore,
  useScreenSizeStore,
  useFacilityStore,
  useCapabilitySuggestionStore,
} from "stores";
import BasicIcon from "components/BasicIcon";
import { addSize, addFlex, addPadding } from "utils";
import shallow from "zustand/shallow";
import {
  DetailsProps,
  SendableFormFormName,
} from "stores/utils/storeMakers/makeModalStore/types";
import SubmitEnquiry from "components/SubmitEnquiry";
import { parseHTMLOptions } from "utils/parseHtml";
import SuggestionTeaser from "components/SuggestionTeaser";
import { useLocation } from "react-router-dom";
import { parseAndValidateHash } from "utils/routes";
import { SUGGESTED_DESCRIPTION_CSS, DESCRIPTION_CSS } from "./styles";
import { getAlternativesByType } from "./utils/getAlternativesByType";
import YouVerifiedNote from "./YouVerifiedNote";
import { createStyleSheet } from "utils/styles";
import useStackedModals from "stores/modals";
import { DEFAULT_SCROLLBAR_WIDTH } from "utils/constants";

const HAS_NOT_BEEN_CONSIDERED = {
  images: false,
  title: false,
  description: false,
} as const;

type Props = {} & DetailsProps;

type SuggestionsDismissedState = {
  title: boolean;
  images: boolean;
  description: boolean;
  multi: boolean;
};

const ItemDetails: React.FC<Props> = ({
  detailsType,
  title,
  images,
  description,
  alternatives,
}) => {
  const [closeModal, modalContentProps] = useModalStore(
    (state) => [state.closeModal, state.modalContentProps as DetailsProps],
    shallow
  );
  const { hash } = useLocation();
  const theme = useTheme();

  const { openNewModal } = useStackedModals();

  const { screenIs } = useScreenSizeStore();
  const { facility } = useFacilityStore();
  const {
    initialiseCapabilitySuggestion,
    hasConsideredSuggestions,
    suggestions,
    suggestionsConsidered,
    getSuggestionsConfirmed,
    resetCapabilitySuggestion,
    suggestionsConfirmed,
    numberOfSuggestions,
    facilitySuggestionsConsideredHistory,
    getNextAction,
  } = useCapabilitySuggestionStore();

  const [suggestionDismissedState, setSuggestionDismissedState] =
    useState<SuggestionsDismissedState>({
      title: false,
      images: false,
      description: false,
      multi: false,
    });

  useEffect(() => {
    if (alternatives) {
      initialiseCapabilitySuggestion(getAlternativesByType(alternatives), {
        images,
        title,
        description,
      });
    }
    return () => {
      resetCapabilitySuggestion();
    };
  }, [
    alternatives,
    description,
    resetCapabilitySuggestion,
    images,
    title,
    facility,
    initialiseCapabilitySuggestion,
  ]);
  function _getFirstModal(): SendableFormFormName | null {
    const nextAction = getNextAction();
    if (nextAction === null) {
      setSuggestionDismissedState({
        ...suggestionDismissedState,
        ...{ multi: true },
      });
      return null;
    }

    const actions = {
      images: { formName: "submitImage" as const },
      title: { formName: "submitTitle" as const },
      description: { formName: "submitDescription" as const },
    };

    return actions[nextAction].formName;
  }

  const parsedHash = parseAndValidateHash(hash);
  const currentCapabilityId =
    parsedHash && parsedHash.id ? parsedHash.id : undefined;

  const hasFacilityCapabilityBeenConsidered =
    facilitySuggestionsConsideredHistory.find(
      (suggestion) => currentCapabilityId === suggestion.capabilityId
    )?.suggestions || HAS_NOT_BEEN_CONSIDERED;

  const hasConsideredAny = Object.values(
    hasFacilityCapabilityBeenConsidered
  ).find((value) => value);

  return (
    <div css={styles.container}>
      <div css={styles.mainContent}>
        <BasicButton
          label="close description"
          onClick={closeModal}
          css={styles.closeButton}
        >
          <BasicIcon
            name="Close"
            color={theme.mainColor}
            size={screenIs({ default: 21, desktop: 36 })}
          />
        </BasicButton>
        {detailsType && (
          <Fragment>
            <p css={styles.detailsTypeText}>{detailsType}</p>
            <div css={styles.titleHolder}>
              <h2
                data-automation={`modal-headline`}
                css={
                  getSuggestionsConfirmed().title
                    ? styles.titleConfirmed
                    : styles.title
                }
              >
                {getSuggestionsConfirmed().title
                  ? suggestions.title?.value
                  : title}
              </h2>
            </div>
            {numberOfSuggestions > 1 &&
              !hasConsideredAny &&
              !suggestionDismissedState.multi &&
              !hasConsideredSuggestions && (
                <div css={styles.titleSuggestionItemsHolder}>
                  <SuggestionTeaser
                    headline={`I think I’ve found more descriptive content`}
                    text={`Help improve ${facility?.institutionName} equipment listing by verifying Arin's suggestion in 5 seconds (or less!)`}
                    dismissMissSuggestion={() => {
                      setSuggestionDismissedState({
                        ...suggestionDismissedState,
                        ...{ multi: true },
                      });
                    }}
                    viewSuggestion={() => {
                      const formName = _getFirstModal();
                      if (formName !== null) {
                        openNewModal({
                          modalContentProps: {
                            formName,
                          },
                          modalContentName: "sendableForm",
                          modalType: "popup",
                        });
                      }
                    }}
                  />
                </div>
              )}
            {suggestionsConfirmed.title && (
              <div css={styles.titleSuggestionItemsHolder}>
                <YouVerifiedNote />{" "}
              </div>
            )}
            {numberOfSuggestions === 1 &&
              !!suggestions.title &&
              !suggestionDismissedState.title &&
              !suggestionsConsidered.title &&
              !hasFacilityCapabilityBeenConsidered.title && (
                <div css={styles.titleSuggestionItemsHolder}>
                  <SuggestionTeaser
                    headline={`I think I’ve found an improved title`}
                    text={`Help improve ${facility?.institutionName} equipment listing by verifying Arin's suggestion in 5 seconds (or less!)`}
                    dismissMissSuggestion={() => {
                      setSuggestionDismissedState({
                        ...suggestionDismissedState,
                        ...{ title: true },
                      });
                    }}
                    viewSuggestion={() => {
                      openNewModal({
                        modalContentProps: {
                          formName: "submitTitle",
                        },
                        modalContentName: "sendableForm",
                        modalType: "popup",
                      });
                    }}
                  />
                </div>
              )}
            <div css={styles.scrollingContent}>
              {suggestionsConfirmed.images && <YouVerifiedNote />}
              {!!getSuggestionsConfirmed().images ? (
                <div css={styles.imageConfirmedHolder}>
                  <img
                    css={styles.image}
                    src={suggestions.images ? suggestions.images[0].value : ""}
                    alt={`${modalContentProps.title}`}
                  />
                </div>
              ) : (
                "images" in modalContentProps &&
                !!images &&
                !!images.length && (
                  <div css={styles.imageHolder}>
                    <img
                      css={styles.image}
                      src={images[0]}
                      alt={`${modalContentProps.title}`}
                    />
                  </div>
                )
              )}
              {numberOfSuggestions === 1 &&
                !!suggestions.images &&
                !suggestionDismissedState.images &&
                !suggestionsConsidered.images &&
                !hasFacilityCapabilityBeenConsidered.images && (
                  <SuggestionTeaser
                    headline={`I think I've found an image for this listing`}
                    text={`Help improve ${facility?.institutionName} equipment listing by verifying Arin's suggestion in 5 seconds (or less!)`}
                    dismissMissSuggestion={() => {
                      setSuggestionDismissedState({
                        ...suggestionDismissedState,
                        ...{ images: true },
                      });
                    }}
                    viewSuggestion={() => {
                      openNewModal({
                        modalContentProps: {
                          formName: "submitImage",
                        },
                        modalContentName: "sendableForm",
                        modalType: "popup",
                      });
                    }}
                  />
                )}

              {suggestionsConfirmed.description && <YouVerifiedNote />}
              {numberOfSuggestions === 1 &&
                !!suggestions.description &&
                !suggestionDismissedState.description &&
                !suggestionsConsidered.description &&
                !hasFacilityCapabilityBeenConsidered.description && (
                  <SuggestionTeaser
                    headline={`I think I’ve found more descriptive content`}
                    text={`Help improve ${facility?.institutionName} equipment listing by verifying Arin's suggestion in 5 seconds (or less!)`}
                    dismissMissSuggestion={() => {
                      setSuggestionDismissedState({
                        ...suggestionDismissedState,
                        ...{ description: true },
                      });
                    }}
                    viewSuggestion={() => {
                      openNewModal({
                        modalContentProps: {
                          formName: "submitDescription",
                        },
                        modalContentName: "sendableForm",
                        modalType: "popup",
                      });
                    }}
                  />
                )}

              <div
                css={
                  getSuggestionsConfirmed().description
                    ? SUGGESTED_DESCRIPTION_CSS
                    : DESCRIPTION_CSS
                }
                data-automation={`modal-description`}
              >
                {parse(
                  getSuggestionsConfirmed().description &&
                    suggestions.description
                    ? suggestions.description.value
                    : description || "",
                  parseHTMLOptions
                )}
              </div>
            </div>
          </Fragment>
        )}
      </div>
      <SubmitEnquiry />
    </div>
  );
};

const styles = createStyleSheet({
  container: {
    ...addSize({ size: "100%" }),
    maxHeight: "100%",
    ...addFlex({ x: "left", y: "top", direction: "down" }),
    backgroundColor: "#1D1D1D",
    position: "relative",
    justifyContent: "space-between",
  },
  mainContent: {
    width: "100%",
    ...addFlex({ x: "left", y: "top", direction: "down" }),
    ...addPadding({
      top: "64px",
    }),
    backgroundColor: "#1D1D1D",
    color: "white",
    position: "relative",
    flexGrow: 1,
    flexShrink: 1,
    overflowY: "auto", // no scrollbar, but helps flex
  },
  scrollingContent: {
    flexGrow: 1,
    flexShrink: 1,
    width: `calc(100% - ${DEFAULT_SCROLLBAR_WIDTH}px)`,
    overflowY: "auto",
    marginRight: "15px",
    ...addPadding({ horizontal: "32px" }),
    "::-webkit-scrollbar": {
      backgroundColor: "#3D3D3D",
      width: "5px",
    },
    "::-webkit-scrollbar-thumb": {
      backgroundColor: "#949494",
      borderRadius: "5px",
    },
  },
  detailsTypeText: {
    textTransform: "uppercase",
    fontWeight: 800,
    fontSize: "10px",
    letterSpacing: "0.5px",
    lineHeight: "13px",
    ...addPadding({ horizontal: "32px" }),
  },
  closeButton: { position: "absolute", top: "25px", right: "25px" },

  titleHolder: {
    width: "100%",
    ...addPadding({ horizontal: "32px", bottom: "20px" }),
  },
  title: {
    width: "100%",
  },
  titleConfirmed: {
    width: "100%",
    background: "rgba(153, 153, 153, 0.15)",
    border: "2px dashed #979797",
    borderRadius: "16px",
    padding: "15px 20px",
  },
  titleSuggestionItemsHolder: {
    ...addPadding({ horizontal: "32px", bottom: "20px" }),
  },
  imageHolder: {
    label: "imageHolder",
    width: "100%",
    display: "flex",
    padding: "20px 0",
    margin: "20px 0",
  },
  imageConfirmedHolder: {
    label: "imageConfirmedHolder",
    width: "100%",
    display: "flex",
    padding: "20px",
    background: "rgba(153, 153, 153, 0.15)",
    border: "2px dashed #979797",
    borderRadius: "16px",
    margin: "20px 0",
  },
  image: { margin: "auto", height: "intrinsic", width: "100%" },
});

export default ItemDetails;
