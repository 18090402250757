export const MAIN_PADDING = {
  mobile: 15,
  tablet: 0,
  default: 76,
};

export const DEFAULT_SCROLLBAR_WIDTH = 15;

export const LOADING_FACILITY_NAMES = [
  "University of Melbourne",
  "RMIT",
  "Monash University",
  "Murdoch Childrens Research Institute",
];

// NOTE: possibly combine this with the names from the laoding overlay
export const FUTURE_INSTITUTION_NAMES = ["WEHI", "CSIRO", "Peter Mac"];

function getCookieExpiry(): Date {
  const now = new Date();
  return new Date(now.setHours(now.getHours() + 36));
}

export const ORGANISATION_TYPES = {
  industry: "COMMERCIAL",
  research: "ACADEMIC",
  none: "PRIVATE",
} as const;

export const USER_ORGANISATION_COOKIE = {
  key: "USER_ORGANISATION_TYPE",
  expiry: getCookieExpiry(),
};

export const ALLOWED_HTML_TAGS = [
  "div",
  "span",
  "table",
  "button",
  "strong",
  "br",
  "a",
  "li",
  "ul",
  "ol",
  "p",
  "h1",
  "h2",
  "h3",
  "h4",
  "h5",
  "h6",
  "address",
  "article",
  "section",
  "blockquote",
  "pre",
  "b",
  "em",
  "code",
  "i",
  "kbd",
  "mark",
  "time",
  "audio",
  "map",
  "track",
  "video",
  "picture",
  "source",
  "del",
  "ins",
  "table",
  "caption",
  "col",
  "colgroup",
  "tbody",
  "td",
  "tfoot",
  "th",
  "thead",
  "tr",
];
