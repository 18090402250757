import {
  ValidatorFunctions,
  ValidatorFunctionSharedParams,
  ValidatorsOptions,
} from "./types";

const validatorFunctions = {
  required({ value, inputState, formState }: ValidatorFunctionSharedParams) {
    if (typeof value !== "string") return false;

    if (value.length === 0) {
      return "required field";
    }
    return false;
  },
  requiredLength({
    value,
    inputState,
    formState,
    validatorOptions,
  }: ValidatorFunctionSharedParams & {
    validatorOptions: ValidatorsOptions["requiredLength"];
  }) {
    if (typeof value !== "string" || !validatorOptions) return false;

    if (
      validatorOptions.max !== undefined &&
      value.length > validatorOptions.max
    ) {
      return `over maximum of ${validatorOptions.max} characters`;
    }

    if (
      validatorOptions.min !== undefined &&
      value.length < validatorOptions.min
    ) {
      return `under minimum of ${validatorOptions.min} characters`;
    }
    return false;
  },
};

export default validatorFunctions;
