import makeFormStore from "stores/utils/storeMakers/makeFormStore";

const useSendFeedbackForm = makeFormStore({
  comment: {
    inputType: "textArea",
    validatorTypes: ["required"],
    validatorOptions: {},
  },
});

export { useSendFeedbackForm };
