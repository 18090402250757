import { gql } from "apollo-boost";

import Api from "services/api";
import {ProgramType} from "utils/types";

type GetProgramTypeResponse = {
  getProgramType: ProgramType;
};

const getProgramType = (programTypeId?: string | undefined | null) =>
  Api.query<GetProgramTypeResponse>({
    query: gql`
      {
        getProgramType(programTypeId: "${programTypeId}") {
          id
          externalId
          name
          logo
          summary
          programs {
            id
            name
            logo
            summary
            description {
              title
              body
            }
          }
        }
      }
    `,
  }).then((result) => result);

export default getProgramType;
